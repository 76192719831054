/* import __COLOCATED_TEMPLATE__ from './path-editor.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { EntityType } from 'embercom/models/data/entity-types';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import {
  stepTypes,
  branchingQuestionIdentifier,
  responseTypes,
} from 'embercom/models/data/survey/constants';
import { generateAttributes } from 'embercom/lib/survey-branching-helper';

export default class PathEditor extends Component {
  @service store;
  @service appService;
  @service intl;
  @tracked predicateQuestionData;

  get currentStep() {
    return this.args.step;
  }

  get survey() {
    return this.args.survey;
  }

  get currentStepId() {
    return this.currentStep.id || this.currentStep.uuid;
  }

  get currentStepPaths() {
    let defaultPathOrder = 0;
    let stepPaths = this.survey.paths.filter((path) => {
      let stepId = path.stepId || path.stepUuid;

      return stepId === this.currentStepId && path.order !== defaultPathOrder;
    });

    return stepPaths;
  }

  get allowedStepsWithQuestions() {
    let currentStepOrder = this.currentStep.order;
    let allowedSteps = this.survey.steps.filter(
      (step) => step.stepType === stepTypes.question && step.order <= currentStepOrder,
    );

    return allowedSteps;
  }

  get allowedRuleConditionalAttributes() {
    return [
      {
        heading: 'Select a question',
        attributes: generateAttributes(this.allowedStepsWithQuestions, this.generateAttributeName)
          .questionAttributes,
      },
    ];
  }

  get attributeListOverride() {
    return generateAttributes(this.allowedStepsWithQuestions, this.generateAttributeName)
      .attributesOverrides;
  }

  get nextPathOrderCount() {
    let incrementByValue = 1;
    let stepPaths = this.survey.paths.filter((path) => {
      if (path.stepId) {
        return path.stepId === this.currentStep.id;
      } else {
        return path.stepUuid === this.currentStep.uuid;
      }
    });

    let maxPathOrder = Math.max(...stepPaths.map((path) => path.order));

    return maxPathOrder + incrementByValue;
  }

  get defaultNextStep() {
    let currentStepOrder = this.currentStep.order;
    let defaultNextStepOrder = currentStepOrder + 1;

    let { id, uuid } =
      this.survey.steps.find((step) => step.order === defaultNextStepOrder) ||
      this.survey.steps.find((step) => step.stepType === stepTypes.thankYou);

    return {
      nextStepId: id,
      nextStepUuid: uuid,
    };
  }

  _generatePredicateQuestionData(questionUuid) {
    let currentQuestion = this.survey.questions.find((question) => question.uuid === questionUuid);

    switch (currentQuestion.granularQuestionType) {
      case responseTypes.dropdown:
        this.predicateQuestionData = this._dropdownQuestionPredicateData(currentQuestion);
        break;

      default:
        break;
    }
  }

  _dropdownQuestionPredicateData(question) {
    return question.data.options.map((option) => ({
      value: option,
      text: option,
    }));
  }

  @action generateAttributeName(stepOrder, questionOrder, questioName) {
    let questionName = `${this.intl.t('outbound.surveys.branching.abbreviated-step-question', {
      stepOrder,
      questionOrder,
    })} ${questioName}`;

    return questionName;
  }

  @action addPath() {
    let newPath = this.store.createRecord('content-service/path', {
      contentType: EntityType.Survey,
      contentId: this.survey.id,
      stepType: EntityType.SurveyStep,
      stepUuid: this.currentStep.uuid,
      stepId: this.currentStep.id,
      order: this.nextPathOrderCount,
      predicateGroup: { predicates: [] },
      nextStepId: this.defaultNextStep.nextStepId,
      nextStepUuid: this.defaultNextStep.nextStepUuid,
    });

    this.survey.paths.pushObject(newPath);
  }

  @action removePath(path) {
    this.survey.paths.removeObject(path);
    path.deleteRecord();
    // TODO: nice to have
    // update order change in present paths it goes in order 0, 1, 2
    // not 0, 2 if we remove path in the middle
  }

  @action onPredicateOpen(predicate) {
    let questionUuid = predicate.attribute.replace(branchingQuestionIdentifier, '');

    this._generatePredicateQuestionData(questionUuid);
  }
}
