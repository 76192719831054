/* import __COLOCATED_TEMPLATE__ from './step-options-container.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

interface Args {
  currentStep: $TSFixMe;
  step: $TSFixMe;
  showContainer: boolean;
  deleteStep: (step: $TSFixMe) => void;
}

export default class StepOptionsContainer extends Component<Args> {
  @service declare appService: $TSFixMe;

  get app() {
    return this.appService.app;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Survey::StepControls::StepOptionsContainer': typeof StepOptionsContainer;
    'survey/step-controls/step-options-container': typeof StepOptionsContainer;
  }
}
