/* import __COLOCATED_TEMPLATE__ from './bulk-actions-buttons.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';
import { equal, readOnly, mapBy } from '@ember/object/computed';
import { put } from 'embercom/lib/ajax';
import LegacySelectionState from 'embercom/models/legacy-selection-state';
// eslint-disable-next-line @intercom/intercom/no-legacy-modal
import ModalActions from 'embercom/controllers/mixins/users-and-companies/modal-actions';
import { uniq } from '@ember/object/computed';
import pluralize from 'embercom/lib/inflector';

export default Component.extend(ModalActions, {
  tagName: '',
  intl: service(),
  appService: service(),
  intercomEventService: service(),
  notificationsService: service(),
  app: readOnly('appService.app'),
  selectedUserIds: mapBy('selectedUsers', 'id'),
  isSelectAllMode: equal('selectedUsers.length', 0),
  _uniqueSelectedUserIds: uniq('selectedUserIds', 'id'),
  bulkTaggingUrl: 'ember/stats_system/tag.json',
  showTagModal: false,
  content: readOnly('statsParameters.contentObject'),
  disableBulkMessage: computed(
    'isSelectAllMode',
    '_uniqueSelectedUserIds',
    'uniqueCount',
    function () {
      if (this.isSelectAllMode) {
        return this.uniqueCount > 2000;
      } else {
        return this._uniqueSelectedUserIds.length > 2000;
      }
    },
  ),

  bulkMessageLabel: computed(
    'intl.locale',
    'isSelectAllMode',
    '_uniqueSelectedUserIds.[]',
    function () {
      if (this.isSelectAllMode) {
        return this.intl.t('stats-system.list.header.bulk-action-buttons.message-all');
      } else {
        let uniqueUserCount = this._uniqueSelectedUserIds.length;
        return this.intl.t('stats-system.list.header.bulk-action-buttons.message-num-of-users', {
          unique_user_count: uniqueUserCount,
          singular_or_plural_user: pluralize('user', uniqueUserCount, 'users'),
        });
      }
    },
  ),

  bulkTagLabel: computed(
    'intl.locale',
    'isSelectAllMode',
    '_uniqueSelectedUserIds.[]',
    function () {
      if (this.isSelectAllMode) {
        return this.intl.t('stats-system.list.header.bulk-action-buttons.tag-all');
      } else {
        let uniqueUserCount = this._uniqueSelectedUserIds.length;
        return this.intl.t('stats-system.list.header.bulk-action-buttons.tag-num-of-users', {
          unique_user_count: uniqueUserCount,
          singular_or_plural_user: pluralize('user', uniqueUserCount, 'users'),
        });
      }
    },
  ),

  tagTitle: computed('selectedUsers.length', 'statCount', function () {
    let numberOfUsers = this.isSelectAllMode ? this.statCount : this.selectedUsers.length;
    if (this.selectedUsers.length === 1) {
      return `Tag this user`;
    } else {
      return `Tag these ${numberOfUsers} unique users`;
    }
  }),

  taggable: computed(function () {
    return {
      tags: [],
      taggings: [],
      type: 'user-company',
      updateTaggings: (admin, selectedTags, removedTags) =>
        this.tagUsersTask.perform(admin, selectedTags),
    };
  }),

  tagUsersTask: task(function* (admin, selectedTags) {
    let params = {
      app_id: this.get('app.id'),
      admin_id: this.get('app.currentAdmin.id'),
      tag_names: selectedTags.map((tag) => tag.name),
      included_ids: this.isSelectAllMode ? [] : this.selectedUserIds,
      is_select_all_mode: this.isSelectAllMode,
      stat: this.statistic,
      content_id: this.statsParameters.contentId,
      content_type: this.statsParameters.contentType,
      range_start: this.statsParameters.range?.start,
      range_end: this.statsParameters.range?.end,
    };
    if (this.isSelectAllMode) {
      params = { ...this.baseQueryParams, ...params };
    }
    yield put(this.bulkTaggingUrl, params);
  }),

  _makeOptionsForBulkMessageModal() {
    let options = {
      app_id: this.get('app.id'),
      admin_id: this.get('app.currentAdmin.id'),
      included_ids: this.isSelectAllMode ? [] : this.selectedUserIds,
      is_select_all_mode: this.isSelectAllMode,
      stat: this.statistic,
      content_id: this.statsParameters.contentId,
      content_type: this.statsParameters.contentType,
      shortObjectName: this.statsParameters.shortObjectName,
    };
    if (this.isSelectAllMode) {
      options = { ...this.baseQueryParams, ...options };
    }

    return options;
  },

  _getLegacySelectionState() {
    return LegacySelectionState.create({
      isSelectAllMode: this.isSelectAllMode,
      selected: this.selectedUsers,
      unselected: [],
      totalCount: this.isSelectAllMode ? this.statCount : this.selectedUsers.length,
    });
  },

  _trackBulkActionEvent(action) {
    let objectName = this.statsParameters.shortObjectName;
    let analyticsEvent = {
      object: objectName,
      app_id: this.get('app.id'),
      action,
    };
    analyticsEvent[`${objectName}_id`] = this.content.id;
    this.intercomEventService.trackAnalyticsEvent(analyticsEvent);
  },

  actions: {
    showBulkTagModal() {
      this.set('showTagModal', true);
      this._trackBulkActionEvent('bulk_tag_button_clicked');
    },

    showBulkMessageModal() {
      let component = 'stats-system/modals/bulk-message';
      // eslint-disable-next-line @intercom/intercom/no-legacy-modal
      this.send(
        'openModal',
        component,
        this._getLegacySelectionState(),
        this._makeOptionsForBulkMessageModal(),
      );
      this._trackBulkActionEvent('bulk_message_button_clicked');
    },

    afterSave() {
      this.set('showTagModal', false);
      this.notificationsService.notifyConfirmation(
        'Customers are being tagged. This may take a few minutes.',
      );
      this._trackBulkActionEvent('bulk_tagged_stat_list');
    },
  },
});
