/* import __COLOCATED_TEMPLATE__ from './form-component.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
export default Component.extend({
  attributeBindings: ['data-test-signup-form'],
  'data-test-signup-form': true,
  submitAction: null,
  actions: {
    submit(e) {
      e.preventDefault();
      this.submitAction(e);
    },
  },
});
