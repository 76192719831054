/* import __COLOCATED_TEMPLATE__ from './multi-select.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
export default class MultiSelect extends Component {
  get question() {
    return this.args.question;
  }

  get hasOtherOption() {
    return this.question.data.includeOther;
  }

  @action addSelectOption() {
    this.question.data.options.pushObject('');
    this.args.handleMultiSelectMax(this.question);
  }

  @action deleteSelectOption(index) {
    this.question.data.options.removeAt(index);
    this.args.handleMultiSelectMax(this.question);
  }

  @action loadOptions() {
    this.question.data.options = this.args.question.data.options;
  }

  @action updateDataOptions(option, index) {
    this.question.data.options.replace(index, 1, [option]);
  }
}
