/* import __COLOCATED_TEMPLATE__ from './answer-list.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class AnswerList extends Component {
  @service appService;
  @service intl;

  get app() {
    return this.appService.app;
  }

  get survey() {
    return this.args.statsParameters.contentObject;
  }

  get additionalFilters() {
    if (this.selectedFilterValue === 0) {
      return null;
    }
    return { 'question.id': this.selectedFilterValue };
  }

  get questions() {
    let questions = this.survey.steps.toArray().flatMap((step) => step.orderedQuestions.toArray());

    if (this.additionalFilters) {
      questions = questions.filter(
        (question) => question.sanitizedId === String(this.selectedFilterValue),
      );
    }

    return questions;
  }

  get filteredQuestion() {
    return this.questions.find(
      (question) => question.sanitizedId === String(this.selectedFilterValue),
    );
  }

  get statistic() {
    return 'answer';
  }

  get titleComponent() {
    return 'stats-system/list/survey/header/answer-title';
  }

  get rowComponent() {
    return 'stats-system/list/survey/row/answer-list-row';
  }

  get tableColumns() {
    let columns = [
      { label: 'Name', valuePath: 'user.displayAs', type: 'avatar-with-text' },
      { label: 'Company name', valuePath: 'company.name' },
    ];

    if (this.questions.length > 0) {
      columns.push(
        ...this.questions.map((question, index) => ({
          label: `Q${index + 1}: ${question.questionTitle}`,
          tooltip: this.questionColumnTooltip(question),
          valuePath: `answers.[${question.sanitizedId}].response`,
          id: question.sanitizedId,
        })),
      );
    }

    if (this.app.canUseDeletedSurveyResponseStats && this.survey.deletedQuestions.length > 0) {
      columns.push(
        ...this.survey.deletedQuestions.map((question) => ({
          label: this.intl.t('stats-system.list.surveys.deleted-question', {
            question: question.questionTitle,
          }),
          tooltip: this.intl.t('stats-system.list.surveys.deleted-question-tooltip'),
          valuePath: `answers.[${question.sanitizedId}].response`,
          id: question.sanitizedId,
        })),
      );
    }

    columns.push({ label: 'Date responded', valuePath: 'createdAt' });

    return columns;
  }

  questionColumnTooltip(question) {
    let label;

    if (question.attributeIdentifier) {
      let attributeIdentifier = question.attributeIdentifier.split('.').lastObject;
      label = `Saved to attribute: ${attributeIdentifier}`;
    }

    return label;
  }

  get selectedFilterValue() {
    return Number(this.args.filter) || 0;
  }
}
