/* import __COLOCATED_TEMPLATE__ from './answer-list-row.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class AnswerListRow extends Component {
  @service contentEditorService;
  @service appService;

  get app() {
    return this.appService.app;
  }

  get survey() {
    return this.contentEditorService.activeRulesetLink.object;
  }

  get questions() {
    let questions = this.survey.steps.toArray().flatMap((step) => step.orderedQuestions.toArray());
    let deletedQuestions = this.survey.deletedQuestions.toArray();
    if (!this.app.canUseDeletedSurveyResponseStats || deletedQuestions === 0) {
      return questions;
    }
    return [...questions, ...deletedQuestions];
  }

  get answers() {
    let answers = [];

    this.questions.forEach((question) => {
      let questionId = Number(question.sanitizedId);
      let stats = this.args.row.data.stats.filter((stat) => stat.questionId === questionId);
      let response = stats.map((stat) => stat.response).join(', ');
      answers.push({ id: questionId, response: response || '-' });
    });

    return answers;
  }
}
