/* import __COLOCATED_TEMPLATE__ from './basket-info-item.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  translationKey: string;
  baseUsage?: string;
  price?: string;
  fromPrice?: string;
  toPrice?: string;
}

interface Signature {
  Element: any;
  Args: Args;
}

const BasketInfoItem = templateOnlyComponent<Signature>();
export default BasketInfoItem;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Signup::Teams::Pricing5::Components::BasketInfoItem': typeof BasketInfoItem;
  }
}
