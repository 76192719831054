/* import __COLOCATED_TEMPLATE__ from './plan-feature-component.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import templateOnlyComponent from '@ember/component/template-only';
import { type Feature } from 'embercom/components/signup/teams/pricing5/plan-builder';

const PlanFeatureComponent = templateOnlyComponent<Signature>();

interface Args {
  feature: Feature;
}

interface Signature {
  Args: Args;
}

export default PlanFeatureComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Signup::Teams::Pricing5::PlanFeatureComponent': typeof PlanFeatureComponent;
  }
}
