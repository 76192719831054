/* import __COLOCATED_TEMPLATE__ from './click-title.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import { stats } from 'embercom/models/data/stats-system/stats-constants';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { restartableTask } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import { statisticKeys } from 'embercom/models/data/outbound/constants';

export default class ClickTitle extends Component {
  @service appService;
  @service contentEditorService;
  @service store;
  @service intl;

  @tracked contentLinks = [];

  constructor() {
    super(...arguments);
    this.fetchContentLinks.perform();
  }

  @restartableTask
  *fetchContentLinks() {
    let content = this.args.statsParameters.contentObject;
    if (this.args.statsParameters.contentObject.localizedContents) {
      content = this.args.statsParameters.contentObject.localizedContents.firstObject;
    }
    this.contentLinks = yield this.store.query('content-service/content-link', {
      app_id: this.appService.app.id,
      model_id: this._stripRulesetVersionId(content.id),
      model_name: content.constructor.modelName,
    });
  }

  get showFilterDropdown() {
    // TODO: This really shouldn't be using the content editor service as it breaks the separation of concerns that
    // should be happening here. Instead, the BaseStatList should provide the ability to specify a list header block
    // wherein we'd have access to whether there are any click stats.
    let rulesetLink = this.contentEditorService.activeRulesetLink;

    if (!rulesetLink) {
      return false;
    }

    let statistic = rulesetLink.objectStats.findBy('key', statisticKeys.clicks);
    return statistic.value > 0 && this.filterItems.length > 1;
  }

  get filterItems() {
    let items = [
      {
        text: this.intl.t('stats-system.list.header.bulk-actions-buttons.clicked-on-any'),
        value: 0,
        component: 'stats-system/list/truncated-filter-item',
        tooltipText: this.intl.t('stats-system.list.header.bulk-actions-buttons.clicked-on-any'),
      },
    ];
    if (this.contentLinks.length > 0) {
      items.push(
        ...this.contentLinks.map((link) => ({
          text: `${this.intl.t('stats-system.list.header.bulk-actions-buttons.clicked-on')} ${
            link.url
          }`,
          value: parseInt(link.id, 10),
          component: 'stats-system/list/truncated-filter-item',
          tooltipText: link.url,
        })),
      );
    } else if (this.selectedFilterValue !== 0) {
      items.push({
        text: this.intl.t('stats-system.list.header.bulk-actions-buttons.loading'),
        value: this.selectedFilterValue,
      });
    }
    return items;
  }

  get selectedFilterValue() {
    return parseInt(this.args.filter, 10) || 0;
  }

  _stripRulesetVersionId(localizedContentId) {
    // In Ruleset versions, localizedContentId is a composite key in the following format:
    // "rv-${version.id}-${ruleset.id}-${rulesetLink.id}-${object.id}-${localizedContent.id}"
    // We're splitting the ids by the separator - and taking the last value as that's the ID we're looking for
    return localizedContentId.split('-').lastObject;
  }

  @action
  changeFilter(filter) {
    this.args.changeView(stats.click, filter);
  }
}
