/* import __COLOCATED_TEMPLATE__ from './blocking-page.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class BlockingPage extends Component {
  @service purchaseAnalyticsService;

  constructor() {
    super(...arguments);
    this.purchaseAnalyticsService.trackEvent({
      context: 'signup',
      action: 'viewed',
      object: this.args.analyticsObjectName,
    });
  }

  @action
  onButtonClick() {
    this.purchaseAnalyticsService.trackEvent({
      context: 'signup',
      action: 'clicked',
      object: this.args.analyticsObjectName,
    });
  }
}
