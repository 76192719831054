/* import __COLOCATED_TEMPLATE__ from './date-cell.hbs'; */
/* RESPONSIBLE TEAM: team-app-security */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

interface DateCellArgs {
  row:
    | {
        event?: {
          created_at: Date;
        };
      }
    | {
        created_at: Date;
      };
  column: {
    widthStyle: string;
  };
}

export default class DateCell extends Component<DateCellArgs> {
  @service declare appService: any;

  get event(): { created_at: Date } {
    let row = this.args.row;
    return 'event' in row && row.event ? row.event : (row as { created_at: Date });
  }

  get appTimezone(): string {
    return this.appService.app.timezone;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'TableCells::AdminEvents::DateCell': typeof DateCell;
    'table-cells/admin-events/date-cell': typeof DateCell;
  }
}
