/* import __COLOCATED_TEMPLATE__ from './simple-composer-audience-user-component.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';

export default class SimpleComposerAudienceUserComponent extends Component {
  get isError() {
    if (!this.args.isEmailMessage) {
      return false;
    }

    if (this.args.userCount > 1 && this.args.user.unsubscribed_from_emails) {
      return true;
    }

    return this.args.user.canNotBeEmailed;
  }
}
