/* import __COLOCATED_TEMPLATE__ from './early-stage-application-form-component.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { and, equal, not, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency';
import Validations from 'embercom/controllers/base/early_stage/validations';
import inclusiveRange from 'embercom/lib/inclusive-range';
import moment from 'moment-timezone';

function showErrorComputed(prop, didSubmitProp) {
  return computed(
    `validations.attrs.${prop}.{isDirty,isInvalid}`,
    `${prop}Visited`, // eslint-disable-line @intercom/intercom/no-bare-strings
    didSubmitProp,
    function () {
      let isDirty = this.get(`validations.attrs.${prop}.isDirty`);
      let didVisit = this.get(`${prop}Visited`); // eslint-disable-line @intercom/intercom/no-bare-strings
      let didSubmit = this.get(didSubmitProp);
      let isInvalid = this.get(`validations.attrs.${prop}.isInvalid`);

      return ((isDirty && didVisit) || didSubmit) && isInvalid;
    },
  );
}

function showIntentOtherErrorComputed() {
  return computed('validations.attrs.intentOther.{isDirty,isInvalid}', 'intent', function () {
    return this.intent === 'other' && this.get('validations.attrs.intentOther.isInvalid');
  });
}

export default Component.extend(Validations, {
  isSubmitting: false,
  didSubmitCompany: false,
  purchaseAnalyticsService: service(),
  appService: service(),
  intl: service(),
  app: readOnly('appService.app'),
  cardlessTrialService: service(),
  earlyStageService: service(),
  solutionId: null,
  context: 'early_stage_signup',
  place: 'early_stage_application',
  fundingLevelOptions: computed('earlyStageService.isPartnerProgram', 'intl.locale', function () {
    if (this.earlyStageService.isPartnerProgram) {
      return [
        { label: this.intl.t('teams-checkout.early_stage.labels.pre_seed_seed'), value: 14 },
        { label: this.intl.t('teams-checkout.early_stage.labels.series_a'), value: 15 },
        { label: this.intl.t('teams-checkout.early_stage.labels.series_b_plus'), value: 16 },
      ];
    } else {
      return [
        {
          label: this.intl.t('teams-checkout.early_stage.funding_options.less_than_500000'),
          value: 10,
        },
        {
          label: this.intl.t(
            'teams-checkout.early_stage.funding_options.between_500000_and_1000000',
          ),
          value: 11,
        },
        {
          label: this.intl.t(
            'teams-checkout.early_stage.funding_options.between_1000001_and_3000000',
          ),
          value: 12,
        },
        {
          label: this.intl.t('teams-checkout.early_stage.funding_options.more_than_3000000'),
          value: 13,
        },
      ];
    }
  }),

  companySizeOptions: computed('intl.locale', function () {
    // https://github.com/intercom/intercom/issues/334573#issuecomment-2222952249
    // We deviate from the standard set of ranges here to meet the ES Partner criteria of < 25 employees
    return [
      {
        label: this.intl.t('teams-checkout.early_stage.employees_number_options.one_to_five'),
        value: '1-5',
      },
      {
        label: this.intl.t('teams-checkout.early_stage.employees_number_options.six_to_nine'),
        value: '6-9',
      },
      {
        label: this.intl.t(
          'teams-checkout.early_stage.employees_number_options.ten_to_twenty-five',
        ),
        value: '10-25',
      },
      {
        label: this.intl.t(
          'teams-checkout.early_stage.employees_number_options.twenty-six_to_forty-nine',
        ),
        value: '26-49',
      },
      {
        label: this.intl.t(
          'teams-checkout.early_stage.employees_number_options.fifty_to_one-hundred-ninety-nine',
        ),
        value: '50-199',
      },
      {
        label: this.intl.t(
          'teams-checkout.early_stage.employees_number_options.two-hundred_to_nine-hundred-ninety-nine',
        ),
        value: '200-999',
      },
      {
        label: this.intl.t('teams-checkout.early_stage.employees_number_options.one-thousand_plus'),
        value: '1000+',
      },
    ];
  }),

  monthOptions: computed('intl.locale', function () {
    return [
      { label: this.intl.t('months.january'), value: 1 },
      { label: this.intl.t('months.february'), value: 2 },
      { label: this.intl.t('months.march'), value: 3 },
      { label: this.intl.t('months.april'), value: 4 },
      { label: this.intl.t('months.may'), value: 5 },
      { label: this.intl.t('months.june'), value: 6 },
      { label: this.intl.t('months.july'), value: 7 },
      { label: this.intl.t('months.august'), value: 8 },
      { label: this.intl.t('months.september'), value: 9 },
      { label: this.intl.t('months.october'), value: 10 },
      { label: this.intl.t('months.november'), value: 11 },
      { label: this.intl.t('months.december'), value: 12 },
    ];
  }),

  yearOptions: computed(function () {
    return inclusiveRange(moment().year(), 2012);
  }),

  intentOptions: computed('intl.locale', function () {
    return [
      {
        label: this.intl.t('teams-checkout.early_stage.intent.capture'),
        value: 'capture-and-convert',
      },
      {
        label: this.intl.t('teams-checkout.early_stage.intent.onboard'),
        value: 'onboard-and-engage',
      },
      {
        label: this.intl.t('teams-checkout.early_stage.intent.retain'),
        value: 'support-and-retain',
      },
      {
        label: this.intl.t('teams-checkout.early_stage.intent.support'),
        value: 'self-service-support',
      },
      { label: this.intl.t('teams-checkout.early_stage.intent.other'), value: 'other' },
      { label: this.intl.t('teams-checkout.early_stage.intent.all'), value: 'all' },
    ];
  }),

  companyAIFocusOptions: computed('intl.locale', function () {
    return [
      {
        label: this.intl.t('teams-checkout.early_stage.company_ai_focus.not_focused_on_ai'),
        value: 'not_focused_on_ai',
      },
      {
        label: this.intl.t('teams-checkout.early_stage.company_ai_focus.utilizing_ai'),
        value: 'utilizing_ai',
      },
      {
        label: this.intl.t('teams-checkout.early_stage.company_ai_focus.developing_ai'),
        value: 'developing_ai',
      },
      {
        label: this.intl.t(
          'teams-checkout.early_stage.company_ai_focus.both_developing_and_utilizing_ai',
        ),
        value: 'both_developing_and_utilizing_ai',
      },
    ];
  }),

  companySize: null,
  fundingLevel: null,
  yearFoundedInput: null,
  companyWebsite: null,
  companyLinkedIn: null,
  companyCrunchbase: null,
  selectedPartner: null,

  partnerSelections: computed('earlyStageService.partners', function () {
    return this.earlyStageService.partners.sort().map((partner) => {
      return { text: partner, value: partner };
    });
  }),

  selectedPartnerIsValid: computed(
    'shouldShowPartnerSelection',
    'showPartnerSelectionError',
    function () {
      return !(this.shouldShowPartnerSelection && this.showPartnerSelectionError);
    },
  ),

  heading: computed('notEligibleForEarlyStage', 'intl.locale', function () {
    if (this.notEligibleForEarlyStage) {
      return this.intl.t('teams-checkout.early_stage.thanks');
    } else {
      return this.intl.t('teams-checkout.early_stage.apply');
    }
  }),

  companyNameVisited: false,
  companyWebsiteVisited: false,
  companyLinkedInVisited: false,
  intentOtherVisited: false,
  showCompanyWebsiteError: showErrorComputed('companyWebsite', 'didSubmitCompany'),
  showFundingLevelError: showErrorComputed('fundingLevel', 'didSubmitCompany'),
  showCompanySizeError: showErrorComputed('companySize', 'didSubmitCompany'),
  showYearFoundedError: showErrorComputed('yearFounded', 'didSubmitCompany'),
  showIntentError: showErrorComputed('intent', 'didSubmitCompany'),
  showIntentOtherError: showIntentOtherErrorComputed('didSubmitCompany'),
  showPartnerSelectionError: showErrorComputed('selectedPartner', 'didSubmitCompany'),

  yearFoundedIsValid: computed('shouldShowYearFounded', 'showYearFoundedError', function () {
    return this.shouldShowYearFounded ? !this.showYearFoundedError : true;
  }),

  yearFounded: computed('shouldShowYearFounded', 'yearFoundedInput', function () {
    return this.shouldShowYearFounded ? this.yearFoundedInput : null;
  }),

  starterErrors: null,

  shouldShowPartnerSelection: computed(
    'earlyStageService.{isPartnerProgram,isPartnerValid}',
    function () {
      return this.earlyStageService.isPartnerProgram && !this.earlyStageService.isPartnerValid;
    },
  ),
  shouldShowYearFounded: not('earlyStageService.isPartnerProgram'),
  shouldShowIntentOther: equal('intent', 'other'),
  intentOtherIsValid: computed('shouldShowIntentOther', 'showIntentOtherError', function () {
    return !(this.shouldShowIntentOther && this.showIntentOtherError);
  }),

  companyInformationValid: and(
    'validations.attrs.companyWebsite.isValid',
    'validations.attrs.companySize.isValid',
    'validations.attrs.fundingLevel.isValid',
    'yearFoundedIsValid',
    'selectedPartnerIsValid',
  ),

  _trackInteraction(action = 'entered', object, value) {
    let event = {
      action,
      object,
      context: this.context,
      place: this.place,
      solutionId: this.solutionId,
      value,
    };

    this.purchaseAnalyticsService.trackEvent(event);
  },

  formSubmitTaskRunning: readOnly('submitApplicationTask.isRunning'),

  submitApplicationTask: task(function* () {
    if (this.shouldShowPartnerSelection) {
      this.earlyStageService.partnerSource = this.selectedPartner;
    }

    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      context: 'early_stage_signup',
      place: 'early_stage_application',
      object: 'early_stage_application',
      solutionId: this.solutionId,
    });

    let formData = {
      app_id: this.app.id,
      customer_id: this.app.customer_id,
      company_name: this.app.name,
      company_website: this.companyWebsite,
      company_linkedin: this.companyLinkedIn,
      company_crunchbase: this.companyCrunchbase,
      funding_level: this.fundingLevel,
      company_size: this.companySize,
      partner_source: this.earlyStageService.partnerSource,
      year_founded: this.yearFounded,
      company_ai_focus: this.companyAIFocus,
    };

    yield this.submitApplication(formData);
  }).drop(),

  actions: {
    trackEnteringEvent(object) {
      this._trackInteraction('entered', object);
    },
    dropDownSelected(object, value) {
      this.set(object, value);
      this._trackInteraction('selected', object, value);
    },
    setCompanySize(value) {
      this.set('companySize', value);
      this._trackInteraction('selected', 'companySize', value);
    },
    setCompanyAIFocus(value) {
      this.set('companyAIFocus', value);
      this._trackInteraction('selected', 'companyAIFocus', value);
    },

    setVisited(prop) {
      this.set(`${prop}Visited`, true); // eslint-disable-line @intercom/intercom/no-bare-strings
      this._trackInteraction('entered', prop);
    },

    submitApplicationInformation() {
      this.set('didSubmitCompany', true);
      if (this.companyInformationValid) {
        this.submitApplicationTask.perform();
      }
    },
  },
});
