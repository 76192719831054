/* import __COLOCATED_TEMPLATE__ from './billing-addressees-section.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import templateOnlyComponent from '@ember/component/template-only';

export interface BillingAddresseesSectionArgs {
  title: string;
  emails: string[];
  type: 'added' | 'removed' | 'resulting';
}

let BillingAddresseesSection = templateOnlyComponent<BillingAddresseesSectionArgs>();

export default BillingAddresseesSection;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'TableCells::AdminEvents::BillingAddresseesSection': typeof BillingAddresseesSection;
    'table-cells/admin-events/billing-addressees-section': typeof BillingAddresseesSection;
  }
}
