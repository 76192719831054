/* import __COLOCATED_TEMPLATE__ from './table-cell-edit-name.hbs'; */
/* RESPONSIBLE TEAM: unused-components */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { task } from 'ember-concurrency';

export default Component.extend({
  notificationsService: service(),
  tagName: '',

  actions: {
    saveTag(newName) {
      let tag = this.get('row.tag');
      let oldName = tag.get('name');
      tag.set('name', newName);
      tag.save().catch((error) => {
        if (error.responseJSON.errors.name[0] === 'has already been taken') {
          this.notificationsService.notifyError('Tag name has already been taken');
          tag.set('name', oldName);
        }
      });
    },
  },

  saveTag: task(function* (newName) {
    let oldName = this.tag.name;
    let tag = this.tag;
    tag.set('name', newName);
    try {
      yield tag.save();
    } catch (error) {
      // this catch block never executes - old code broken too
      if (error.responseJSON.errors.name[0] === 'has already been taken') {
        this.notificationsService.notifyError('Tag name has already been taken');
        tag.set('name', oldName);
      }
    }
  }).drop(),
});
