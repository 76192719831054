/* import __COLOCATED_TEMPLATE__ from './addon-feature-pill.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import templateOnlyComponent from '@ember/component/template-only';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

const AddonFeaturePill = templateOnlyComponent<Signature>();

interface Args {
  interfaceIcon: InterfaceIconName;
  marketingTranslationKey: string;
}

interface Signature {
  Args: Args;
}

export default AddonFeaturePill;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Signup::Teams::Pricing5::AddonFeaturePill': typeof AddonFeaturePill;
  }
}
