/* import __COLOCATED_TEMPLATE__ from './content.hbs'; */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type ZendeskConfiguration from 'embercom/models/standalone/zendesk-configuration';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import type ContentImportService from 'embercom/services/content-import-service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

interface Args {}

export default class StandaloneContentSetup extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare notificationsService: any;
  @service declare contentImportService: ContentImportService;

  get zendeskConfig(): ZendeskConfiguration {
    return this.finStandaloneService.zendeskConfig;
  }

  @tracked showAddSourceModal = false;

  @action async reloadContentSources() {
    this.contentImportService.contentImportSources =
      await this.contentImportService.forceFetchContentImportSources();
  }

  get contentImportSources() {
    return this.contentImportService.contentImportSources || [];
  }

  get contentLibrarySummary() {
    return this.finStandaloneService.contentLibrarySummary;
  }

  get hasAnyContent() {
    return Object.keys(this.contentLibrarySummary).some((key) => {
      return this.contentLibrarySummary[Number(key)].total_count > 0;
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Setup::Content': typeof StandaloneContentSetup;
    'standalone/setup/content': typeof StandaloneContentSetup;
  }
}
