/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { linkTags } from 'embercom/models/data/tooltips/constants';

export const getTooltipFilterLabel = (tooltip, index) => {
  let tooltipTextContent = tooltip.blocks
    .filter((block) => block.type === 'paragraph')
    .map((block) => block.text.replace(linkTags, ''))
    .join('');

  // Avoid really long dropdown options
  if (tooltipTextContent.length > 20) {
    tooltipTextContent = `${tooltipTextContent.substring(0, 20)}...`;
  }

  return tooltipTextContent
    ? `Tooltip ${index + 1}: '${tooltipTextContent}'`
    : `Tooltip ${index + 1}`;
};
