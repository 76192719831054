/* import __COLOCATED_TEMPLATE__ from './billing-period-selector.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { VALUE_BASED_PRICING_FEB2022_STARTER_SOLUTION_ID } from 'embercom/lib/billing';
export default class BillingPeriodSelector extends Component {
  @service appService;
  @service signupService;
  @service intl;

  get app() {
    return this.appService.app;
  }

  get discount() {
    return this.args.solutionPlusAddOnPrice?.getAnnualDiscountPercentage();
  }

  get isOnVBP2_1() {
    return this.signupService.solutionId === VALUE_BASED_PRICING_FEB2022_STARTER_SOLUTION_ID;
  }

  get discountText() {
    if (this.isOnVBP2_1) {
      return this.intl.t('signup.build-your-trial.billing_period_selector.twelve_months_for_ten');
    } else {
      return this.intl.t('signup.build-your-trial.billing_period_selector.get_discount_off', {
        discount: this.discount,
      });
    }
  }

  get monthlyDifference() {
    return this.args.solutionPlusAddOnPrice?.getMonthlyDifferenceBetweenBillingPeriods(1, 12);
  }

  get monthlyTotalAfterTrial() {
    return this.args.solutionPlusAddOnPrice?.getMonthlyTotalAfterTrial(1);
  }

  get monthlyTotalAfterTrialForYearly() {
    return this.args.solutionPlusAddOnPrice?.getMonthlyTotalAfterTrial(12);
  }

  get billedYearlyTotalAfterTrial() {
    return this.args.solutionPlusAddOnPrice?.getBilledAsAmount(12);
  }
}
