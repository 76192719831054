/* import __COLOCATED_TEMPLATE__ from './addon-cta-selector.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class BillingPeriodSelector extends Component {
  @action
  toggleAddon() {
    this.args.toggle(this.args.addonId, this.args.source);
  }
}
