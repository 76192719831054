/* import __COLOCATED_TEMPLATE__ from './source.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import type ContentImportRun from 'embercom/models/content-service/content-import-run';
import type ContentImportSource from 'embercom/models/content-service/content-import-source';

interface Signature {
  Args: {
    contentImportSource: ContentImportSource;
    contentImportRun: ContentImportRun;
  };
  Element: HTMLElement;
}

export default class StandaloneContentImportSource extends Component<Signature> {
  get latestRun() {
    return this.args.contentImportRun;
  }

  get statusClasses() {
    if (this.latestRun.isFailed) {
      return 'bg-red-light text-red';
    }

    if (this.latestRun.isFullyIngested) {
      return 'bg-green-light text-green';
    }

    return 'bg-sky-dark text-blue';
  }

  get statusIcon(): InterfaceIconName {
    if (this.latestRun.isFailed) {
      return 'alert';
    }

    if (this.latestRun.isFullyIngested) {
      return 'check';
    }

    return 'sync';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Setup::Content::Source': typeof StandaloneContentImportSource;
    'standalone/setup/content/source': typeof StandaloneContentImportSource;
  }
}
