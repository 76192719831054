/* import __COLOCATED_TEMPLATE__ from './basket.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type Task } from 'ember-concurrency';
import { findSolution, PRICING_5_X_ESSENTIAL_SOLUTION_IDS } from 'embercom/lib/billing';

interface Args {
  solutionId: string;
  addOnPlanIds: number[];
  price: any;
  fetchNewPrices: Task<any, any>;
  billingPeriodDurationInMonths: number;
  couponDiscountAmountInCents: number;
  convertCardlessTrialSubscription: boolean;
  customerHasEverHadSubscription: boolean;
  shouldShowBasketCTAs?: boolean;
}

export default class Basket extends Component<Args> {
  @tracked showItemizedBreakdown = false;

  @service declare appService: any;
  @service declare purchaseAnalyticsService: any;
  @service declare intl: any;
  @service declare router: any;

  get app() {
    return this.appService.app;
  }
  get solution() {
    return findSolution(this.args.solutionId);
  }

  get isOnEssentialPlanCheckout() {
    return PRICING_5_X_ESSENTIAL_SOLUTION_IDS.includes(this.args.solutionId);
  }
  get shouldShowTalkToSalesCTA() {
    return !this.isOnEssentialPlanCheckout;
  }

  get planPriceWithSeatUsage() {
    return this.args.price.getPlanCoreSeatUsageAndPrice(this.args.billingPeriodDurationInMonths);
  }

  get priceBreakdowns() {
    return this.args.price.getBreakdownInfo(this.args.billingPeriodDurationInMonths);
  }

  get liteSeatLimit() {
    return this.solution.liteSeatLimit;
  }

  get currentLiteSeatCount() {
    return this.app.humanAdminsWithLiteSeat?.length || 0;
  }

  get showLiteSeatText() {
    return this.isOnEssentialPlanCheckout && this.currentLiteSeatCount > 0;
  }

  get totalPrice() {
    return (
      (this.args.price.getMonthlyTotalAfterTrial(this.args.billingPeriodDurationInMonths) -
        (this.args.couponDiscountAmountInCents || 0)) /
      100
    );
  }

  get totalPriceFormatted() {
    return this.intl.formatNumber(this.totalPrice, {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    });
  }

  get pricePerSeat() {
    return (
      parseInt(this.planPriceWithSeatUsage.price.replace(/\D/g, ''), 10) /
      parseInt(this.planPriceWithSeatUsage.usage, 10)
    );
  }

  get addons() {
    return this.priceBreakdowns.filter((breakdown: any) => breakdown.addon);
  }

  get finResolutionPrice() {
    return 99;
  }

  get buyNowFlowEnabled() {
    return this.app?.buyNowEssentialFlowEnabled || this.app?.buyNowAdvancedFlowEnabled;
  }

  get isBuyNowFlowAfterEmailVerify() {
    return this.buyNowFlowEnabled && this.router.currentRoute?.queryParams?.buy_now_flow === 'true';
  }

  @action
  transitionToNextStep() {
    let { solutionId, addOnPlanIds, billingPeriodDurationInMonths } = this.args;

    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      object: 'continue_button',
      planIds: addOnPlanIds,
      solutionId,
      context: 'usecase_signup_flow',
      place: 'basket',
      billing_period_duration_in_months: billingPeriodDurationInMonths,
    });

    this.router.transitionTo('apps.app.teams-checkout.confirm', {
      queryParams: { solution_id: this.args.solutionId },
    });
  }

  @action
  talkToSales() {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      context: 'cardless_trial',
      place: 'checkout_basket',
      object: 'talk_to_sales_button',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Signup::Teams::Pricing5::Basket': typeof Basket;
  }
}
