/* import __COLOCATED_TEMPLATE__ from './social-connect-component.hbs'; */
/* RESPONSIBLE TEAM: team-reporting */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-on-calls-in-components */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { action } from '@ember/object';
import { alias } from '@ember/object/computed';
import $ from 'jquery';
import { bind } from '@ember/runloop';
import { on } from '@ember/object/evented';
import safeWindowOpen from 'embercom/lib/safe-window-open';

export default Component.extend({
  setup: on('didInsertElement', function () {
    $(`.social-connect__${this.provider}`, this.element)
      .on(
        'socialConnectSuccess',
        bind(this, function () {
          this.successAction(this.provider);
        }),
      )
      .on(
        'socialConnectFailure',
        bind(this, function () {
          this.failureAction(this.provider);
        }),
      );
  }),
  teardown: on('willDestroyElement', function () {
    $(`.social-connect__${this.provider}`, this.element).off(
      'socialConnectSuccess socialConnectFailure',
    );
  }),
  commonOptions: 'menubar=no,location=no,resizable=no,scrollbars=no,status=no',
  target: alias('targetObject'),
  dimensions: {
    twitter: {
      width: 800,
      height: 600,
    },
    linkedin: {
      width: 843,
      height: 584,
    },
  },
  mouseEnter() {
    this.set('showDeleteButton', true);
  },
  mouseLeave() {
    this.set('showDeleteButton', false);
  },
  windowDimensions() {
    return this.dimensions[this.provider];
  },
  computeWindowPositions(dimensions) {
    let left = window.screen.width / 2 - dimensions.width / 2;
    let top = window.screen.height / 2 - dimensions.height / 2;

    return { left, top };
  },
  windowOptions() {
    let dimensions = this.windowDimensions();
    let position = this.computeWindowPositions(dimensions);

    return `${this.commonOptions},width=${dimensions.width},height=${dimensions.height},top=${position.top},left=${position.left}`;
  },
  url() {
    let host = window.location.host;

    return `//${host}/auth/${this.provider}?${this.params}`;
  },

  openDialogBox: action(function () {
    safeWindowOpen(this.url(), this.placeholderText, this.windowOptions());
  }),
  deleteSocialAccount: action(function () {
    this.deleteAction(this.model, this.provider);
  }),
});
