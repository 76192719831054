/* import __COLOCATED_TEMPLATE__ from './user-menu.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
/* eslint-disable @intercom/intercom/no-component-inheritance */

import UserMenu from 'embercom/components/inbox2/user-menu';

export default class StandaloneUserMenu extends UserMenu {
  get groupList() {
    return [
      {
        items: [
          {
            model: this.session.teammate,
            component: 'inbox2/user-menu/workspaces-switcher',
            componentAttrs: {
              workspacesList: this.workspacesList,
            },
          },
        ],
      },
      {
        items: this.logoutLinkItems,
      },
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Navbar::UserMenu': typeof StandaloneUserMenu;
    'standalone/navbar/user-menu': typeof StandaloneUserMenu;
  }
}
