/* import __COLOCATED_TEMPLATE__ from './revoke-cell.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class RevokeCell extends Component {
  @service notificationsService;
  @service intl;
  @service intercomConfirmService;

  @action
  async revokeInvite() {
    let inviteEmail = this.args.invite.email;
    let options = {
      title: this.intl.t('settings.teammates.invite.invite-list.delete-invite'),
      body: this.intl.t('settings.teammates.invite.invite-list.revoke-alert', {
        email: inviteEmail,
      }),
      confirmButtonText: this.intl.t('settings.teammates.invite.invite-list.delete-invite'),
      primaryButtonType: 'primary-destructive',
    };
    let confirmed = await this.intercomConfirmService.confirm(options);

    if (confirmed) {
      try {
        await this.args.invite.destroyRecord();
        this.notificationsService.notifyConfirmation(
          this.intl.t('settings.teammates.invite.invite-list.invite-revoked', {
            email: inviteEmail,
          }),
        );
      } catch (err) {
        this.notificationsService.notifyError(
          this.intl.t('settings.teammates.invite.invite-list.revoke-error'),
        );
      }
    }
  }
}
