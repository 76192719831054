/* import __COLOCATED_TEMPLATE__ from './step-controls.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import storage from 'embercom/vendor/intercom/storage';
import { inject as service } from '@ember/service';

export default class StepControl extends Component {
  @service appService;

  get app() {
    return this.appService.app;
  }

  get survey() {
    return this.args.survey;
  }

  get steps() {
    return this.survey.steps.filter((step) => !step.isThankYouType);
  }

  get thankYouSteps() {
    return this.survey.thankYouSteps;
  }

  get introStep() {
    return this.survey.steps.find((step) => step.isIntroType);
  }

  get pathDebuggingEnabled() {
    return storage.get('surveys-debug-branching-paths');
  }
}
