/* import __COLOCATED_TEMPLATE__ from './checkout-header.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { SUPPORTED_LANGUAGES } from 'embercom/services/intl';
import type IntlService from 'embercom/services/intl';
import type Session from 'embercom/services/session';
import { type ValidLocales } from '../../../../inbox2/user-menu/locale-switcher';

interface Signature {
  Args: {};
  Element: never;
  Blocks: {
    default: [];
  };
}

export default class CheckoutHeader extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare session: Session;
  @service declare appService: any;

  get app() {
    return this.appService.app;
  }

  get localeList() {
    return SUPPORTED_LANGUAGES.map(({ locale: value, language: text }) => {
      return {
        value,
        text,
        onSelectItem: () => {
          this.session.changeLanguage(value as ValidLocales, this.app, this.app.currentAdmin);
        },
      };
    });
  }

  get locale() {
    return this.localeList.filter(
      (item) => item.value.toUpperCase() === this.selectedLocale.toUpperCase(),
    )[0];
  }

  get selectedLocale() {
    return this.intl.primaryLocale;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Signup::Teams::Pricing5::Components::CheckoutHeader': typeof CheckoutHeader;
  }
}
