/* import __COLOCATED_TEMPLATE__ from './default-empty-state.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class DefaultEmptyState extends Component {
  @service contentEditorService;

  get emptyStateTitle() {
    if (this.contentEditorService.isViewingVersionSnapshot) {
      return 'No data recorded for this version';
    } else {
      return (
        this.args.overrideEmptyStateTitle ||
        `No one's ${this.args.verb} your ${this.args.statsParameters.uiObjectName} yet`
      );
    }
  }
}
