/* import __COLOCATED_TEMPLATE__ from './goal-success-title.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { stats } from 'embercom/models/data/stats-system/stats-constants';
import { action } from '@ember/object';

export default class GoalSuccessTitle extends Component {
  @service intercomEventService;

  get filterItems() {
    let items = [{ text: `Hit the goal`, value: stats.receipt }];

    if (!this.args.statsParameters.goal.usesCompanyPredicate) {
      if (
        typeof this.args.statsParameters.contentObject.showStat === 'function' &&
        this.args.statsParameters.contentObject.showStat(stats.completion)
      ) {
        items.push({
          text: `Hit the goal after completing the ${this.args.statsParameters.uiObjectName}`,
          value: stats.completion,
        });
      }

      if (this.args.filterConfig) {
        items = items.concat(this.args.filterConfig);
      }
    }

    return items;
  }

  @action
  changeFilter(filter) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'filtered_goal_success_list',
      object: filter,
      goal_id: this.args.statsParameters.goal.id,
    });
    this.args.changeView(stats.goalSuccess, filter);
  }
}
