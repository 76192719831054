/* import __COLOCATED_TEMPLATE__ from './multi-select.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class MultiSelect extends Component {
  @tracked isMultiSelect = false;

  constructor() {
    super(...arguments);
    this.isMultiSelect = this.question.data.isMultiSelect;
  }

  get question() {
    return this.args.question;
  }

  @action toggleMultiSelect() {
    this.isMultiSelect = !this.isMultiSelect;
    this.args.handleMultiSelectMax(this.question, this.isMultiSelect);
  }
}
