/* import __COLOCATED_TEMPLATE__ from './sms-failure-list-row.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import {
  failureListCategories,
  smsErrorMessages,
} from '../../../../models/data/stats-system/stats-constants';

export default class SmsFailureListRow extends Component {
  get errorMessage() {
    if (this.args.row.data.failureCategory === failureListCategories.unsubscribe) {
      return smsErrorMessages.unsubscribe;
    }
    // display usage limits error when limits reached.
    if (this.args.row.data.messageStatus === 'usage_limits_reached') {
      return smsErrorMessages.usageLimitsReached;
    }
    return smsErrorMessages[this.args.row.data.errorCode] || smsErrorMessages.default;
  }
}
