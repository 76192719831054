/* import __COLOCATED_TEMPLATE__ from './sunshine-activation.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-empty-glimmer-component-classes */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type ZendeskConfiguration from 'embercom/models/standalone/zendesk-configuration';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import { action } from '@ember/object';
import ENV from 'embercom/config/environment';

interface Args {}

export default class StandaloneZendeskSetupSunshineActivation extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare notificationsService: any;

  get zendeskConfig(): ZendeskConfiguration {
    return this.finStandaloneService.zendeskConfig;
  }

  @action async activateFinForMessenger() {
    try {
      await this.zendeskConfig.activateForMessenger();
      this.notifyConfirmation('Fin has been enabled for Zendesk messenger');
    } catch (e) {
      this.notificationsService.notifyResponseError(e, {
        default: `Something went wrong and we couldn't activate Fin. Please try again`,
      });
    }
  }

  @action async deactivateFinForMessenger() {
    try {
      await this.zendeskConfig.deactivateForMessenger();
      this.notifyConfirmation('Fin has been disabled for Zendesk messenger');
    } catch (e) {
      this.notificationsService.notifyResponseError(e, {
        default: `Something went wrong and we couldn't deactivate Fin. Please try again`,
      });
    }
  }

  notifyConfirmation(message: string) {
    this.notificationsService.notifyConfirmation(
      message,
      ENV.APP._2000MS,
      'fin-standalone-activation',
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Zendesk::Setup::SunshineActivation': typeof StandaloneZendeskSetupSunshineActivation;
    'standalone/zendesk/setup/sunshine-activation': typeof StandaloneZendeskSetupSunshineActivation;
  }
}
