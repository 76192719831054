/* import __COLOCATED_TEMPLATE__ from './library-summary.hbs'; */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import type ContentImportService from 'embercom/services/content-import-service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type ArticleSyncSetting from 'embercom/models/articles/article-sync-setting';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import type Store from '@ember-data/store';
import { EntityType } from 'embercom/models/data/entity-types';
import { type TaskGenerator } from 'ember-concurrency';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

interface Args {
  placeholderCount?: number;
}

export default class LibrarySummary extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare notificationsService: any;
  @service declare contentImportService: ContentImportService;
  @service declare store: Store;
  @service declare realTimeEventService: any;

  @tracked showAddKnowledgeModal = false;
  @tracked syncSettings: ArticleSyncSetting[] = [];
  @tracked isSyncingArticles = false;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    taskFor(this.loadArticleSyncSetting).perform();
    this.realTimeEventService.on('ImportStarted', this, 'handleArticleSyncStarted');
    this.realTimeEventService.on('ImportCompleted', this, 'handleArticleSyncCompleted');
  }

  willDestroy() {
    super.willDestroy();
    this.realTimeEventService.off('ImportStarted', this, 'handleArticleSyncStarted');
    this.realTimeEventService.off('ImportCompleted', this, 'handleArticleSyncCompleted');
  }

  get placeholders() {
    // We need at least one placeholder to show the "no knowledge" card
    return Array.from(
      { length: Math.max(1, this.args.placeholderCount ?? 0) },
      (_, index) => index,
    );
  }

  @action async reloadContentSources() {
    this.contentImportService.contentImportSources =
      await this.contentImportService.forceFetchContentImportSources();
  }

  @action
  toggleAddKnowledgeModal(value: boolean) {
    this.showAddKnowledgeModal = value;
  }

  @action
  async reloadSources() {
    await this.reloadContentSources();
    await taskFor(this.loadArticleSyncSetting).perform();
    taskFor(this.finStandaloneService.fetchLibrarySummary).perform();
  }

  @task({ drop: true })
  *loadArticleSyncSetting(): TaskGenerator<void> {
    let syncSettings = yield this.store.findAll('articles/article-sync-setting');
    this.syncSettings = syncSettings;
  }

  get articleSyncSetting(): ArticleSyncSetting | undefined {
    return this.syncSettings.find(
      (setting: ArticleSyncSetting) =>
        setting.provider === 'zendesk' && setting.status === 'active',
    );
  }

  get isLoading() {
    return (
      (this.contentImportService.isLoadingSources && this.contentImportSources.length === 0) ||
      (!this.articleSyncSetting && taskFor(this.loadArticleSyncSetting).isRunning)
    );
  }

  get showNoKnowledgePlaceholder() {
    return (
      !this.articleSyncSetting && this.contentImportSources.length === 0 && !this.hasSnippetsOrPdfs
    );
  }

  get contentImportSources() {
    return this.contentImportService.contentImportSources || [];
  }

  get contentLibrarySummary() {
    return this.finStandaloneService.contentLibrarySummary;
  }

  get hasAnyContent() {
    return Object.keys(this.contentLibrarySummary).some((key) => {
      return this.contentLibrarySummary[Number(key)].total_count > 0;
    });
  }

  get articleCount() {
    return this.contentLibrarySummary[EntityType.ArticleContent]?.total_count || 0;
  }

  get snippetCount() {
    return this.contentLibrarySummary[EntityType.ContentSnippet]?.total_count || 0;
  }

  get pdfCount() {
    return this.contentLibrarySummary[EntityType.FileSourceContent]?.total_count || 0;
  }

  get hasSnippetsOrPdfs() {
    return this.snippetCount > 0 || this.pdfCount > 0;
  }

  get snippetsAndPdfsDescription() {
    let parts = [];

    if (this.snippetCount > 0) {
      parts.push(`${this.snippetCount} ${this.snippetCount === 1 ? 'Snippet' : 'Snippets'}`);
    }

    if (this.pdfCount > 0) {
      parts.push(`${this.pdfCount} ${this.pdfCount === 1 ? 'PDF' : 'PDFs'}`);
    }

    return parts.join(', ');
  }

  get zendeskSyncStatusIcon(): InterfaceIconName {
    return this.isSyncingArticles ? 'sync' : 'check';
  }

  get zendeskSyncStatusClasses() {
    return this.isSyncingArticles ? 'bg-sky-dark text-blue' : 'bg-green-light text-green';
  }

  handleArticleSyncStarted() {
    this.isSyncingArticles = true;
  }

  handleArticleSyncCompleted() {
    this.isSyncingArticles = false;
    taskFor(this.finStandaloneService.fetchLibrarySummary).perform();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Channels::LibrarySummary': typeof LibrarySummary;
    'standalone/channels/library-summary': typeof LibrarySummary;
  }
}
