/* import __COLOCATED_TEMPLATE__ from './usage-limit-status-banner.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
interface Args {
  remainingUsage: number;
}

const REMAINING_USAGE_BANNER_VISIBLE_THRESHOLD = 50;

export default class UsageLimitStatusBanner extends Component<Args> {
  @service declare contentEditorService: any;

  get showSendingIsBlockedBanner() {
    return (
      this.contentEditorService?.activeRulesetLink?.object.isSendingBlocked ||
      (this.args.remainingUsage !== null && this.args.remainingUsage <= 0)
    );
  }

  get showRemainingUsageBanner() {
    if (
      this.args.remainingUsage === null ||
      this.args.remainingUsage >= REMAINING_USAGE_BANNER_VISIBLE_THRESHOLD
    ) {
      return false;
    }
    return true;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Sms::UsageLimitStatsBanner': typeof UsageLimitStatusBanner;
  }
}
