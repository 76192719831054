/* import __COLOCATED_TEMPLATE__ from './tour-step-failure-empty-state.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class TourStepFailureEmptyState extends Component {
  @service intercomEventService;

  @action
  trackLearnMoreEvent() {
    let tourId = this.args.statsParameters.contentId;
    this.intercomEventService.trackAnalyticsEvent({
      tour_id: tourId,
      object: 'tour_help_doc',
      action: 'clicked',
      helpdoc_feature: 'product_tours',
      helpdoc_name: 'issues_learn_more',
    });
  }
}
