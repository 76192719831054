/* import __COLOCATED_TEMPLATE__ from './inbox-seat-cell.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class InboxSeatCell extends Component {
  @service appService;

  get teammate() {
    return this.args.row.teammate;
  }

  get isLastTeammateWithInboxAccess() {
    return this.appService.app.adminsWithInboxAccess.length === 1
      ? this.teammate.hasInboxAccess === true
      : false;
  }

  get seatsPaywalled() {
    return (
      this.teammate && !this.teammate.hasInboxAccess && this.args.tableActions.seatsPaywall.isActive
    );
  }
}
