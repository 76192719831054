/* import __COLOCATED_TEMPLATE__ from './copilot-access-cell.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type IntlService from 'ember-intl/services/intl';
import type Store from '@ember-data/store';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';
import { type SeatType, COPILOT_SEAT_TYPE } from 'embercom/lib/settings/seats/constants';
import type Admin from 'embercom/models/admin';
import type CopilotUsageService from 'embercom/services/copilot-usage-service';
import { COPILOT_FREE_CREDIT_LIMIT } from 'embercom/services/copilot-usage-service';
import mapSelectionToSeatAndPermission from 'embercom/lib/settings/copilot-access-editor/map-selection-to-seat-and-permission';
import {
  COPILOT_PERMISSION,
  LIMITED_USAGE,
  NO_ACCESS,
  UNLIMITED_USAGE,
} from 'embercom/lib/settings/copilot-access-editor/constants';
import type CopilotDataService from 'embercom/services/copilot-data-service';

interface Signature {
  Args: {
    teammate: Admin;
  };
  Element: HTMLDivElement;
}

export default class CopilotAccessCell extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;
  @service declare copilotUsageService: CopilotUsageService;
  @service declare customerService: $TSFixMe;
  @service declare permissionsMutatorService: $TSFixMe;
  @service declare intercomConfirmService: IntercomConfirmService;
  @service declare store: Store;
  @service declare copilotDataService: CopilotDataService;

  get teammate() {
    return this.args.teammate;
  }

  get count() {
    return this.copilotUsageService.adminUsedCreditCounts[this.teammate.id] ?? 0;
  }

  get teammateHasLiteSeat() {
    return this.appService.app.hasMultipleSeatTypes && this.teammate.hasPricing5LiteSeat;
  }

  get teammateDoesNotHaveSupportSeat() {
    return (
      this.appService.app.hasMultipleSeatTypes &&
      this.appService.app.notOnPricing5 &&
      !this.teammate.hasSupportSeat
    );
  }

  get teammateDoesNotHaveInboxAccess() {
    return this.appService.app.canUsePerProductPricingFlow && !this.teammate.hasInboxAccess;
  }

  get currentTeammateDoesNotHaveCopilotAccess() {
    return (
      this.appService.app.currentAdmin === this.teammate &&
      this.teammate.copilotAccessType === NO_ACCESS
    );
  }

  get isDropdownDisabled() {
    return (
      this.currentTeammateDoesNotHaveCopilotAccess ||
      this.teammateDoesNotHaveInboxAccess ||
      this.teammateDoesNotHaveSupportSeat ||
      this.teammateHasLiteSeat
    );
  }

  get accessType() {
    if (this.teammate.hasLimitedCopilotAccess) {
      return this.intl.t('table-cells.teammate-list.copilot-access-cell.included');
    } else if (this.teammate.hasUnlimitedCopilotAccess) {
      return this.intl.t('table-cells.teammate-list.copilot-access-cell.unlimited');
    }
    return this.intl.t('table-cells.teammate-list.copilot-access-cell.off');
  }

  get accessTypeItems() {
    let cannotDisableCopilot =
      this.appService.app.currentAdmin === this.teammate &&
      this.teammate.copilotAccessType !== NO_ACCESS;

    let unlimitedDescriptionSuffix = this.hasContractedSeatsLeft
      ? 'seats-remaining'
      : 'price-per-month';
    return [
      {
        text: this.intl.t('table-cells.teammate-list.copilot-access-cell.unlimited'),
        value: UNLIMITED_USAGE,
        isDisabled: this.rolePreventsAccessLevel(UNLIMITED_USAGE),
        component: 'table-cells/teammate-list/copilot-access-dropdown-item',
        componentAttrs: {
          description: this.intl.t(
            `table-cells.teammate-list.copilot-access-cell.${unlimitedDescriptionSuffix}`,
            { price: this.copilotSeatPrice, seatsRemaining: this.contractedSeatsRemaining },
          ),
        },
        tooltipText: this.tooltipTextForToggleDisabled(
          false,
          this.rolePreventsAccessLevel(UNLIMITED_USAGE),
        ),
      },
      {
        text: this.intl.t('table-cells.teammate-list.copilot-access-cell.included'),
        value: LIMITED_USAGE,
        isDisabled: this.rolePreventsAccessLevel(LIMITED_USAGE),
        component: 'table-cells/teammate-list/copilot-access-dropdown-item',
        componentAttrs: {
          description: this.intl.t(
            'table-cells.teammate-list.copilot-access-cell.included-description',
            { count: COPILOT_FREE_CREDIT_LIMIT },
          ),
        },
        tooltipText: this.tooltipTextForToggleDisabled(
          false,
          this.rolePreventsAccessLevel(LIMITED_USAGE),
        ),
      },
      {
        text: this.intl.t('table-cells.teammate-list.copilot-access-cell.off'),
        value: NO_ACCESS,
        isDisabled: cannotDisableCopilot || this.rolePreventsAccessLevel(NO_ACCESS),
        component: 'table-cells/teammate-list/copilot-access-dropdown-item',
        componentAttrs: {
          description: this.intl.t('table-cells.teammate-list.copilot-access-cell.off-description'),
        },
        tooltipText: this.tooltipTextForToggleDisabled(
          cannotDisableCopilot,
          this.rolePreventsAccessLevel(NO_ACCESS),
        ),
      },
    ];
  }

  get creditUsageTranslationSuffix(): string | undefined {
    if (this.teammate.hasUnlimitedCopilotAccess) {
      return 'unlimited-conversation-count';
    } else if (this.copilotUsageService.adminIsAtLimit(this.teammate)) {
      return 'limit-reached';
    } else if (this.teammate.hasLimitedCopilotAccess) {
      return 'limited-conversation-count';
    }

    return;
  }

  get adminIsAtCreditsLimit() {
    return this.creditUsageTranslationSuffix === 'limit-reached';
  }

  get creditUsage() {
    if (!this.creditUsageTranslationSuffix) {
      return;
    }

    return this.intl.t(
      `table-cells.teammate-list.copilot-access-cell.${this.creditUsageTranslationSuffix}`,
      { count: this.count, limit: COPILOT_FREE_CREDIT_LIMIT },
    );
  }

  get copilotSeatPrice() {
    let priceInCents = this.copilotDataService.copilotData.price;
    return this.intl.formatNumber(priceInCents / 100, {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
    });
  }

  get hasContractedSeatsLeft() {
    return (
      this.contractedSeatsRemaining > 0 &&
      (this.appService.app.isSalesforceContracted ||
        this.customerService.isP5SelfServeAnnualCustomer)
    );
  }

  get contractedSeatsRemaining() {
    let { contractedLimit, used } = this.copilotDataService.copilotData.seatUsages;

    return contractedLimit - used;
  }

  @action
  toggleSeat(seat: SeatType) {
    let isCopilotSeat = seat === COPILOT_SEAT_TYPE;

    if (this.teammate.seats.includes(seat)) {
      this.teammate.seats.removeObject(seat);

      if (isCopilotSeat) {
        this.copilotDataService.updateSeatUsage('decrement');
      }
    } else {
      this.teammate.seats.pushObject(seat);

      if (isCopilotSeat) {
        this.copilotDataService.updateSeatUsage('increment');
      }
    }
  }

  @action toggleCopilotAccess(
    value: typeof NO_ACCESS | typeof LIMITED_USAGE | typeof UNLIMITED_USAGE,
  ) {
    mapSelectionToSeatAndPermission(
      value,
      this.toggleSeat,
      this.teammate.currentAppPermissions,
      this.teammate.copilotAccessType,
    );
  }

  @action async onCopilotAccessChange(
    openPaywall: () => void,
    value: typeof NO_ACCESS | typeof LIMITED_USAGE | typeof UNLIMITED_USAGE,
  ) {
    if (value === this.teammate.copilotAccessType) {
      return;
    }

    if (value === UNLIMITED_USAGE && !this.teammate.hasUnlimitedCopilotAccess) {
      if (this.appService.app.hasUnlimitedCopilotAccessAddOn) {
        this.confirmAddCopilotSeat();
      } else {
        openPaywall();
      }
    } else {
      this.toggleCopilotAccess(value);
      await this.saveChanges();
    }
  }

  @action async saveChanges() {
    if (
      this.appService.app.hasMultipleSeatTypes ||
      this.appService.app.hasCopilotSeatType ||
      this.appService.app.hasUnlimitedCopilotAccessAddOn
    ) {
      await this.permissionsMutatorService.savePermissionAndSeats(
        this.teammate,
        this.teammate.currentAppPermissions,
        this.teammate.seats,
      );
    } else {
      await this.permissionsMutatorService.savePermission(
        this.teammate,
        this.teammate.currentAppPermissions,
        this.teammate.currentAppPermissions.has_inbox_access,
      );
    }

    this.teammate.currentAppPermissions.rollbackAttributes();
    this.store.findRecord('admin', this.teammate.id, {
      reload: true,
      backgroundReload: false,
    });
  }

  @action async confirmAddCopilotSeat() {
    if (this.hasContractedSeatsLeft) {
      this.onConfirmAddCopilotSeat();
      return;
    }

    let confirmOptions = {
      title: this.intl.t('settings.copilot.modal-title'),
      primaryButtonType: 'primary',
      confirmButtonText: this.intl.t('settings.copilot.confirm'),
      cancelButtonText: this.intl.t('settings.copilot.cancel'),
      body: this.intl.t('settings.copilot.modal-body', { price: this.copilotSeatPrice }),
    };
    let confirmed = await this.intercomConfirmService.confirm(confirmOptions);

    if (confirmed) {
      this.onConfirmAddCopilotSeat();
    }
  }

  @action onConfirmAddCopilotSeat() {
    this.toggleCopilotAccess(UNLIMITED_USAGE);

    this.saveChanges();
  }

  @action onPaywallClose() {
    if (this.appService.app.hasUnlimitedCopilotAccessAddOn) {
      this.confirmAddCopilotSeat();
    }
  }

  rolePreventsAccessLevel(accessLevel: string) {
    let role = this.teammate.currentAppPermissions?.role;

    // Directly return false if role is null or undefined
    if (role === null || role === undefined) {
      return false;
    }

    // Return true if the role does not have copilot access or if trying to set access level to NO_ACCESS
    return !role[COPILOT_PERMISSION] || accessLevel === NO_ACCESS;
  }

  tooltipTextForToggleDisabled(ownPermission: boolean, hasRole: boolean) {
    if (ownPermission) {
      return this.intl.t('table-cells.teammate-list.copilot-access-cell.cannot-disable-tooltip');
    }

    let role = this.teammate.currentAppPermissions?.role;

    // Directly return false if role is null or undefined
    if (role === null || role === undefined) {
      return undefined;
    }

    if (hasRole && !role[COPILOT_PERMISSION]) {
      return this.intl.t(
        'table-cells.teammate-list.copilot-access-cell.cannot-set-access-because-role',
      );
    }
    if (hasRole && role[COPILOT_PERMISSION]) {
      return this.intl.t(
        'table-cells.teammate-list.copilot-access-cell.cannot-remove-access-because-role',
      );
    }
    return undefined;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'TableCells::TeammateList::CopilotAccessCell': typeof CopilotAccessCell;
    'table-cells/teammate-list/copilot-access-cell': typeof CopilotAccessCell;
  }
}
