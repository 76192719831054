/* import __COLOCATED_TEMPLATE__ from './resend-cell.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { notifySendDenialError } from 'embercom/lib/invite-error-strings';
const SENDING_DENIED_ERROR_CODE = 'App not approved to send email';

export default class ResendCell extends Component {
  @service notificationsService;
  @service intl;

  @action
  async resendInvite() {
    let inviteEmail = this.args.invite.email;
    try {
      await this.args.invite.resend();
      this.notificationsService.notifyConfirmation(
        this.intl.t('settings.teammates.invite.invite-list.invite-resent', {
          email: inviteEmail,
        }),
      );
    } catch (err) {
      if (err.jqXHR.responseJSON.errors === SENDING_DENIED_ERROR_CODE) {
        notifySendDenialError(this.notificationsService);
      } else {
        this.notificationsService.notifyError(
          this.intl.t('settings.teammates.invite.invite-list.resent-error', {
            email: inviteEmail,
          }),
        );
      }
    }
  }
}
