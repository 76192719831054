/* import __COLOCATED_TEMPLATE__ from './email-domain-verification.hbs'; */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type SalesforceConfiguration from 'embercom/models/standalone/salesforce-configuration';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';

interface Args {}

export default class StandaloneSetupSalesforceEmailDomainVerification extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare notificationsService: any;

  get salesforceConfig(): SalesforceConfiguration {
    return this.finStandaloneService.salesforceConfig;
  }

  get salesforceSetupData() {
    return this.finStandaloneService.salesforceSetupData;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Setup::Salesforce::EmailDomainVerification': typeof StandaloneSetupSalesforceEmailDomainVerification;
    'standalone/setup/salesforce/email-domain-verification': typeof StandaloneSetupSalesforceEmailDomainVerification;
  }
}
