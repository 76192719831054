/* import __COLOCATED_TEMPLATE__ from './completion-title.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { stats } from 'embercom/models/data/stats-system/stats-constants';

export default class CompletionTitle extends Component {
  @service contentEditorService;

  get survey() {
    return this.args.statsParameters.contentObject;
  }

  get filterItems() {
    let items = [{ text: 'All questions', value: 0 }];
    let questions = this.survey.steps.toArray().flatMap((step) => step.orderedQuestions.toArray());
    if (questions.length > 0) {
      items.push(
        ...questions.map((question, index) => ({
          text: `Q${index + 1}: ${question.questionTitle}`,
          value: Number(question.sanitizedId),
        })),
      );
    }
    return items;
  }

  get selectedFilterValue() {
    return parseInt(this.args.filter, 10) || 0;
  }

  @action
  changeFilter(filter) {
    this.args.changeView(stats.completion, filter);
  }
}
