/* import __COLOCATED_TEMPLATE__ from './navbar-adapter.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
interface Args {}

const VALID_ROUTES = ['apps.app.settings', 'apps.app.billing', 'apps.app.standalone'];

export default class NavbarAdapter extends Component<Args> {
  @service declare router: RouterService;

  get isValidRoute() {
    return VALID_ROUTES.some((route) => this.router.currentRouteName?.startsWith(route));
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Adapters::NavbarAdapter': typeof NavbarAdapter;
  }
}
