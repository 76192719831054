/* import __COLOCATED_TEMPLATE__ from './checkout-component.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable @intercom/intercom/no-component-inheritance */
/* eslint-disable promise/prefer-await-to-then */
import { computed } from '@ember/object';
import { alias, notEmpty, or, readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import PALETTE from '@intercom/pulse/lib/palette';
import { task } from 'ember-concurrency';
import FormComponent from 'embercom/components/signup/new/form-component';
import ajax from 'embercom/lib/ajax';
import { ARTICLES_ESSENTIAL_ID, ARTICLES_PRO_ID, plansFromSolution } from 'embercom/lib/billing';
import { MARKETO_FORM_IDS } from 'embercom/lib/gtm';
import { captureException } from 'embercom/lib/sentry';
import storage from 'embercom/vendor/intercom/storage';

export default FormComponent.extend({
  appService: service(),
  store: service(),
  purchaseAnalyticsService: service(),
  notificationsService: service(),
  customerService: service(),
  gtmService: service(),
  router: service(),
  paymentIntentService: service(),
  intercomEventService: service(),
  earlyStageService: service(),
  intl: service(),
  cardlessTrialService: service(),

  app: readOnly('appService.app'),
  teamsExperience: true,
  premium: false,
  stripeElement: alias('elements.firstObject'),
  planIds: null,
  seats: null,
  hasActivePlans: notEmpty('solutionId'),
  isInCardlessTrial: readOnly('cardlessTrialService.isInCardlessTrial'),
  hasActiveTrialSubscriptionOrIsInCardlessTrial: readOnly(
    'cardlessTrialService.hasActiveTrialSubscriptionOrIsInCardlessTrial',
  ),
  stripeInputStyle: computed('mobileDevice', function () {
    return {
      base: {
        border: '1px solid grey', // eslint-disable-line @intercom/intercom/no-bare-strings
        fontSize: this.mobileDevice ? '16px' : '14px',
        fontSmoothing: 'antialiased',
        color: '#32325d',
        '::placeholder': {
          color: PALETTE.gray,
        },
      },
    };
  }),

  formSubmitTaskRunning: or(
    'paymentIntentService.addPaymentMethod.isRunning',
    'sendNewSubscriptionTask.isRunning',
  ),

  analyticsTotal: computed('solutionPlusAddOnPrice', 'billingPeriodDurationInMonths', function () {
    return this.solutionPlusAddOnPrice.getMonthlyTotalAfterTrial(
      this.billingPeriodDurationInMonths,
    );
  }),

  newSubscriptionFormData() {
    if (!this.planIds) {
      this.set(
        'planIds',
        plansFromSolution(this.solutionId, this.app).map((x) => parseInt(x, 10)),
      );
    }

    let planIdsToUse = this.planIds.toArray();

    if (this.addOnPlanIds && this.addOnPlanIds.length > 0) {
      planIdsToUse = planIdsToUse.concat(this.addOnPlanIds.map((id) => parseInt(id, 10)));
    }

    if (
      planIdsToUse.includes(parseInt(ARTICLES_PRO_ID, 10)) &&
      planIdsToUse.includes(parseInt(ARTICLES_ESSENTIAL_ID, 10))
    ) {
      planIdsToUse.removeObject(parseInt(ARTICLES_ESSENTIAL_ID, 10));
    }

    let stateCode = this.addressConfiguration.showState ? this.billingAddress.stateCode : undefined;
    let formData = {
      plan_ids: planIdsToUse,
      stripe_payment_method_id: this.stripePaymentMethodId,
      app_id: this.app.id,
      country_code: this.billingAddress.countryCode,
      coupon_code: this.couponCode,
      premium: this.premium,
      state_code: stateCode,
      street_address: this.billingAddress.streetAddress,
      city: this.billingAddress.city,
      postcode: this.billingAddress.postcode,
      new_trial: true,
      seats: this.seats,
    };

    if (this.shouldOfferAnnualBillingPeriods) {
      formData.billing_period_duration_in_months = this.billingPeriodDurationInMonths;
    }

    return formData;
  },

  reportProductChangesForAnalytics(plan_ids) {
    plan_ids.forEach((plan_id) => {
      let plan = this.store.peekRecord('plan', plan_id);
      if (plan && plan.product) {
        let product = plan.product;
        this.purchaseAnalyticsService.trackEvent({
          action: 'product added', // eslint-disable-line @intercom/intercom/no-bare-strings
          product_name: product.name,
          tier: plan.name,
          is_trial: true,
          object: `${product.name}`,
          context: 'usecase_signup_flow',
          place: 'start_trial',
          section: 'signup',
        });
      } else {
        let planString = plan ? 'Plan' : 'Product for plan'; // eslint-disable-line @intercom/intercom/no-bare-strings
        let e = `[Analytics] ${planString} ${plan_id} not found in local store.`; // eslint-disable-line @intercom/intercom/no-bare-strings
        console.error(e);
        captureException(e);
      }
    });
  },

  sendNewSubscriptionTask: task(function* (formData) {
    formData.purchase_experience = this.purchaseExperience;
    formData.solution_id = this.solutionId;
    formData.partner = this.earlyStageService.partnerSource;
    formData.analytics = {
      addOn: this.addOn,
    };

    let url = `/ember/customers/${this.app.id}/start_subscription`;
    if (this.hasActiveTrialSubscriptionOrIsInCardlessTrial) {
      url = `/ember/customers/${this.app.id}/convert_subscription`;
    }
    let params = {
      url,
      type: 'post',
      contentType: 'application/json',
      data: JSON.stringify(formData),
    };

    yield ajax(params)
      .then(() => {
        this.reportProductChangesForAnalytics(formData['plan_ids']);

        this.trackSignupEvent();

        this.purchaseAnalyticsService.trackEvent({
          action: 'completed',
          object: 'start_trial',
          context: 'usecase_signup_flow',
          place: 'confirm_page',
          solutionId: this.solutionId,
          app_id_code: this.app.id,
          addOn: this.addOn,
          planIds: this.addOnPlanIds,
          cartAmount: this.analyticsTotal,
          emailSubmissionId: this.emailSubmissionId,
          mobile: this.mobileDevice,
        });

        this.app.reload().then(() => {
          this.customerService.resetAll();
          storage.set('shouldShowConfetti', true);
          this.onCheckout();
        });
        this.gtmService.fireMarketoEvent(MARKETO_FORM_IDS.trialSignupEnd, {
          App_ID__c: this.app.id,
          Email: this.app.currentAdmin.email,
          name: this.app.name,
          Company: this.app.name,
          selectedSolutionIntercom: this.solutionId,
        });
        window.sessionStorage && window.sessionStorage.removeItem('intercom-coupon-code');
      })
      .catch((err) => {
        console.error(err);
        let errorMessage =
          (err && err.jqXHR && err.jqXHR.responseJSON && err.jqXHR.responseJSON[0]) ||
          this.intl.t('signup.teams.start.form.new_subscription_task_failed');
        this.set('subscriptionError', errorMessage);
      });
  }).drop(),

  trackSignupEvent() {
    try {
      this.intercomEventService.trackEvent('self-serve-checkout-completed');
    } catch (e) {
      console.error(e);
    }
  },

  actions: {
    createSubscription() {
      let data = this.newSubscriptionFormData();
      this.sendNewSubscriptionTask.perform(data);
    },
    submitStripeElement(stripeElement) {
      this._resetInvalidLocationDataErrors();
      this.resetErrors();
      if (this.hasInvalidLocationData) {
        this._showInvalidLocationDataErrors();
        return;
      }
      this.purchaseAnalyticsService.trackEvent({
        action: 'clicked',
        object: 'start_trial',
        context: 'signup',
        place: 'start_trial',
        cartAmount: this.analyticsTotal,
        planIds: this.addOnPlanIds,
        solutionId: this.solutionId,
        mobile: this.mobileDevice,
        gclid: this.gclid,
        emailSubmissionId: this.emailSubmissionId,
        billing_period_duration_in_months: this.billingPeriodDurationInMonths,
      });

      let success;

      this.paymentIntentService.addPaymentMethod
        .perform(stripeElement, this.billingAddress.countryCode)
        .then((stripePaymentMethodId) => {
          success = true;
          this.stripePaymentMethodId = stripePaymentMethodId;
          this.send('createSubscription');
        })
        .catch((error) => {
          success = false;
          let errorString = error?.message
            ? error.message
            : this.intl.t('signup.teams.start.form.payment_error');
          return this.set('cardError', errorString);
        })
        .finally(() => {
          this.intercomEventService.trackAnalyticsEvent({
            action: 'create',
            object: 'payment_method',
            context: 'signup',
            place: 'start_trial',
            success,
          });
          if (
            success &&
            (this.app.buyNowEssentialFlowEnabled || this.app.buyNowAdvancedFlowEnabled)
          ) {
            let buy_now_value = this.app.buyNowEssentialFlowEnabled ? 24 : 25;
            this.intercomEventService.trackEvent('buy_now_flow_checkout_finished', {
              action: 'create',
              object: 'payment_method',
              context: 'signup',
              place: 'start_trial',
              buy_now_value,
            });
          }
        });
    },
  },
});
