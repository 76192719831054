/* import __COLOCATED_TEMPLATE__ from './predicates-editor.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { copy } from 'ember-copy';

export default class Modal extends Component {
  @service appService;
  @service attributeService;

  @tracked attributeGroupList = [];

  constructor() {
    super(...arguments);
    let userAttributes = this.attributeService.statsSystemGoalAttributes;
    let visibilitySettings = this.attributeService.attributeSettings;

    let priorityUserAttributes = userAttributes.filter(
      (a) => a.requiredFilter || visibilitySettings.findBy('attribute', a.identifier),
    );

    let sortedUserAttributes = priorityUserAttributes.concat(userAttributes).uniqBy('identifier');

    let eventAttributes = sortedUserAttributes.filter(function (attribute) {
      return attribute.type === 'user_event';
    });
    let nonEventAttributes = sortedUserAttributes.filter(function (attribute) {
      return attribute.type !== 'user_event';
    });

    let companyAttributes = this.attributeService.statsSystemGoalCompanyAttributes;

    let groups = [
      {
        heading: 'Customer',
        attributes: nonEventAttributes,
      },
      {
        heading: 'Company',
        attributes: companyAttributes,
      },
      {
        heading: 'Activity',
        attributes: eventAttributes,
      },
    ];

    if (this.appService.app.canUseEventMetadataGoals) {
      let activityMetadataAttributes = eventAttributes
        .map(function (attribute) {
          let metadataList = attribute.metadata.toArray();
          return metadataList.map(function (metadata) {
            metadata.name = `${attribute.humanFriendlyName} → ${metadata.humanFriendlyName}`;
            return metadata;
          });
        })
        .reduce((acc, metadata) => acc.concat(metadata), []);

      groups.push({
        heading: 'Activity Metadata',
        attributes: activityMetadataAttributes,
      });
    }

    this.attributeGroupList = groups;
  }

  @action proxiedOnUpdate(predicates) {
    this.args.predicateGroup.predicates = copy(predicates);

    let onUpdate = this.args.onUpdate;
    if (typeof onUpdate === 'function') {
      onUpdate(...arguments);
    }
  }
}
