/* import __COLOCATED_TEMPLATE__ from './ai-agent-customization-settings-change-details.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'ember-intl/services/intl';

interface AiAgentCustomizationSettingsChangeDetailsArgs {
  event: {
    value: {
      ai_tone_of_voice: {
        before: string;
        after: string;
      };
      ai_language_style: {
        before: string;
        after: string;
      };
      ai_answer_length: {
        before: string;
        after: string;
      };
    };
  };
}

export const TONE_OF_VOICE_SETTING_ID = 'ai_tone_of_voice';
export const ANSWER_LENGTH_SETTING_ID = 'ai_answer_length';
export const LANGUAGE_STYLE_SETTING_ID = 'ai_language_style';

const settingIdToTranslationKeyMap: { [key: string]: string } = {
  [TONE_OF_VOICE_SETTING_ID]: 'ai-agent.settings.tone-of-voice.tones.title',
  [ANSWER_LENGTH_SETTING_ID]: 'ai-agent.settings.answer-length.name',
  [LANGUAGE_STYLE_SETTING_ID]: 'ai-agent.settings.tone-of-voice.language-styles.title',
};

const valueTranslationKeyMap: { [key: string]: string } = {
  [ANSWER_LENGTH_SETTING_ID]: 'ai-agent.settings.answer-length.values',
  [TONE_OF_VOICE_SETTING_ID]: 'ai-agent.settings.tone-of-voice.tones',
  [LANGUAGE_STYLE_SETTING_ID]: 'ai-agent.settings.tone-of-voice.language-styles',
};

type SettingId =
  | typeof ANSWER_LENGTH_SETTING_ID
  | typeof TONE_OF_VOICE_SETTING_ID
  | typeof LANGUAGE_STYLE_SETTING_ID;

export default class AiAgentCustomizationSettingsChangeDetails extends Component<AiAgentCustomizationSettingsChangeDetailsArgs> {
  @service declare intl: IntlService;

  @tracked showModal = false;

  get settingIds(): SettingId[] {
    return [TONE_OF_VOICE_SETTING_ID, ANSWER_LENGTH_SETTING_ID, LANGUAGE_STYLE_SETTING_ID];
  }

  get settingsWithBeforeAndAfter() {
    return this.settingIds.map((settingId) => {
      let { before, after } = this.args.event.value[settingId] as { before: string; after: string };
      let beforeValue = this.finPersonalityValueToLocalisedString(before, settingId);
      let afterValue = this.finPersonalityValueToLocalisedString(after, settingId);

      return {
        name: this.finPersonalitySettingIdToLocalisedString(settingId),
        before: beforeValue,
        after: afterValue,
        hasChanged: beforeValue !== afterValue,
      };
    });
  }

  finPersonalitySettingIdToLocalisedString(settingId: SettingId): string {
    return this.intl.t(settingIdToTranslationKeyMap[settingId]);
  }

  finPersonalityValueToLocalisedString(value: string, settingId: SettingId): string {
    let formattedValue = value.replace(/_/g, '-');
    let translationKey = valueTranslationKeyMap[settingId];
    return this.intl.t(`${translationKey}.${formattedValue}.name`);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'TableCells::AdminEvents::AiAgentCustomizationSettingsChangeDetails': typeof AiAgentCustomizationSettingsChangeDetails;
    'table-cells/admin-events/ai-agent-customization-settings-change-details': typeof AiAgentCustomizationSettingsChangeDetails;
  }
}
