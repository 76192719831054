/* import __COLOCATED_TEMPLATE__ from './early-stage-application-form-header.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { AppPricing5PriceModels } from 'embercom/lib/billing';

interface Args {
  discount: number;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Args;
}

export default class EarlyStageApplicationFormHeader extends Component<Signature> {
  @service declare earlyStageService: any;
  @service declare appService: any;

  get proactiveSupportPlusHCLink() {
    if (this.appService.app.pricing5PricingModel === AppPricing5PriceModels.PRICING_5_1) {
      return 'https://www.intercom.com/help/en/articles/9061648-proactive-support-plus';
    } else {
      return 'https://www.intercom.com/help/en/articles/8205747-proactive-support-plus';
    }
  }

  get isOnPricing5(): boolean {
    return this.appService.app.onPricing5;
  }

  get seatCount(): number {
    return 6;
  }

  get messageSentCount(): number {
    return 500;
  }

  get pricingDetailsUrl(): string {
    return this.earlyStageService.isPartnerProgram
      ? 'https://www.intercom.com/help/en/articles/9361933-startup-partner-program-from-june-27-2024'
      : 'https://www.intercom.com/help/en/articles/9361928-early-stage-program-from-june-27-2024';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Signup::Teams::EarlyStageApplicationFormHeader': typeof EarlyStageApplicationFormHeader;
    'signup/teams/early-stage-application-form-header': typeof EarlyStageApplicationFormHeader;
  }
}
