/* import __COLOCATED_TEMPLATE__ from './editor.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { BaseConfig, BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import createFragmentFromBlock from 'embercom/lib/blocks/create-fragment-from-block';
import AttributeInfoResolver from 'embercom/lib/common/template-attribute-resolver';
import { EMOJI_TYPEAHEAD } from '@intercom/embercom-prosemirror-composer';
import EmbercomFileUploader from 'embercom/lib/articles/embercom-file-uploader';
import { surveyFormats } from 'embercom/models/data/survey/constants';
import { INLINE_CONTROL_BLOCK_TYPES } from '@intercom/embercom-prosemirror-composer/lib/config/composer-config';

const DEFAULT_NODES_BLOCKS = ['bold', 'italic', 'anchor'];

class ComposerConfig extends BaseConfig {
  placeholder = '';
  autoFocus = false;
  textDirection = 'ltr';
  allowedBlocks = ['paragraph'];
  singleBlockMode = true;
  allowedInline = DEFAULT_NODES_BLOCKS;
  allowTextAlignment = true;
  allowImplicitMarginParagraphs = true;
  tools = [
    { name: 'template-inserter' },
    { name: 'fallback-editor' },
    { name: 'text-formatter' },
    { name: 'anchor-editor' },
  ];
  experimental = {
    hideInsertersOnMouseOut: true,
  };
  typeaheads = [EMOJI_TYPEAHEAD];

  constructor(
    app,
    { placeholder, singleBlockMode, allowedInline, withMedia, hideFromTextFormatter },
  ) {
    super(app);

    this.attributes = app.allowedAttributes;
    this.placeholder = placeholder;
    this.singleBlockMode = singleBlockMode;
    this.allowedInline = allowedInline;
    this.hideFromTextFormatter = hideFromTextFormatter;

    if (withMedia) {
      this.inserters = { emojiInserter: 'composer/inserters/emoji-inserter' };
      this.allowedBlocks = [...this.allowedBlocks, 'image'];
      this.upload = {
        allowedImageFileTypes: ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'],
        allowedAttachmentTypes: [],
        uploader: EmbercomFileUploader,
        attrs: { policyUrl: `/apps/${app.id}/uploads` },
      };
      this.tools = [
        ...this.tools,
        { name: 'media-inserter' },
        { name: 'image-editor', options: { supportAltAttributeEditing: true } },
      ];
    }

    this.templating = {
      picker: 'common/attribute-picker',
      resolver: new AttributeInfoResolver({
        attributes: this.attributes,
        includeAppAttributes: false,
      }),
    };
  }
}

export default class StepEditorComponent extends Component {
  @tracked blocksDoc;
  @service appService;
  @service store;

  constructor() {
    super(...arguments);
    this.blocksDoc = new BlocksDocument(this.step.blocks.serialize());
  }

  get step() {
    return this.args.step;
  }

  get composerConfig() {
    return new ComposerConfig(this.appService.app, {
      placeholder: this.args.placeholder || "What's your question?",
      allowedInline: this.args.allowedBlocks || DEFAULT_NODES_BLOCKS,
      singleBlockMode: this.args.singleBlockMode !== undefined ? this.args.singleBlockMode : true,
      hideFromTextFormatter: this.isBannerFormat ? [INLINE_CONTROL_BLOCK_TYPES] : [],
      withMedia: this.args.withMedia,
    });
  }

  get conditionalWrapperMarginClassName() {
    let hasNoContent =
      !this.step.serializedBlocks.length ||
      (this.step.serializedBlocks.length === 1 && this.step.serializedBlocks[0].text?.length === 0);

    let isEmptyEditorBlockOnPreview = Boolean(
      !this.args.isEditing && this.args.isOrderedStep && hasNoContent,
    );

    if (isEmptyEditorBlockOnPreview) {
      return 'm-0';
    } else if (this.args.isOrderedStep && this.args.isEditing) {
      return 'my-1 p-4';
    } else if (this.step.isThankYouType && this.args.singleBlockMode && this.args.isEditing) {
      return 'p-4 mr-2';
    } else if (this.step.isThankYouType && this.args.singleBlockMode) {
      return 'm-0';
    } else if (this.args.deviceType === 'web') {
      return 'p-4';
    }
  }

  get borderColorClassName() {
    return this.isBannerFormat && this.args.isSurveyBackgroundDark ? 'border-white' : 'border-blue';
  }

  get activeClassName() {
    return this.args.isActive ? 'border-opacity-1' : 'border-opacity-0';
  }

  get stepActionButtonClasses() {
    if (!(this.args.stepAction && this.args.stepAction.actionTitle)) {
      return 'mr-3';
    }
  }

  get isBannerFormat() {
    return this.args.surveyFormat === surveyFormats.banner;
  }

  @action
  updateBlocks(blocksDoc) {
    let blockFragments = blocksDoc.blocks.map((block) =>
      createFragmentFromBlock(this.store, block),
    );
    this.step.blocks.clear(); // reliably forces update
    this.step.blocks = blockFragments;
  }

  @action
  updateBlocksDocument() {
    this.blocksDoc = new BlocksDocument(this.step.blocks.serialize());
  }
}
