/* import __COLOCATED_TEMPLATE__ from './simple-composer-audience-component.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-observers */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
import { scheduleOnce } from '@ember/runloop';
import $ from 'jquery';
import { observer, computed } from '@ember/object';
import EmberArray from '@ember/array';
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { subtractProperties } from '@intercom/pulse/lib/computed-properties';
import MeasureHtml from 'embercom/lib/measure-html';
import { addEventListener } from 'ember-lifeline';

const USER_HTML_TEMPLATE =
  '<div class="ember-view u__left"><div class="avatar__container o__s content-editor__sample-user"><span class="ember-view avatar o__contained o__s"><span class="avatar__media">LC</span></span><a class="ember-view" href="#">%@</a>, </div></div>';

export default Component.extend({
  intl: service(),

  tagName: '',

  users: EmberArray,
  selectedCount: 0,

  // space for the `and 100 others` text
  andNOthersWidth: 65,

  // space for the right tooltip
  rightPadding: 20,

  availableWidth: subtractProperties('width', 'rightPadding'),
  availableWidthWithoutAndNOthers: subtractProperties('availableWidth', 'andNOthersWidth'),

  didInsertElement() {
    this._super();
    addEventListener(this, window, 'resize', () => this.scheduleUpdateAudienceWidth());
    let $container = $('.js__audience-users', this.element);
    this.set('measureHtml', new MeasureHtml($container[0], USER_HTML_TEMPLATE, false));
    this.scheduleUpdateAudienceWidth();
  },

  scheduleUpdateAudienceWidth: observer({
    dependentKeys: ['users.length'],

    fn() {
      scheduleOnce('afterRender', this, this.updateAudienceWidth);
    },

    sync: true,
  }),

  updateAudienceWidth() {
    let $container = $('.js__audience-users', this.element);
    this.set('width', $container.width());

    let measureHtml = this.measureHtml;
    this.users.forEach((user) => {
      let uiWidth = measureHtml.measure([`\n${user.get('displayAs')}\n`]);
      user.set('uiWidth', uiWidth);
    });

    measureHtml.clear();
    this.notifyPropertyChange('displayableUserCount');
  },

  displayableUserCount: computed('users.length', function () {
    if (!this.width) {
      return 0;
    }
    return this._getDisplayableUserCount(this.users, this.availableWidthWithoutAndNOthers);
  }),

  audienceHasErrors: computed(
    'isEmailMessage',
    'users.@each.{unsubscribed_from_emails,canNotBeEmailed}',
    function () {
      if (!this.isEmailMessage) {
        return false;
      }
      if (this.users.length > 1 && this.users.any((user) => user.unsubscribed_from_emails)) {
        return true;
      }
      return this.users.any((user) => user.canNotBeEmailed);
    },
  ),

  displayedUsers: computed('users.length', 'displayableUserCount', function () {
    return this.users.slice(0, this.displayableUserCount);
  }),

  remainingUsers: computed('users.length', 'displayableUserCount', function () {
    return this.users.slice(this.displayableUserCount, this.get('users.length'));
  }),

  remainingUserCount: subtractProperties('selectedCount', 'displayableUserCount'),

  usersToDisplayInTooltip: computed('remainingUsers', function () {
    let userLimitInTooltip = 15;
    return this.remainingUsers.slice(0, userLimitInTooltip);
  }),

  additionalUsersForTooltip: computed('remainingUsers', 'usersToDisplayInTooltip', function () {
    return this.remainingUsers.filter((user) => !this.usersToDisplayInTooltip.includes(user));
  }),

  additionalUsersTextForTooltip: computed(
    'intl.locale',
    'remainingUsers',
    'usersToDisplayInTooltip',
    function () {
      let additionalUserCount = this.additionalUsersForTooltip.length;
      if (additionalUserCount > 0) {
        return this.intl.t('components.simple-composer-audience-component.and-others', {
          additionalUserCount,
        });
      }
    },
  ),

  additionalUsersForTooltipHaveErrors: computed(
    'isEmailMessage',
    'additionalUsersForTooltip.@each.{unsubscribed_from_emails,canNotBeEmailed}',
    function () {
      if (!this.isEmailMessage) {
        return false;
      }
      return this.additionalUsersForTooltip.any(
        (user) => user.unsubscribed_from_emails || user.canNotBeEmailed,
      );
    },
  ),

  _getDisplayableUserCount(users, availableWidth) {
    let displayableCount = 0;
    let cumulativeWidth = 0;
    users.forEach((user) => {
      cumulativeWidth += user.get('uiWidth');
      if (cumulativeWidth <= availableWidth) {
        displayableCount += 1;
      }
    });

    return displayableCount;
  },
});
