/* import __COLOCATED_TEMPLATE__ from './topic-details-list.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import ConversationAttributeDescriptor from 'embercom/models/conversation-attributes/descriptor';
import { task } from 'ember-concurrency-decorators';
import { capitalize } from '@ember/string';

const OPERATOR_MAP = {
  eq: 'is',
  nin: 'is not',
  known: 'has any value',
  unknown: 'is unknown',
};

export default class TopicDetailsList extends Component {
  @service appService;
  @tracked conversationDataFiltersDescriptions = [];

  get authorTypes() {
    return this.args.value.author_types.map((type) => capitalize(type));
  }

  @task
  *loadConversationDataFilterDescriptions() {
    let filters = [];
    let conversation_custom_data = Array.from(this.args.value.conversation_custom_data || []);
    let descriptors = yield ConversationAttributeDescriptor.peekAllAndMaybeLoad();

    for (let filter of conversation_custom_data) {
      let descriptor = descriptors.find((descriptor) => descriptor.id === filter.field_id);
      filters.push(this.getFilterDescription(filter, descriptor));
    }
    this.conversationDataFiltersDescriptions = filters;
  }

  getFilterDescription(filter, descriptor) {
    let operator = OPERATOR_MAP[filter.operator] || filter.operator;
    let values = filter.values || [];
    let convertedValues = values.map((value) => descriptor.getDisplayableValue(value));
    return `${descriptor.name} ${operator} ${convertedValues.join(', ')}`;
  }
}
