/* import __COLOCATED_TEMPLATE__ from './checkout-details.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import templateOnlyComponent from '@ember/component/template-only';

const CheckoutDetails = templateOnlyComponent<Signature>();

interface Signature {
  Args: {
    isEarlyStageFree: boolean;
  };
}

export default CheckoutDetails;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Signup::Teams::Pricing5::EarlyStage::CheckoutDetails': typeof CheckoutDetails;
    'signup/teams/pricing5/early-stage/checkout-details': typeof CheckoutDetails;
  }
}
