/* import __COLOCATED_TEMPLATE__ from './chrome.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
export default class Chrome extends Component {
  @service intl;
  @service appService;

  get app() {
    return this.appService.app;
  }

  get deviceTypeIsMobile() {
    return this.args.deviceType === 'android' || this.args.deviceType === 'ios';
  }

  get toggleMobilePreviewLabel() {
    let label = this.intl.t('outbound.surveys.mobile-preview');
    if (this.args.isMobileReadOnlyMode) {
      label = this.intl.t('outbound.surveys.exit-mobile-view');
    }

    return label;
  }

  get toggleMobilePreviewIcon() {
    let icon = 'visible';
    if (this.args.isMobileReadOnlyMode) {
      icon = 'close';
    }

    return icon;
  }

  get showManageBranchingLogicButton() {
    return this.args.isEditing && !this.args.isMobileReadOnlyMode;
  }

  get branchingAndMobilePreviewPresent() {
    return this.args.isEditing && this.args.survey.preferredDevicesHasMobile;
  }
}
