/* import __COLOCATED_TEMPLATE__ from './addon-cta-component.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class AddonCta extends Component {
  @service media;
  @service appService;
  @service purchaseAnalyticsService;
  @tracked showAddonModal;

  get app() {
    return this.appService.app;
  }

  get addOnPrice() {
    let addOnId = parseInt(this.args.addOn.id, 10);
    return this.args.prices.find((price) => price.hasSamePlans([addOnId]));
  }

  get monthlyAddOnPrice() {
    return this.addOnPrice.getMonthlyTotalAfterTrial(this.args.billingPeriodDurationInMonths);
  }

  get monthlyAddOnPricePerSeat() {
    return this.addOnPrice.getMonthlyPerSeatPriceAfterTrial(
      this.args.billingPeriodDurationInMonths,
    );
  }

  @action
  openAddonModal() {
    this.showAddonModal = true;

    let addonName = this.args.addOn.key;
    this.purchaseAnalyticsService.trackEvent({
      action: `${addonName}_modal_opened`,
      section: 'add_ons_cta',
      addonId: this.args.addOn.id,
      object: 'add_on',
      context: 'add_ons_page',
      place: 'add_ons_page',
      mobile: this.media.isMobile,
    });
  }

  @action
  closeAddonModal() {
    this.showAddonModal = false;
  }
}
