/* import __COLOCATED_TEMPLATE__ from './table.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class Table extends Component {
  @service appService;

  get isSelectAllMode() {
    return this.selectedUsers.length === 0;
  }

  get selectedRows() {
    return this.args.data.filterBy('isSelected', true);
  }

  get selectedUsers() {
    return this.selectedRows.mapBy('userModel');
  }

  get isCompanyGoalStat() {
    return (
      this.args.statistic === 'goal_success' && this.args.statsParameters.goal?.hasCompanyPredicate
    );
  }

  get insertableContent() {
    return this.appService.app.insertableContent;
  }
}
