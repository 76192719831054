/* import __COLOCATED_TEMPLATE__ from './format-and-options-cell.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import Component from '@ember/component';
import { joinBy } from '@intercom/pulse/lib/computed-properties';
import dataFormatMap from 'embercom/models/data/attributes/data-format-map';
import { inject as service } from '@ember/service';

export default Component.extend({
  tagName: 'td',
  intl: service(),
  classNames: ['table__cell settings__attributes-events__cell'],
  classNameBindings: ['attribute.archived:o__archived'],
  attribute: readOnly('row.attribute'),
  commaSeparatedOptions: joinBy('attribute.options', 'value', ', '),
  formatName: computed('intl.locale', 'attribute.type', 'attribute.hasOptions', function () {
    if (this.get('attribute.hasOptions')) {
      return this.intl.t('settings.data-format-map.list');
    }
    return this.intl.t(
      dataFormatMap[this.get('attribute.type')] || 'settings.data-format-map.unknown',
    );
  }),
});
