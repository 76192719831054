/* import __COLOCATED_TEMPLATE__ from './ruleset-activation-details.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import AttributeInfoResolver, {
  APP_NAME_ATTRIBUTE,
} from 'embercom/lib/common/template-attribute-resolver';

export default class RulesetActivationDetails extends Component {
  @service contentEditorService;
  @service attributeService;
  @tracked showModal = false;

  get resolver() {
    return new AttributeInfoResolver({
      attributes: this.attributeService.userEditorAndMetadataAttributes,
      manualAppAttributes: [APP_NAME_ATTRIBUTE],
      selectedEvents: this.selectedEvents,
    });
  }

  get selectedEvents() {
    let selectedEvent = this.contentEditorService.selectedEvent;
    return selectedEvent ? [selectedEvent] : [];
  }

  get subjectBlocks() {
    return [
      {
        text: this.args.event.value.content_preview,
        type: 'paragraph',
      },
    ];
  }
}
