/* import __COLOCATED_TEMPLATE__ from './upgrade-enable-banner.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { preferredDevices as devices } from 'embercom/models/data/outbound/constants';
import compareVersions from 'embercom/lib/compare-versions';
import { isEmpty, isPresent } from '@ember/utils';

export default class UpgradeEnableBanner extends Component {
  @service appService;
  get app() {
    return this.appService.app;
  }

  get isMobileSelected() {
    let mobile = [devices.android, devices.ios];
    return mobile.some((m) => this.args.preferredDevices.includes(m));
  }

  get hasNoMobileSdk() {
    if (!this.showUpgradeBanner) {
      return false;
    }
    let androidSdkVersion = this.app.android_sdk_version;
    let iosSdkVersion = this.app.ios_sdk_version;
    return isEmpty(androidSdkVersion) && isEmpty(iosSdkVersion);
  }

  get hasOldMobileSdk() {
    if (!this.showUpgradeBanner) {
      return false;
    }
    return !this.meetsAndroidSdkRequirements() && !this.meetsIosSdkRequirements();
  }

  meetsAndroidSdkRequirements() {
    let androidSdkVersion = this.app.android_sdk_version;
    return (
      isPresent(androidSdkVersion) &&
      compareVersions(androidSdkVersion, this.args.minAndroidVersion) !== -1
    );
  }

  meetsIosSdkRequirements() {
    let iosSdkVersion = this.app.ios_sdk_version;
    return (
      isPresent(iosSdkVersion) && compareVersions(iosSdkVersion, this.args.minIosVersion) !== -1
    );
  }

  get showUpgradeBanner() {
    return this.isMobileSelected;
  }
}
