/* import __COLOCATED_TEMPLATE__ from './whatsapp-failure-list-row.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { whatsappErrorMessages } from 'embercom/models/data/stats-system/stats-constants';

export default class WhatsappFailureListRow extends Component {
  get errorMessage() {
    return whatsappErrorMessages[this.args.row.data.messageStatus] || whatsappErrorMessages.default;
  }
}
