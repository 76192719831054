/* import __COLOCATED_TEMPLATE__ from './path-action.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { availableStepToBranchTo, nextStepForPath } from 'embercom/lib/survey-branching-helper';

export default class PathAction extends Component {
  @service appService;

  constructor() {
    super(...arguments);
  }

  get currentStep() {
    return this.args.step;
  }

  get survey() {
    return this.args.survey;
  }

  get path() {
    return this.args.path;
  }

  get selectedAction() {
    let nextStep = nextStepForPath(this.path, this.survey.steps);
    return nextStep.order;
  }

  get actionsList() {
    return [
      {
        items: availableStepToBranchTo(this.currentStep, this.survey.steps),
      },
    ];
  }

  @action onSelectItem(selectedStepOrder) {
    let { id, uuid } = this.survey.steps.find((step) => step.order === selectedStepOrder);

    this.path.nextStepId = id;
    this.path.nextStepUuid = uuid;
  }
}
