/* import __COLOCATED_TEMPLATE__ from './answer-title.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { stats } from 'embercom/models/data/stats-system/stats-constants';
import { inject as service } from '@ember/service';

export default class AnswerTitle extends Component {
  @service appService;
  @service intl;

  get app() {
    return this.appService.app;
  }

  get survey() {
    return this.args.statsParameters.contentObject;
  }

  get filterItems() {
    let items = [
      {
        text: this.intl.t('stats-system.list.header.bulk-actions-buttons.responded-to-any'),
        value: 0,
        component: 'stats-system/list/truncated-filter-item',
        tooltipText: this.intl.t('stats-system.list.header.bulk-actions-buttons.responded-to-any'),
      },
    ];
    let questions = this.survey.steps.toArray().flatMap((step) => step.orderedQuestions.toArray());
    if (questions.length > 0) {
      items.push(
        ...questions.map((question, index) => ({
          text: `Q${index + 1}: ${question.questionTitle}`,
          value: Number(question.sanitizedId),
          component: 'stats-system/list/truncated-filter-item',
          tooltipText: `Q${index + 1}: ${question.questionTitle}`,
        })),
      );
    }
    return items;
  }

  get selectedFilterValue() {
    return Number(this.args.filter) || 0;
  }

  @action
  changeFilter(filter) {
    this.args.changeView(stats.answer, filter);
  }
}
