/* import __COLOCATED_TEMPLATE__ from './click-list.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';

export default class ClickList extends Component {
  get additionalFilters() {
    if (this.selectedFilterValue === 0) {
      return null;
    }
    return { 'content_link.id': this.selectedFilterValue };
  }

  get selectedFilterValue() {
    return parseInt(this.args.filter, 10) || 0;
  }
}
