/* import __COLOCATED_TEMPLATE__ from './plan-builder-details.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { type Feature } from 'embercom/components/signup/teams/pricing5/plan-builder';
import { type PlanMetricDisplay } from 'embercom/components/signup/teams/pricing5/plan-builder';
import { action } from '@ember/object';

interface Args {
  features: Feature[];
  planMetrics: PlanMetricDisplay[];
}

export default class PlanBuilderDetails extends Component<Args> {
  @service declare appService: any;

  @action
  showMeteredMessagesArticle() {
    window.Intercom('showArticle', this.appService.app.meteredMessagesArticleId);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Signup::Teams::Pricing5::PlanBuilderDetails': typeof PlanBuilderDetails;
  }
}
