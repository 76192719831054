/* import __COLOCATED_TEMPLATE__ from './failure-list.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { failureListCategories } from 'embercom/models/data/stats-system/stats-constants';

export default class FailureList extends Component {
  get columns() {
    if (this.args.statsParameters.uiObjectName === 'mobile push') {
      return [
        { label: 'Name', valuePath: 'user.displayAs', type: 'avatar-with-text' },
        { label: 'Issue type', valuePath: 'failureCategory' },
        { label: 'Platform', valuePath: 'device' },
        { label: 'Error message', valuePath: 'errorMessage' },
        { label: 'When they had an issue', valuePath: 'createdAt' },
      ];
    } else if (this.args.statsParameters.uiObjectName === 'SMS message') {
      return [
        { label: 'Name', valuePath: 'user.displayAs', type: 'avatar-with-text' },
        { label: 'Issue type', valuePath: 'failureCategory' },
        { label: 'Issue reason', valuePath: 'errorMessage' },
        { label: 'When they had an issue', valuePath: 'createdAt' },
      ];
    } else if (this.args.statsParameters.uiObjectName === 'WhatsApp message') {
      return [
        { label: 'Name', valuePath: 'user.displayAs', type: 'avatar-with-text' },
        { label: 'Issue reason', valuePath: 'errorMessage' },
        { label: 'When they had an issue', valuePath: 'createdAt' },
      ];
    } else {
      return [
        { label: 'Name', valuePath: 'user.displayAs', type: 'avatar-with-text' },
        { label: 'Issue type', valuePath: 'failureCategory' },
        { label: 'When they had an issue', valuePath: 'createdAt' },
      ];
    }
  }

  get selectedFilterValue() {
    let availableFilters = [
      failureListCategories.hardBounce,
      failureListCategories.unsubscribe,
      failureListCategories.spamComplaint,
      failureListCategories.smsFailure,
      failureListCategories.pushFailure,
      failureListCategories.whatsappFailure,
    ];
    return availableFilters.includes(this.args.filter) ? this.args.filter : '';
  }

  get additionalFilters() {
    if (!this.selectedFilterValue) {
      return null;
    }
    return { 'failure_category.id': this.selectedFilterValue };
  }

  get rowComponent() {
    if (this.args.statsParameters.uiObjectName === 'SMS message') {
      return 'stats-system/list/row/sms-failure-list-row';
    } else if (this.args.statsParameters.uiObjectName === 'WhatsApp message') {
      return 'stats-system/list/row/whatsapp-failure-list-row';
    }
    return 'stats-system/list/row/failure-list-row';
  }
}
