/* import __COLOCATED_TEMPLATE__ from './edit-button-cell.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { readOnly } from '@ember/object/computed';
import Component from '@ember/component';
export default Component.extend({
  tagName: 'td',
  classNames: ['table__cell settings__attributes-events__cell'],
  classNameBindings: ['archived:o__archived'],

  attribute: readOnly('row.attribute'),
  archived: readOnly('attribute.archived'),

  actions: {
    openDetailsView() {
      this.get('tableActions.openDetailsView')(this.row);
    },
  },
});
