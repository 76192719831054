/* import __COLOCATED_TEMPLATE__ from './modal.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import Range from 'embercom/models/reporting/range';
import moment from 'moment-timezone';
import { action } from '@ember/object';
import { task } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import { humanReadableObjectNames } from 'embercom/models/data/matching-system/matching-constants';

export default class Modal extends Component {
  @service appService;
  @service notificationsService;
  @service intercomEventService;
  @tracked range;

  constructor() {
    super(...arguments);
    let timezone = moment.tz.guess();
    let range = Range.createFromPreset('past_4_weeks', timezone);
    this.range = range;
  }

  get humanReadableObjectName() {
    return humanReadableObjectNames[this.args.entityType];
  }

  @task *exportCsv() {
    try {
      yield this.args.entity.exportData({
        app_id: this.appService.app.id,
        start_date: this.range.start,
        end_date: this.range.end,
      });
      this.intercomEventService.trackAnalyticsEvent({
        start_date: this.range.start,
        end_date: this.range.end,
        action: 'data_exported',
        object: 'csv_export',
        entity_id: this.args.entity.id,
        entity_type: this.args.entityType,
      });
      this.notificationsService.notifyConfirmation(
        'Export successful! We’re sending a CSV to your default email address. This can take a few hours at peak times.',
      );
      this.args.hideCsvExportModal();
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: 'We were unable to start your data export.',
      });
    }
  }

  @action updateDateRange(range) {
    this.range = range;
    this.intercomEventService.trackAnalyticsEvent({
      start_date: this.range.start,
      end_date: this.range.end,
      action: 'date_range_updated',
      object: 'csv_export',
      entity_id: this.args.entity.id,
      entity_type: this.args.entityType,
    });
  }
}
