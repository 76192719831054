/* import __COLOCATED_TEMPLATE__ from './editor.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import createFragmentFromBlock from 'embercom/lib/blocks/create-fragment-from-block';
import { BaseConfig, BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import AttributeInfoResolver from 'embercom/lib/common/template-attribute-resolver';
import { useCaseTypes, SENDER_ID_OPTIONS } from 'embercom/models/data/sms/constants';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';

class ComposerConfig extends BaseConfig {
  placeholder = 'Your message...';
  autoFocus = false;
  textDirection = 'ltr';
  allowedBlocks = ['paragraph'];
  allowedInline = [];
  allowTextAlignment = false;
  allowImplicitMarginParagraphs = true;
  tools = [{ name: 'template-inserter' }, { name: 'fallback-editor' }];
  experimental = {
    hideInsertersOnMouseOut: true,
  };

  constructor({ resolver }) {
    super();

    this.templating = {
      picker: 'common/attribute-picker',
      resolver,
    };
  }
}

export default class SmsEditorComponent extends Component {
  @service attributeService;
  @service appService;
  @service contentEditorService;
  @service store;

  @tracked blocksDoc;
  @tracked smsContent;

  @tracked selectedSendingOption = this.args.sms.useAlphanumSenderId
    ? SENDER_ID_OPTIONS.ALPHANUMERIC_SENDER_ID
    : SENDER_ID_OPTIONS.LONGCODE;

  useCaseTypes = useCaseTypes;

  constructor() {
    super(...arguments);

    this.blocksDoc = new BlocksDocument(this.serializedBlocks);
    this.smsContent = this.localizedSmsMessageContent;
    this.args.sms.unsubscribeInstructionsText = this.args.sms.optOutInstruction;
  }

  get senderIdOptions() {
    return [
      { text: '2-Way Phone Number', value: SENDER_ID_OPTIONS.LONGCODE },
      { text: 'Alphanumeric ID', value: SENDER_ID_OPTIONS.ALPHANUMERIC_SENDER_ID },
    ];
  }

  get serializedBlocks() {
    return this.localizedSmsMessageContent.bodyBlocks.serialize();
  }

  get serializedBlocksForViewMode() {
    return this.localizedSmsMessageContent.bodyBlocks.serialize().map((block) => {
      if (block.type === 'paragraph' && block.text === '') {
        block.text = '<br>';
      }
      return block;
    });
  }

  get selectedEvents() {
    let selectedEvent = this.contentEditorService.selectedEvent;
    return selectedEvent ? [selectedEvent] : [];
  }

  get resolver() {
    return new AttributeInfoResolver({
      attributes: this.attributeService.userEditorAndMetadataAttributes,
      includeAppAttributes: false,
      selectedEvents: this.selectedEvents,
    });
  }

  get composerConfig() {
    return new ComposerConfig({
      resolver: this.resolver,
    });
  }

  get localizedSmsMessageContent() {
    return this.args.sms.localizedSmsMessageContents.firstObject;
  }

  get objectType() {
    return objectTypes.sms;
  }

  get displayCompanyInfoWarningBanner() {
    return !this.args.sms.showCompanyInfo && !this.args.sms.useAlphanumSenderId;
  }

  @action
  updateBlocks(blocksDoc) {
    let blockFragments = blocksDoc.blocks.map((block) =>
      createFragmentFromBlock(this.store, block),
    );
    this.localizedSmsMessageContent.bodyBlocks = blockFragments;
    this.smsContent = this.localizedSmsMessageContent;
    this.blocksDoc = blocksDoc;
  }

  @action
  updateUseCase(selectedValue) {
    this.args.sms.useCaseType = selectedValue;

    if (selectedValue === useCaseTypes.operational) {
      this.args.sms.showUnsubscribeInstructions = false;
    } else if (selectedValue === useCaseTypes.marketing) {
      this.args.sms.showUnsubscribeInstructions = true;
    }
  }

  @action
  updateSendFrom(sendFrom) {
    this.selectedSendingOption = sendFrom;
    // disable company prefix when sending via alphanum sender id
    if (sendFrom === SENDER_ID_OPTIONS.ALPHANUMERIC_SENDER_ID) {
      this.args.sms.useAlphanumSenderId = true;
      this.args.sms.showCompanyInfo = false;
    } else {
      this.args.sms.useAlphanumSenderId = false;
      this.args.sms.showCompanyInfo = true;
    }
    this.args.sms.unsubscribeInstructionsText = this.args.sms.optOutInstruction;
    this.updateBlocks(this.blocksDoc);
  }
}
