/* import __COLOCATED_TEMPLATE__ from './seats-cell.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { availableUiSeatTypes } from 'embercom/lib/settings/seats/constants';
import { intersection } from 'underscore';
import { inject as service } from '@ember/service';

export default class TeammateListSeatsCellComponent extends Component {
  @service appService;

  get seatsOrderedByDefaultArrangement() {
    let seatTypes = availableUiSeatTypes(this.appService.app.onPricing5).filter(
      (seat) => !this.args.hideCopilotSeat || seat !== 'copilot',
    );

    return intersection(seatTypes, this.args.seats);
  }
}
