/* import __COLOCATED_TEMPLATE__ from './answer-and-hand-off.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
/* eslint-disable @intercom/intercom/no-bare-strings */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { type PulseAccordion } from 'glint/pulse';
import type SalesforceConfiguration from 'embercom/models/standalone/salesforce-configuration';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

interface Args {
  accordion: PulseAccordion;
}

export default class StandaloneSalesforceAnswerAndHandOff extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare notificationsService: $TSFixMe;

  @tracked newHandoverId?: string;

  @action openSectionChanged() {
    this.newHandoverId = undefined;
  }

  get salesforceConfig(): SalesforceConfiguration {
    return this.finStandaloneService.salesforceConfig;
  }

  get salesforceSetupData() {
    return this.finStandaloneService.salesforceSetupData;
  }

  get isLoading() {
    return this.finStandaloneService.isLoadingSalesforceSetupData && !this.handoverOptions.length;
  }

  get handoverOptions() {
    let admins = this.salesforceSetupData.admins?.map((admin) => ({
      text: admin.name,
      value: admin.id,
    }));
    let queues = this.salesforceSetupData.queues?.map((queue) => ({
      text: queue.name,
      value: queue.id,
    }));

    let options = [];

    if (queues) {
      options.push({
        heading: 'Queues',
        items: queues,
      });
    }

    if (admins) {
      options.push({
        heading: 'Admins',
        items: admins,
      });
    }

    return options;
  }

  get selectedAgentName(): string | undefined {
    if (this.isLoading) {
      return undefined;
    }

    return this.handoverOptions
      .flatMap((group) => group.items)
      .find((option) => option.value === this.selectedHandoverId)?.text;
  }

  get selectedHandoverId(): string | undefined {
    if (this.salesforceConfig.finSalesforceHandoverId) {
      return this.salesforceConfig.finSalesforceHandoverId;
    }
    return undefined;
  }

  @action setHandoverId(handoverId: string) {
    this.newHandoverId = handoverId;
  }

  @action async updateIdentity() {
    try {
      if (this.newHandoverId) {
        await this.salesforceConfig.setHandoverId(this.newHandoverId);
      }

      this.notificationsService.notifyConfirmation('Successfully updated hand-off settings');
    } catch (e) {
      this.notificationsService.notifyResponseError(e, {
        default: `Something went wrong and we couldn't update your hand-off settings. Please try again`,
      });
      console.error(e);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Salesforce::Setup::AnswerAndHandOff': typeof StandaloneSalesforceAnswerAndHandOff;
    'standalone/salesforce/setup/answer-and-hand-off': typeof StandaloneSalesforceAnswerAndHandOff;
  }
}
