/* import __COLOCATED_TEMPLATE__ from './billing-addressees-bounced-details.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'ember-intl/services/intl';

interface BillingAddresseesBouncedDetailsArgs {
  event: {
    value: {
      removed_email_addresses: string[];
    };
  };
}

export default class BillingAddresseesBouncedDetails extends Component<BillingAddresseesBouncedDetailsArgs> {
  @service declare intl: IntlService;
  @tracked showModal = false;

  get removedEmails(): string[] {
    return this.args.event.value.removed_email_addresses;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'TableCells::AdminEvents::BillingAddresseesBouncedDetails': typeof BillingAddresseesBouncedDetails;
    'table-cells/admin-events/billing-addressees-bounced-details': typeof BillingAddresseesBouncedDetails;
  }
}
