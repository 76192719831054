/* import __COLOCATED_TEMPLATE__ from './article-sync-modal.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { taskFor } from 'ember-concurrency-ts';
import type Store from '@ember-data/store';
import type ArticleSyncSetting from 'embercom/models/articles/article-sync-setting';
import ajax from 'embercom/lib/ajax';
import { task } from 'ember-concurrency-decorators';
import { AsyncData } from 'embercom/resources/utils/async-data';
import { use } from 'ember-resources/util/function-resource';

interface Args {
  closeModal: () => void;
}

export default class StandaloneZendeskArticleSyncModal extends Component<Args> {
  @service declare store: Store;
  @service declare appService: $TSFixMe;

  @use syncSettings = AsyncData<Array<ArticleSyncSetting>>(async () => {
    return this.store.findAll('articles/article-sync-setting');
  });

  @use activeSyncJob = AsyncData(async () => {
    return ajax({
      url: '/ember/article_import_jobs/current_import',
      type: 'GET',
      data: { app_id: this.app.id },
    });
  });

  get app() {
    return this.appService.app;
  }

  get activeSyncSetting() {
    return this.syncSettings.value?.find(
      (setting: ArticleSyncSetting) =>
        setting.provider === 'zendesk' && setting.status === 'active',
    );
  }

  get hasExistingZendeskSync() {
    return !!this.activeSyncSetting;
  }

  @action removeExistingSync() {
    if (this.activeSyncSetting) {
      taskFor(this.pauseActiveSync).perform();
    }
  }
  get hasActiveSyncJob() {
    return this.activeSyncJob.value;
  }

  get isRemovingSync() {
    return taskFor(this.pauseActiveSync).isRunning;
  }

  @action closeModal() {
    this.args.closeModal();
  }

  @task({ drop: true })
  *pauseActiveSync() {
    yield this.activeSyncSetting?.pause();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Zendesk::ArticleSyncModal': typeof StandaloneZendeskArticleSyncModal;
  }
}
