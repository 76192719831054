/* import __COLOCATED_TEMPLATE__ from './email-engagement-warning.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { statisticKeys } from 'embercom/models/data/outbound/constants';
import emailLowEngagementData from 'embercom/lib/outbound/email-low-engagement-data';

export default class StatsSystemListEmailEngagementWarning extends Component {
  @service store;
  @service appService;
  @service outboundHomeService;
  @service contentEditorService;

  statisticKeys = [statisticKeys.emailFailures];
  subaggregations;
  redirectTo = {
    spamRate: 'https://www.intercom.com/help/en/articles/2272-how-to-prevent-spam-complaints',
    unsubscribeRate:
      'https://www.intercom.com/help/en/articles/216-record-customer-activity-with-event-tracking-filter-and-message-your-customers-based-on-actions-they-take',
    bounceRate:
      'https://www.intercom.com/help/en/articles/1516-protect-your-workspace-from-high-bounce-rates',
    openRate:
      'https://www.intercom.com/help/en/articles/2366-improve-your-open-rate-and-email-deliverability',
    deliverability:
      'https://www.intercom.com/help/en/articles/2366-improve-your-open-rate-and-email-deliverability',
  };
  translationKeys = {
    spamRate: 'marked-as-spam',
    unsubscribeRate: 'unsubscribed',
    openRate: 'low-open-rate',
    bounceRate: 'hard-bounce',
  };

  get app() {
    return this.appService.app;
  }

  get activeRulesetLink() {
    return this.contentEditorService.activeRulesetLink;
  }

  get openedEmailsStat() {
    return this.activeRulesetLink.objectStats.findBy('key', statisticKeys.opens);
  }

  get sentEmailsStat() {
    return this.activeRulesetLink.objectStats.findBy('key', statisticKeys.receipts);
  }

  get emailHasNoSubscription() {
    return !(
      this.contentEditorService.activeObject?.subscriptionTypeId &&
      this.store.peekRecord(
        'outbound-subscriptions/subscription-type',
        this.contentEditorService.activeObject.subscriptionTypeId,
      )?.state === 1
    );
  }

  get showAuthenticateDomainAdvice() {
    return (
      this.hasIntercomEmailAddress ||
      this.hasNoVerifiedAdminEmailDomains ||
      this.hasNoCustomEmailAddresses
    );
  }

  get hasIntercomEmailAddress() {
    return !this.app.use_admin_app_email;
  }

  get hasNoVerifiedAdminEmailDomains() {
    return this.verifiedAdminEmailDomains.length === 0;
  }

  get hasNoCustomEmailAddresses() {
    return this.app.customEmailAddresses.length === 0;
  }

  get verifiedAdminEmailDomains() {
    let verifiedCustomBounceDomains = this.app.customBounceSettings.filter(
      (settings) => settings.validRecordExists,
    );
    return this.app.adminEmailDKIMSettings.filter(
      (dkimSetting) =>
        dkimSetting.validRecordExists &&
        verifiedCustomBounceDomains.find(
          (customDomain) => customDomain.rootDomain === dkimSetting.domain,
        ),
    );
  }

  get failureStats() {
    if (!this.activeRulesetLink) {
      // activeRulesetLink will be nil if the content object is associated with a selection set rather than a ruleset.
      return [];
    }

    let emailFailures = this.activeRulesetLink.objectStats?.findBy(
      'key',
      statisticKeys.emailFailures,
    );
    let failureCalculations = emailLowEngagementData(
      this.activeRulesetLink.wentLiveAt,
      this.sentEmailsStat.value,
      this.openedEmailsStat.value,
      emailFailures?.subaggregations,
    );
    return Object.keys(failureCalculations).map((key) => ({
      translationKey: this.translationKeys[key],
      impact: (failureCalculations[key].actual * 100).toFixed(1),
      redirectTo: this.redirectTo[key],
    }));
  }
}
