/* import __COLOCATED_TEMPLATE__ from './each-seat-price-component.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import Component from '@glimmer/component';

export default class EachSeatPrice extends Component {
  get price() {
    return this.args.solutionPlusAddOnPrice;
  }

  get combinedPerSeatMonthlyListPrice() {
    return this.price.annualPerSeatCombinedPlanPrices()?.monthly_price_in_cents;
  }

  get combinedPerSeatMonthlyDiscountedPrice() {
    return this.price.annualPerSeatCombinedPlanPrices()
      ?.monthly_equivalent_when_billed_by_billing_period;
  }

  get combinedPerSeatAnnualDiscountedPrice() {
    return this.price.annualPerSeatCombinedPlanPrices()?.billing_period_price_after_discount;
  }

  get annualPerSeatPricesPerPlan() {
    return this.price.annualPerSeatPricesPerPlan();
  }

  get showSeatPriceBreakdown() {
    return this.annualPerSeatPricesPerPlan.length > 1;
  }
}
