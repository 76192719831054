/* import __COLOCATED_TEMPLATE__ from './zendesk.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import type Form from 'embercom/models/side-by-side/handover/zendesk/form';
import type Field from 'embercom/models/side-by-side/handover/zendesk/field';
import type Session from 'embercom/services/session';
import type ZendeskConfiguration from 'embercom/models/side-by-side/handover/zendesk/configuration';
import type IntlService from 'embercom/services/intl';
import type Store from '@ember-data/store';

export interface Args {
  isAuthenticated: boolean;
  isConfigured: boolean;
  configuration: ZendeskConfiguration;
  initializeZendeskConfig: () => void;
  editRouting: () => void;
  turnOnRouting: () => void;
  location: 'wizard' | 'onboarding-home';
}

export default class TicketForm extends Component<Args> {
  @service declare session: Session;
  @service declare intercomEventService: any;
  @service declare notificationsService: any;
  @service declare intl: IntlService;
  @service openCenteredWindowService: any;
  @service declare store: Store;

  @tracked ticketForms: Form[] = [];
  @tracked subdomain = '';
  @tracked selectedTicketForm: Form | null = null;
  @tracked ticketFormValues: any;
  @tracked zendeskVerificationCompletedHandler;
  @tracked showOHErrorMessage = false;

  constructor(owner: unknown, args: any) {
    super(owner, args);

    this.zendeskVerificationCompletedHandler = this.zendeskVerificationCompleted.bind(this);
    window.addEventListener('message', this.zendeskVerificationCompletedHandler);

    taskFor(this.loadForms).perform();
  }

  get isLocationOnboardingHome(): boolean {
    return this.args.location === 'onboarding-home';
  }

  willDestroy() {
    super.willDestroy();

    if (this.zendeskVerificationCompletedHandler) {
      window.removeEventListener('message', this.zendeskVerificationCompletedHandler);
    }
  }

  zendeskVerificationCompleted(event: any) {
    let expectedEventType = 'zendesk-verification-completed';
    let expectedOrigin = window.location.origin;
    let eventData;

    try {
      eventData = JSON.parse(event.data);
    } catch {
      eventData = null;
    }

    if (!eventData || eventData.type !== expectedEventType || event.origin !== expectedOrigin) {
      return;
    }

    if (eventData.success === true) {
      this.showOHErrorMessage = false;
      taskFor(this.loadForms).perform();
      this.intercomEventService.trackAnalyticsEvent({
        action: 'verified',
        object: 'verify_zendesk',
      });
      this.args.initializeZendeskConfig();
      this.args.configuration.set('subdomain', this.subdomain);
    } else if (this.isLocationOnboardingHome) {
      this.showOHErrorMessage = true;
    } else {
      this.notificationsService.notifyError(
        this.intl.t('side-by-side.zendesk.verify-zendesk-failed'),
      );
    }
  }

  @task({ restartable: true })
  *loadForms() {
    this.ticketForms = yield this.store.findAll('side-by-side/handover/zendesk/form');

    this.ticketFormValues = this.ticketForms.map((form: Form) => {
      if (form.default) {
        this.selectTicketForm(form.id);
      }
      return { text: form.displayName, value: form.id };
    });
  }

  get fieldsString() {
    if (this.args.configuration?.zendeskForm?.ticketFields) {
      let fieldNames = [];
      fieldNames = this.args.configuration.zendeskForm.ticketFields
        .filter((field: Field) => field.checked)
        .map((field: Field) => {
          return field.titleInPortal;
        });
      return fieldNames;
    }

    return '';
  }

  async zendeskOAuthURL() {
    let queryParams = encodeURI(
      `app_id=${this.session.workspace.id}&zendesk_subdomain=${this.subdomain}`,
    );
    return `/side_by_side/zendesk/oauth/start?${queryParams}`;
  }

  @action
  async startOauth() {
    let ZdUrl = await this.zendeskOAuthURL();
    // eslint-disable-next-line @intercom/intercom/no-bare-strings
    this.openCenteredWindowService.setup(ZdUrl, 800, 1280, 'Connect');
  }

  @action
  selectTicketForm(value: string) {
    this.selectedTicketForm = this.ticketForms.find((form: Form) => form.id === value) as Form;
  }

  @action
  cancel() {
    this.args.editRouting();
  }

  @action
  turnOnRouting() {
    if (this.args.configuration !== null && this.selectedTicketForm !== null) {
      this.args.configuration.zendeskForm = this.selectedTicketForm;
      this.args.configuration.zendeskForm.set(
        'ticketFields',
        this.args.configuration.zendeskForm.ticketFields.filter((field: Field) => field.checked),
      );
    }
    this.args.turnOnRouting();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SideBySide::Zendesk': typeof TicketForm;
    'side-by-side/zendesk': typeof TicketForm;
  }
}
