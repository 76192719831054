/* import __COLOCATED_TEMPLATE__ from './thank-you-step-settings.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { actionTypes } from 'embercom/models/data/survey/constants';
import { inject as service } from '@ember/service';
import { preferredDevices as devices } from 'embercom/models/data/outbound/constants';
import { htmlToTextContent } from 'embercom/lib/html-unescape';
import createFragmentFromBlock from 'embercom/lib/blocks/create-fragment-from-block';
import { BaseConfig, BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import AttributeInfoResolver, {
  APP_NAME_ATTRIBUTE,
} from 'embercom/lib/common/template-attribute-resolver';
import { urlWithHttpPrefix } from 'embercom/lib/url-validator';

class ComposerConfig extends BaseConfig {
  autoFocus = false;
  textDirection = 'ltr';
  allowedBlocks = ['paragraph'];
  allowedInline = [];
  allowTextAlignment = false;
  singleBlockMode = true;
  tools = [{ name: 'template-inserter' }, { name: 'fallback-editor' }];
  placeholder = '';
  experimental = {
    hideInsertersOnMouseOut: true,
  };

  constructor({ resolver, placeholder }) {
    super();
    this.placeholder = placeholder;
    this.templating = { picker: 'common/attribute-picker', resolver };
  }
}

export default class ThankYouStepSettings extends Component {
  @service intl;
  @service store;
  @service appService;

  @tracked actionWebUrlBlocks;
  @service attributeService;

  actionOptions = [
    { text: this.intl.t('outbound.surveys.action-settings.action-types.no-action'), value: null },
    {
      text: this.intl.t('outbound.surveys.action-settings.action-types.add-link'),
      value: actionTypes.openUrlCurrentTab,
    },
  ];

  constructor() {
    super(...arguments);
    this.actionWebUrlBlocks = new BlocksDocument(this.serializedActionWebUrlBlocks);
  }

  get app() {
    return this.appService.app;
  }

  get showAddLinkSettings() {
    return this.selectedAction === actionTypes.openUrlCurrentTab;
  }

  get currentStep() {
    return this.args.currentStep;
  }

  get currentAction() {
    if (this.currentStep.actions.length) {
      return this.currentStep.actions.firstObject;
    }
  }

  get selectedAction() {
    let storedAction = this.currentAction?.actionType;
    let actionTypeForSelectField =
      storedAction === actionTypes.openUrlNewTab ? actionTypes.openUrlCurrentTab : storedAction;

    return actionTypeForSelectField;
  }

  get isLinkInNewTab() {
    return this.currentAction?.actionType === actionTypes.openUrlNewTab;
  }

  get urlValidation() {
    let validationMessage = {
      value: null,
      isInvalid: false,
    };

    if (!this.currentAction) {
      return validationMessage;
    }

    let webUrl = this.currentAction.webUrl;
    if (!webUrl || !webUrl.length) {
      validationMessage = {
        value: this.intl.t('outbound.surveys.action-settings.validations.empty-web-url'),
        isInvalid: true,
      };

      return validationMessage;
    }

    validationMessage = {
      value: this.currentAction.webUrlHasValidFormat
        ? null
        : this.intl.t('outbound.surveys.action-settings.validations.invalid-web-url'),
      isInvalid: !this.currentAction.webUrlHasValidFormat,
    };

    return validationMessage;
  }

  get preferredDevices() {
    return this.args.survey.preferredDevices;
  }

  get webInPreferredDevices() {
    return this.preferredDevices.includes(devices.web);
  }

  get showIosDeepLink() {
    return this.preferredDevices.includes(devices.ios);
  }

  get showWebInlineError() {
    if (this.args.shouldShowValidations && this.urlValidation.isInvalid) {
      return this.webInPreferredDevices || this.currentAction.webUrl;
    }
    return false;
  }

  get showIosInlineError() {
    if (this.args.shouldShowValidations && !this.webInPreferredDevices) {
      return !(this.currentAction.webUrl || this.currentAction.iosUri);
    }
    return false;
  }

  get showAndroidInlineError() {
    if (this.args.shouldShowValidations && !this.webInPreferredDevices) {
      return !(this.currentAction.webUrl || this.currentAction.androidUri);
    }
    return false;
  }

  get showAndroidDeepLink() {
    return this.preferredDevices.includes(devices.android);
  }

  get serializedActionWebUrlBlocks() {
    return [
      {
        type: 'paragraph',
        text: this.currentAction?.webUrl || '',
      },
    ];
  }

  get urlComposerConfig() {
    return new ComposerConfig({
      resolver: this.resolver,
      placeholder: this.intl.t('outbound.surveys.action-settings.web-placeholder'),
    });
  }

  get resolver() {
    return new AttributeInfoResolver({
      attributes: this.attributeService.surveyActionUrlComposerAttributes,
      manualAppAttributes: [APP_NAME_ATTRIBUTE],
    });
  }

  @action updateWebUrlAction(blocksDoc) {
    let blockFragments = blocksDoc.blocks.map((block) =>
      createFragmentFromBlock(this.store, block),
    );
    let blockText = blockFragments.firstObject.text;
    let textContent = htmlToTextContent(blockText);
    blockText = textContent.trim();

    this.currentAction.webUrl = urlWithHttpPrefix(blockText);
    this.actionWebUrlBlocks = blocksDoc;
  }

  @action onSelectedAction(actionType) {
    if (actionType !== null) {
      this.addNewAction(actionType);
      return;
    }

    this.deleteAllActions();
  }

  @action addNewAction(actionType) {
    let createdAction = this.store.createRecord('surveys/action', {
      actionType,
      actionTitle: this.intl.t('outbound.surveys.action-settings.default-cta-text'),
    });

    this.currentStep.actions.pushObject(createdAction);
  }

  @action deleteAllActions() {
    if (this.currentStep.actions.length) {
      this.currentStep.actions.forEach((action) => action.deleteRecord());
      this.currentStep.actions = [];
    }
  }

  @action toggleLinkInNewTab() {
    let newActionType = this.isLinkInNewTab
      ? actionTypes.openUrlCurrentTab
      : actionTypes.openUrlNewTab;

    this.currentAction.actionType = newActionType;
  }

  @action updateWebUrlComposer() {
    this.actionWebUrlBlocks = new BlocksDocument(this.serializedActionWebUrlBlocks);
  }
}
