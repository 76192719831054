/* import __COLOCATED_TEMPLATE__ from './seat-selector-component.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class SeatSelector extends Component {
  @action
  incrementSeats() {
    if (this.args.seats < this.args.maxSeatsAllowed) {
      this.args.incrementSeats();
    }
  }

  @action
  decrementSeats() {
    if (this.args.seats > this.args.minSeatsAllowed) {
      this.args.decrementSeats();
    }
  }
}
