/* import __COLOCATED_TEMPLATE__ from './tickets-testing.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
/* eslint-disable @intercom/intercom/no-bare-strings */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { type PulseAccordion } from 'glint/pulse';
import type ZendeskConfiguration from 'embercom/models/standalone/zendesk-configuration';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';

interface Args {
  accordion: PulseAccordion;
}

export default class StandaloneZendeskTicketsTesting extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;

  get zendeskConfig(): ZendeskConfiguration {
    return this.finStandaloneService.zendeskConfig;
  }

  get ticketsSetupData() {
    return this.finStandaloneService.zendeskTicketsSetupData;
  }

  get isLoading() {
    return this.finStandaloneService.isLoadingZendeskTicketsSetupData;
  }

  get emailAddress() {
    return this.finStandaloneService.zendeskTicketsSetupData?.email_addresses?.[0] || '';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Zendesk::Setup::TicketsTesting': typeof StandaloneZendeskTicketsTesting;
    'standalone/zendesk/setup/tickets-testing': typeof StandaloneZendeskTicketsTesting;
  }
}
