/* import __COLOCATED_TEMPLATE__ from './sunshine-api-connection.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
/* eslint-disable @intercom/intercom/no-bare-strings */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type ZendeskConfiguration from 'embercom/models/standalone/zendesk-configuration';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import { action } from '@ember/object';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';
import { type PulseAccordion } from 'glint/pulse';

interface Args {
  accordion: PulseAccordion;
}

export default class StandaloneZendeskSunshineApiConnection extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare intercomConfirmService: IntercomConfirmService;
  @service declare notificationsService: any;

  @action async revokeAccess() {
    let isConfirmed = await this.intercomConfirmService.confirm({
      body: 'Are you sure you want to revoke access to the Zendesk Conversations API? Fin will immediately stop responding to Messenger conversations.',
      confirmButtonText: 'Revoke Access',
    });

    try {
      if (isConfirmed) {
        await this.zendeskConfig.revokeSunshine();
        this.notificationsService.notifyConfirmation(
          'Access to the Conversations API has been revoked',
        );
      }
    } catch (e) {
      this.notificationsService.notifyResponseError(e, {
        default: `Something went wrong and we couldn't revoke access to the Conversations API. Please try again`,
      });
    }
  }

  get zendeskConfig(): ZendeskConfiguration {
    return this.finStandaloneService.zendeskConfig;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Zendesk::Setup::SunshineApiConnection': typeof StandaloneZendeskSunshineApiConnection;
    'standalone/zendesk/setup/sunshine-api-connection': typeof StandaloneZendeskSunshineApiConnection;
  }
}
