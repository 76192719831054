/* import __COLOCATED_TEMPLATE__ from './bulk-export-modal.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import Range from 'embercom/models/reporting/range';
import moment from 'moment-timezone';
import { action } from '@ember/object';
import { task } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import ajax from 'embercom/lib/ajax';

export default class BulkExportModal extends Component {
  @service appService;
  @service notificationsService;
  @service intercomEventService;
  @service permissionsService;
  @tracked range;
  @tracked exportType = 'all-stats';
  @service intl;

  constructor() {
    super(...arguments);
    let timezone = moment.tz.guess();
    let range = Range.createFromPreset('past_4_weeks', timezone);
    this.range = range;
  }

  get exportUrl() {
    if (this.exportType === 'all-stats') {
      return 'ember/reporting/customer_engagement_report/csv_export';
    } else {
      return 'ember/reporting/customer_engagement_report/overview_export';
    }
  }

  get analyticsObject() {
    if (this.exportType === 'all-stats') {
      return 'ce_report_csv_export';
    } else {
      return 'ce_report_overview_csv_export';
    }
  }

  get maxSelectableRangeInDays() {
    return 90;
  }

  get maxSelectableRangeInDaysErrorMessage() {
    return this.intl.t(
      'components.stats-system.csv-export.bulk-export-modal.date-range-filter.max-90-days-error',
      { count: this.maxSelectableRangeInDays },
    );
  }

  @task *exportCsv() {
    try {
      yield ajax({
        url: this.exportUrl,
        type: 'POST',
        data: JSON.stringify({
          app_id: this.appService.app.id,
          start_date: this.range.start,
          end_date: this.range.end,
        }),
      });
      this.intercomEventService.trackAnalyticsEvent({
        start_date: this.range.start,
        end_date: this.range.end,
        action: 'data_exported',
        object: this.analyticsObject,
      });
      this.notificationsService.notifyConfirmation(
        this.intl.t('components.stats-system.csv-export.bulk-export-modal.export-successful'),
      );
      this.args.hideCsvExportModal();
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t(
          'components.stats-system.csv-export.bulk-export-modal.export-unsuccessful',
        ),
      });
    }
  }

  @action changeExportType(type) {
    this.exportType = type;
  }

  @action updateDateRange(range) {
    this.range = range;
    this.intercomEventService.trackAnalyticsEvent({
      start_date: this.range.start,
      end_date: this.range.end,
      action: 'date_range_updated',
      object: 'ce_report_csv_export',
    });
  }
}
