/* import __COLOCATED_TEMPLATE__ from './addon-card.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

interface Args {
  addOn: any;
  addOnPlanIds: number[];
  prices: any;
  billingPeriodDurationInMonths: number;
  toggleAddon: (addonId: number, source: string) => void;
}

export interface FeatureGroup {
  categoryTranslationKey: string;
  features: string[];
}

export default class AddonCard extends Component<Args> {
  @service declare appService: any;
  @service declare intl: any;

  get addOnPrice() {
    let addOnId = parseInt(this.args.addOn.id, 10);
    return this.args.prices.find((price: any) => price.hasSamePlans([addOnId]));
  }

  get monthlyAddOnPrice() {
    let price =
      this.addOnPrice?.getMonthlyTotalAfterTrial(this.args.billingPeriodDurationInMonths) / 100;
    return this.intl.formatNumber(price, {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    });
  }

  get addOnMetrics() {
    return this.addOnPrice?.getMetricsWithBasePricing();
  }

  get isAddedToPlan() {
    return this.args.addOnPlanIds.includes(parseInt(this.args.addOn.id, 10));
  }

  get featureGroups(): FeatureGroup[] {
    let addOnId = this.args.addOn.id;
    return [
      {
        categoryTranslationKey: `app.lib.billing.${addOnId}.plan_data.marketing_features_categories.metered_by_messages_sent`,
        features: [
          `app.lib.billing.${addOnId}.plan_data.marketing_features.in_app_messages`,
          `app.lib.billing.${addOnId}.plan_data.marketing_features.product_tours`,
          `app.lib.billing.${addOnId}.plan_data.marketing_features.surveys`,
          `app.lib.billing.${addOnId}.plan_data.marketing_features.notifications`,
          `app.lib.billing.${addOnId}.plan_data.marketing_features.carousels`,
        ],
      },
      {
        categoryTranslationKey: `app.lib.billing.${addOnId}.plan_data.marketing_features_categories.free_unlimited_use`,
        features: [
          `app.lib.billing.${addOnId}.plan_data.marketing_features.checklists`,
          `app.lib.billing.${addOnId}.plan_data.marketing_features.news`,
          `app.lib.billing.${addOnId}.plan_data.marketing_features.series`,
          `app.lib.billing.${addOnId}.plan_data.marketing_features.webhooks`,
          `app.lib.billing.${addOnId}.plan_data.marketing_features.testing`,
        ],
      },
    ];
  }

  @action
  toggleAddOnToPlan() {
    let addOnId = parseInt(this.args.addOn.id, 10);
    this.args.toggleAddon(addOnId, 'add_ons_page');
  }

  @action showMeteredMessagesArticle() {
    window.Intercom('showArticle', this.appService.app.meteredMessagesArticleId);
  }

  @action showProactiveSupportProArticle() {
    window.Intercom('showArticle', this.appService.app.proactiveSupportProArticleId);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Signup::Teams::Pricing5::AddonCard': typeof AddonCard;
  }
}
