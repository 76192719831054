/* import __COLOCATED_TEMPLATE__ from './keyword-reply-list.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { keywordTypes } from 'embercom/models/data/sms/constants';

export default class KeywordReplyList extends Component {
  get additionalFilters() {
    if (!Object.keys(keywordTypes).includes(this.args.filter)) {
      return null;
    }
    return { 'keyword_type.id': this.args.filter };
  }
}
