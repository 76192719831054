/* import __COLOCATED_TEMPLATE__ from './plan-selector-component.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { findSolution, PLAN_DATA } from 'embercom/lib/billing';
import { MARKETO_FORM_IDS } from 'embercom/lib/gtm';

export default class PlanSelector extends Component {
  @service router;
  @service appService;
  @service gtmService;
  @service purchaseAnalyticsService;
  @service media;
  @service intl;

  get app() {
    return this.appService.app;
  }

  get solution() {
    return findSolution(this.args.solutionId);
  }

  get recommendedAddOns() {
    return this.solution.recommendedAddons.map((addOn) => PLAN_DATA[addOn]);
  }

  get continueButtonLabel() {
    if (this.args.addOnPlanIds.length > 0 || this.media.isMobile) {
      return this.intl.t('signup.build-your-trial.plan_selector.continue');
    } else {
      return this.intl.t('signup.build-your-trial.plan_selector.continue_without_addons');
    }
  }

  get shouldDisableBillingPeriodSelectionDependants() {
    return !this.args.isBillingPeriodSelected;
  }

  @action
  transitionToNextStep() {
    let queryParams = { solution_id: this.args.solutionId };

    let { solutionId, addOn, addOnPlanIds, solutionPlusAddOnPrice, billingPeriodDurationInMonths } =
      this.args;

    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      object: 'continue_button',
      planIds: addOnPlanIds,
      solutionId,
      context: 'usecase_signup_flow',
      place: 'add_ons',
      cartAmount: solutionPlusAddOnPrice.getMonthlyTotalAfterTrial(billingPeriodDurationInMonths),
      mobile: this.media.isMobile,
      addOn,
      billing_period_duration_in_months: billingPeriodDurationInMonths,
    });

    this.fireMarketoEvent();

    if (this.args.billingPeriodDurationInMonths === 12) {
      this.router.transitionTo('apps.app.teams-checkout.usage', { queryParams });
    } else {
      this.router.transitionTo('apps.app.teams-checkout.confirm', { queryParams });
    }
  }

  fireMarketoEvent() {
    let formId = MARKETO_FORM_IDS.addOns;

    let params = {
      Email: this.app.currentAdmin.email,
      Add_on_Interest__c: 'Added to Cart', // eslint-disable-line @intercom/intercom/no-bare-strings
      Selected_Plan__c: this.solution.name,
    };

    Object.values(PLAN_DATA).forEach((plans) => {
      let fieldName = plans.marketoFormField;
      if (fieldName) {
        params[fieldName] = this.args.addOnPlanIds.includes(plans.id);
      }
    });

    this.gtmService.fireMarketoEvent(formId, params);
  }
}
