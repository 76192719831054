/* import __COLOCATED_TEMPLATE__ from './plan-metric-component.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import templateOnlyComponent from '@ember/component/template-only';
import { type PlanMetricDisplay } from 'embercom/components/signup/teams/pricing5/plan-builder';

const PlanMetricComponent = templateOnlyComponent<Signature>();

interface Args {
  planMetric: PlanMetricDisplay;
}

interface Signature {
  Args: Args;
}

export default PlanMetricComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Signup::Teams::Pricing5::PlanMetricComponent': typeof PlanMetricComponent;
  }
}
