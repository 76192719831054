/* import __COLOCATED_TEMPLATE__ from './vat-field.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */

import templateOnlyComponent from '@ember/component/template-only';

export interface Args {
  setVatNumber: (vatNumber: Event) => void;
  countryCode?: string;
}

interface Signature {
  Args: Args;
}

const VatField = templateOnlyComponent<Signature>();
export default VatField;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Stripe::VatField': typeof VatField;
  }
}
