/* import __COLOCATED_TEMPLATE__ from './goal-editor.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import {
  timeConstraintsByEntity,
  defaultGoalMatchingTime,
} from 'embercom/models/data/stats-system/goals-constants';
import { copy } from 'ember-copy';
import { tracked } from '@glimmer/tracking';

export default class GoalEditor extends Component {
  @service store;
  @service intercomEventService;

  @tracked goal;

  constructor() {
    super(...arguments);
    if (!this.goal) {
      this.initializeGoal();
    }
  }

  get goalsTimeConstraintOptions() {
    return timeConstraintsByEntity[this.args.entityType];
  }

  get goalType() {
    if (this.goal && this.goal.hasClientPredicate) {
      return 'activity attributes';
    } else if (this.goal && this.goal.hasUserPredicate) {
      return 'customer attribute';
    }
    return 'not set';
  }

  initializeGoal() {
    if (this.args.entity.goal) {
      this.goal = this.args.entity.goal;
    } else {
      let timeConstraint = this.args.defaultTimeConstraint || defaultGoalMatchingTime;
      this.goal = this.store.createFragment('stats-system/goal', {
        predicateGroup: {
          predicates: [],
        },
        entityId: this.args.entity.id,
        entityType: this.args.entityType,
        timeConstraint,
      });
    }
  }

  _trackGoalUpdateEvent(event) {
    let analyticsEvent = {
      entity_id: this.args.entity.id,
      object: this.args.humanReadableObjectName,
    };
    if (event === 'deleted') {
      analyticsEvent['action'] = 'removed goal';
    } else {
      analyticsEvent['action'] = 'added goal';
      analyticsEvent['goal_type'] = this.goalType;
      analyticsEvent['goal_time_constraint'] = this.goal.timeConstraint;
    }
    this.intercomEventService.trackAnalyticsEvent(analyticsEvent);
  }

  updateEntityGoal(goal, event) {
    this.args.entity.set('goal', goal);
    if (event !== 'edited') {
      this._trackGoalUpdateEvent(event);
    }
  }

  @action
  updatePredicates(predicate, meta) {
    let event = meta.action;
    this.goal.set('predicateGroup.predicates', copy(predicate));
    if (event === 'deleted') {
      this.updateEntityGoal(null, event);
    } else {
      this.updateEntityGoal(this.goal, event);
    }
  }

  @action
  updateTimeConstraint(timeToMatch) {
    this.args.entity.set('goal.timeConstraint', timeToMatch);
  }
}
