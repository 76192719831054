/* import __COLOCATED_TEMPLATE__ from './ip-allowlist-setting-change-details.hbs'; */
/* RESPONSIBLE TEAM: team-app-security */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';

interface IpAllowlistSettingChangeDetailsArgs {
  event: {
    value: {
      previous_state: string;
      new_state: string;
      previous_list: string[] | null;
      new_list: string[] | null;
    };
  };
}

export default class IpAllowlistSettingChangeDetails extends Component<IpAllowlistSettingChangeDetailsArgs> {
  @service declare intl: IntlService;

  @tracked showModal = false;

  get previousIpList(): string {
    return this.intl.t(
      'table-cells.admin-events.ip-allowlist-setting-change-details.ip-allowlist',
      { ipAllowlist: this._ipText(this.args.event.value.previous_list) },
    );
  }

  get newIpList(): string {
    return this.intl.t(
      'table-cells.admin-events.ip-allowlist-setting-change-details.ip-allowlist',
      { ipAllowlist: this._ipText(this.args.event.value.new_list) },
    );
  }

  private _ipText(list: string[] | null): string {
    if (list === null || list === undefined || list.length === 0) {
      return this.intl.t('table-cells.admin-events.ip-allowlist-setting-change-details.no-ips');
    }
    return list.join(', ');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'TableCells::AdminEvents::IpAllowlistSettingChangeDetails': typeof IpAllowlistSettingChangeDetails;
    'table-cells/admin-events/ip-allowlist-setting-change-details': typeof IpAllowlistSettingChangeDetails;
  }
}
