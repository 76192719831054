/* import __COLOCATED_TEMPLATE__ from './simple-composer-modal-component.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-component-inheritance */
/* eslint-disable ember/no-jquery */
import $ from 'jquery';
import ENV from 'embercom/config/environment';
// eslint-disable-next-line @intercom/intercom/no-legacy-modal
import ModalComponent from 'embercom/components/modal-component';
import { bind } from '@ember/runloop';
import { addEventListener, throttleTask } from 'ember-lifeline';

export default ModalComponent.extend({
  didInsertElement() {
    this._super(...arguments);
    this.updateMaxHeight();
    addEventListener(this, window, 'resize', () => this.updateMaxHeight());
  },
  updateMaxHeight() {
    throttleTask(this, '_updateMaxHeight', ENV.APP._80MS);
  },
  _updateMaxHeight() {
    let maxModalHeight = $(document).height() * 0.9;
    let $editor = $('.simple-composer__editor').css('max-height', '');
    let currentModalHeight = $('.modal').height();
    let currentEditorHeight = $editor.height();
    let newEditorHeight = maxModalHeight - currentModalHeight + currentEditorHeight;
    if (newEditorHeight > currentEditorHeight) {
      $editor.css('max-height', Math.floor(newEditorHeight));
    }
  },
  closeOnMouseUp() {
    $('.modal__overlay', this.element).on(
      'mouseup',
      bind(this, (e) => {
        e.preventDefault();
        let target = $(e.target);
        if (target.hasClass('modal__overlay') || target.hasClass('modal__overlay__inner')) {
          if (this.onClose) {
            this.onClose();
          }
          this.closeModal();
        }
        $('.modal__overlay').off('mouseup');
      }),
    );
  },
});
