/* import __COLOCATED_TEMPLATE__ from './country-select-component.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import Countries from 'embercom/lib/countries';
import { sortBy } from 'underscore';

export default Component.extend({
  classesForSelect: null,
  required: false,
  countryCodeError: null,
  selectedCountry: null,
  intl: service(),

  countries: computed('intl.locale', function () {
    Countries.allCountries.forEach((country) => {
      country.label = this.intl.t(country.nameTranslationKey);
    });
    return sortBy(Countries.allCountries, 'label');
  }),

  actions: {
    selectCountry(country) {
      this.set('selectedCountry', country);
      if (this.onSelect) {
        this.onSelect(country.code);
      }
    },
  },
});
