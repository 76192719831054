/* import __COLOCATED_TEMPLATE__ from './selected-plan-component.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import Component from '@glimmer/component';
import { findSolution } from 'embercom/lib/billing';

export default class SelectedPlan extends Component {
  get solution() {
    return findSolution(this.args.solutionId);
  }

  get solutionPriceForBillingPeriod() {
    return this.args.solutionPrice?.getMonthlyTotalAfterTrial(
      this.args.billingPeriodDurationInMonths,
    );
  }
}
