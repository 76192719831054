/* import __COLOCATED_TEMPLATE__ from './early-stage-basket.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { type Task } from 'ember-concurrency';
import { findSolution, percentDiscountForSolution } from 'embercom/lib/billing';

interface Args {
  solutionId: string;
  price: any;
  fetchNewPrices: Task<any, any>;
  shouldShowCart: boolean;
  usageMetrics: any;
  earlyStagePartner: boolean;
}

export default class EarlyStageBasket extends Component<Args> {
  @service declare intl: any;
  @service declare appService: any;

  get app() {
    return this.appService.app;
  }

  get billingPeriodDurationInMonths() {
    return 1;
  }

  get solution() {
    return findSolution(this.args.solutionId);
  }

  get numOfSeats() {
    return this.args.price.getPlanCoreSeatUsageAndPrice(this.billingPeriodDurationInMonths).usage;
  }

  get seatDiscountPriceTotalFormatted() {
    return this.args.price.getPlanCoreSeatUsageAndPrice(this.billingPeriodDurationInMonths).price;
  }

  get seatFullPricePerMonth() {
    return 99;
  }

  get seatFullPricePerMonthFormatted() {
    return this.intl.formatNumber(this.seatFullPricePerMonth, {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    });
  }

  get seatFullPriceTotal() {
    return this.numOfSeats * this.seatFullPricePerMonth;
  }

  get seatFullPriceTotalFormatted() {
    return this.intl.formatNumber(this.seatFullPriceTotal, {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    });
  }

  get priceBreakdowns() {
    return this.args.price.getBreakdownInfo(this.billingPeriodDurationInMonths);
  }

  get liteSeatLimit() {
    return this.solution.liteSeatLimit;
  }

  get planPercentOff() {
    return percentDiscountForSolution(this.args.solutionId);
  }

  get proactiveSupportPlusAddon() {
    return this.priceBreakdowns.find(
      (breakdown: any) => breakdown.addon && breakdown.name === 'Proactive Support Plus',
    );
  }

  get proactiveSupportPlusFullPrice() {
    return 99;
  }

  get proactiveSupportPlusFullPriceFormatted() {
    return this.intl.formatNumber(this.proactiveSupportPlusFullPrice, {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    });
  }

  get totalPrice() {
    return this.args.price.getMonthlyTotalAfterTrial(this.billingPeriodDurationInMonths) / 100;
  }

  get totalPriceFormatted() {
    return this.intl.formatNumber(this.totalPrice, {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    });
  }

  get totalFullPrice() {
    return this.seatFullPriceTotal + this.proactiveSupportPlusFullPrice;
  }

  get totalFullPriceFormatted() {
    return this.intl.formatNumber(this.totalFullPrice, {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    });
  }

  get amountDueTodayFormatted() {
    return this.intl.formatNumber(0, {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Signup::Teams::Pricing5::EarlyStageBasket': typeof EarlyStageBasket;
  }
}
