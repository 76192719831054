/* import __COLOCATED_TEMPLATE__ from './behavior.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import type SalesforceConfiguration from 'embercom/models/standalone/salesforce-configuration';

import { inject as service } from '@ember/service';

export default class StandaloneSetupSalesforceBehavior extends Component {
  @service declare finStandaloneService: FinStandaloneService;

  get salesforceConfig(): SalesforceConfiguration {
    return this.finStandaloneService.salesforceConfig;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Setup::Salesforce::Behavior': typeof StandaloneSetupSalesforceBehavior;
    'standalone/setup/salesforce/behavior': typeof StandaloneSetupSalesforceBehavior;
  }
}
