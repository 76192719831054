/* import __COLOCATED_TEMPLATE__ from './testing.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
import templateOnly from '@ember/component/template-only';
import { type PulseAccordion } from 'glint/pulse';

interface Signature {
  Args: {
    accordion: PulseAccordion;
  };
}

const StandaloneSalesforceSetupTesting = templateOnly<Signature>();
export default StandaloneSalesforceSetupTesting;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Salesforce::Setup::Testing': typeof StandaloneSalesforceSetupTesting;
    'standalone/salesforce/setup/testing': typeof StandaloneSalesforceSetupTesting;
  }
}
