/* import __COLOCATED_TEMPLATE__ from './multi-line-chart.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { WIDTH_TYPES, HEIGHT_TYPES } from 'embercom/models/reporting/custom/chart';
import { VISUALIZATION_TYPES } from 'embercom/models/reporting/custom/visualization-options';
import generateUUID from 'embercom/lib/uuid-generator';

export default class MultiLineChart extends Component {
  @service reportingMetrics;
  @service appService;
  @service intl;
  @service seriesEditorService;
  @service store;
  uniqueId = generateUUID();

  constructor() {
    super(...arguments);
    this.chartDefinition = [this.createOrderableChart()];
  }

  get charts() {
    return this.chartDefinition;
  }

  get reportState() {
    return {
      settings: {},
      dateRange: this.seriesEditorService.range,
      filters: {},
      isStatic: true,
      colorChartsByPoint: true,
    };
  }

  createMultilineChartDefinition(chartId, statDetails, chartTitle) {
    let allFilters = this.args.filter.slice();
    allFilters[0].data.values = Array.isArray(this.args.filterValues)
      ? this.args.filterValues
      : [this.args.filterValues];
    let chartMetrics = [];
    statDetails.map((statDetail) => {
      if (statDetail.condition === undefined || statDetail.condition(this.seriesEditorService)) {
        chartMetrics.addObject({
          metric_id: statDetail.numerator_metric,
          color: statDetail.color,
          filters: {
            type: 'and',
            filters: allFilters,
          },
        });
      }
    });
    let chartDefinition = {
      id: chartId,
      title: chartTitle,
      visualization_type: VISUALIZATION_TYPES.LINE,
      segment_by: null,
      chart_series: chartMetrics,
      date_range: {
        selection: 'custom',
        start: this.args.range.start,
        end: this.args.range.end,
      },
      view_by: 'time',
      width_type: WIDTH_TYPES.FULL,
      height_type: HEIGHT_TYPES.TALL,
    };
    return chartDefinition;
  }

  createOrderableChart() {
    let chartId = `chart-${this.uniqueId}-${generateUUID()}`;
    let chartTitle = this.intl.t(
      'outbound.series.aggregated-stats.performance.multiline-chart-title',
    );
    let normalized = this.store.normalize(
      'reporting/custom/chart',
      this.createMultilineChartDefinition(chartId, this.args.statInfo, chartTitle),
    );
    let chart = this.store.createRecord('reporting/custom/chart', normalized.data.attributes);
    return chart;
  }
}
