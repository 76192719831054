/* import __COLOCATED_TEMPLATE__ from './composer-wrapper.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import intercomMessengerColors from '@intercom/intercom-messenger-colors';
import { addOpacityToSimpleHex } from 'embercom/lib/color';

const MINIMAL_CONTRAST_RATIO = 1.6;
const LIGHT_TEXT_COLOR = '#FFFFFF';
const DARK_TEXT_COLOR = '#000000';

// Equivalent of 0.32 alpha value, see https://gist.github.com/lopspower/03fb1cc0ac9f32ef38f4
const DROP_SHADOW_TRANSPARENCY_IN_HEX = '52';

export default class SurveyEditorComposerWrapper extends Component {
  get surveyButtonColor() {
    return this.args.survey.customizationOptions.buttonColor;
  }

  get messengerColors() {
    return intercomMessengerColors({
      primaryColor: this.args.backgroundColor,
      secondaryColor: this.surveyButtonColor,
    });
  }

  get surveyTextColor() {
    return this.isSurveyBackgroundDark ? LIGHT_TEXT_COLOR : DARK_TEXT_COLOR;
  }

  get isSurveyBackgroundDark() {
    return this.messengerColors.primary_on_white_contrast > MINIMAL_CONTRAST_RATIO;
  }

  get bannerPlaceholderTextColor() {
    return addOpacityToSimpleHex(this.surveyTextColor, 0.6);
  }

  get customButtonPlaceholderTextColor() {
    return this.isSurveyButtonBackgroundDark
      ? addOpacityToSimpleHex(this.surveyButtonTextColor, 0.65)
      : addOpacityToSimpleHex(this.surveyButtonTextColor, 0.5);
  }

  get editableMobileCtaPlaceholderColor() {
    return addOpacityToSimpleHex(this.surveyTextColor, 0.6);
  }

  get webCtaPlaceholderColor() {
    return addOpacityToSimpleHex(DARK_TEXT_COLOR, 0.5);
  }

  get surveyButtonTextColor() {
    return this.isSurveyButtonBackgroundDark ? LIGHT_TEXT_COLOR : DARK_TEXT_COLOR;
  }

  get surveyDropShadowColor() {
    return `${this.args.backgroundColor}${DROP_SHADOW_TRANSPARENCY_IN_HEX}`;
  }

  get isSurveyButtonBackgroundDark() {
    return this.messengerColors.secondary_on_white_contrast > MINIMAL_CONTRAST_RATIO;
  }

  get questionNoVisibleBorderClassName() {
    return this.isSurveyBackgroundDark ? 'question__no-visible-border' : '';
  }

  get thankYouStepClassName() {
    if (this.args.currentStep?.isThankYouType && this.args.isEditing) {
      return 'py-2 px-5';
    } else {
      return 'p-5';
    }
  }

  get thankYouStepAvatarClassName() {
    if (this.args.currentStep?.isThankYouType && this.args.isEditing) {
      return 'mr-2 mt-4';
    } else {
      return 'mr-2';
    }
  }

  get thankYouCTAClassName() {
    if (this.args.currentStep?.isThankYouType) {
      if (this.args.putCtaBesideText) {
        return 'survey__editor__composer-content-thank-you-container-cta-beside';
      } else {
        return 'survey__editor__composer-content-thank-you-container-cta-under';
      }
    }
  }
}
