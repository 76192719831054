/* import __COLOCATED_TEMPLATE__ from './add-question.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';

export default class AddQuestion extends Component {
  get shouldShowValidations() {
    return this.args.shouldShowValidations;
  }

  get addQuestionFormat() {
    let format = 'survey__editor__add-question';

    if (this.shouldShowValidations) {
      format += '--validation-error';
    }

    return format;
  }

  get analyticsEventName() {
    return this.args.buttonLabel.split(' ').join('_').toLowerCase();
  }

  get popoverOffset() {
    return [0, 16];
  }
}
