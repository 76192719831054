/* import __COLOCATED_TEMPLATE__ from './ai-agent-identity-settings-change-details.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'ember-intl/services/intl';

interface AiAgentIdentitySettingsChangeDetailsArgs {
  event: {
    value: {
      name: {
        before: string;
        after: string;
      };
      avatar_url: {
        before: string;
        after: string;
      };
    };
  };
}

type AvatarShape = 'circle' | 'squircle' | undefined;

interface AvatarDataModel {
  avatarData: {
    avatarShape: AvatarShape;
    url: string;
  };
}

export default class AiAgentIdentitySettingsChangeDetails extends Component<AiAgentIdentitySettingsChangeDetailsArgs> {
  @service declare intl: IntlService;

  @tracked showModal = false;

  identityPreview(avatarUrl: string): AvatarDataModel {
    return {
      avatarData: {
        avatarShape: 'squircle',
        url: avatarUrl,
      },
    };
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'TableCells::AdminEvents::AiAgentIdentitySettingsChangeDetails': typeof AiAgentIdentitySettingsChangeDetails;
    'table-cells/admin-events/ai-agent-identity-settings-change-details': typeof AiAgentIdentitySettingsChangeDetails;
  }
}
