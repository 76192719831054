/* import __COLOCATED_TEMPLATE__ from './api-connection.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
/* eslint-disable @intercom/intercom/no-bare-strings */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type SalesforceConfiguration from 'embercom/models/standalone/salesforce-configuration';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import { action } from '@ember/object';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';
import { type PulseAccordion } from 'glint/pulse';

interface Args {
  accordion: PulseAccordion;
}

export default class StandaloneSalesforceApiConnection extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare intercomConfirmService: IntercomConfirmService;
  @service declare notificationsService: $TSFixMe;

  @action startOauthFlow() {
    let uri = this.oauthUrls.verify;
    let params = `app_id_code=${this.salesforceConfig.id}&client_id=${this.salesforceConfig.clientId}`;
    window.location.href = `${uri}?${params}`;
  }

  @action async disconnectApp() {
    let isConfirmed = await this.intercomConfirmService.confirm({
      body: 'Are you sure you want to delete your Salesforce configuration? If you have connected to Salesforce, Fin will immediately stop responding to cases.',
      confirmButtonText: 'Delete Salesforce Configuration',
    });

    try {
      if (isConfirmed) {
        await this.salesforceConfig.disconnectApp();
        this.notificationsService.notifyConfirmation(
          'Your Salesforce configuration has been deleted',
        );
      }
    } catch (e) {
      this.notificationsService.notifyResponseError(e, {
        default: `Something went wrong and we couldn't disconnect your Salesforce app. Please try again`,
      });
    }
  }

  get oauthUrls() {
    let { protocol, host } = window.location;
    return {
      verify: `${protocol}//${host}/standalone/salesforce/verify`,
      callback: `${protocol}//${host}/standalone/salesforce/callback`,
    };
  }

  get salesforceConfig(): SalesforceConfiguration {
    return this.finStandaloneService.salesforceConfig;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Salesforce::Setup::ApiConnection': typeof StandaloneSalesforceApiConnection;
    'standalone/salesforce/setup/api-connection': typeof StandaloneSalesforceApiConnection;
  }
}
