/* import __COLOCATED_TEMPLATE__ from './bulk-message.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-observers */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-jquery */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/no-legacy-modal */
import { isArray } from '@ember/array';
import { equal, gt, mapBy, not, notEmpty, or, reads, readOnly } from '@ember/object/computed';
import { next, schedule } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import {
  jsonStringify,
  selectFromObject,
  subtractFrom,
  subtractProperties,
  ternary,
  ternaryToProperty,
} from '@intercom/pulse/lib/computed-properties';
import intermoji from '@intercom/intermoji';
import MessageEvents from 'embercom/controllers/mixins/message-events';
import ajax from 'embercom/lib/ajax';
import { LEAD_ROLE_PREDICATE, USER_ROLE_PREDICATE } from 'embercom/lib/default-predicates';
import FiltersToText from 'embercom/lib/filters-to-text';
import logLegacyComposerError from 'embercom/lib/log-legacy-composer-error';
import LegacySelectionState from 'embercom/models/legacy-selection-state';
import Message from 'embercom/models/message';
import $ from 'jquery';
import { Promise as EmberPromise } from 'rsvp';
import { action, computed } from '@ember/object';
import Component from '@ember/component';

export default Component.extend(MessageEvents, {
  BULK_MESSAGE_URL: 'ember/stats_system/message.json',
  tagName: '',
  intercomEventService: service(),
  notificationsService: service(),
  appService: service(),
  app: readOnly('appService.app'),
  admin: readOnly('app.currentAdmin'),
  store: service(),
  intl: service(),
  modalService: service(),
  router: service(),
  TARGET_ID_KEY: 'id',
  paywallService: service(),
  messageTarget: 'user',
  messageTargetPlural: 'users',
  modalAction: 'message',
  blocks: [],
  jsonBlocks: jsonStringify('blocks'),
  typePresetMappings: {
    email: 'simpleLegacyComposerEmailPreset',
    chat: 'simpleLegacyComposerInAppChatPreset',
    'small-announcement': 'simpleLegacyComposerInAppAnnouncementPreset',
    announcement: 'simpleLegacyComposerInAppBigAnnouncementPreset',
  },
  simpleComposerPreset: selectFromObject('typePresetMappings', 'type'),
  isCreatingUserMessage: equal('messageTarget', 'user'),
  isCreatingCompanyMessage: not('isCreatingUserMessage'),
  isSimpleComposerUploading: false,
  cannotUseMessenger: not('app.canUseMessenger'),
  isSendingDisabled: or('isSaving', 'isSimpleComposerUploading'),
  isEmojiPopoverOpen: false,
  isComposerVideoPopoverOpen: false,
  isMessageTypePopoverOpen: false,
  isMessageStylePopoverOpen: false,
  isSavedContentPopoverOpen: false,
  hasInsertedSavedContentWithActions: false,

  init() {
    this._super(...arguments);
    this.reset();
  },

  didInsertElement() {
    this._super(...arguments);
    if (this.isTargetingLeads) {
      this.setMessageChannel('email');
    }
    schedule('afterRender', () => {
      this.composerComponent.send('focus');
    });
  },

  get warningMessage() {
    return this.intl.t('components.modal.message.warning-message');
  },

  sendLabel: computed('intl.locale', 'isSaving', function () {
    return this.isSaving
      ? this.intl.t('components.modal.message.sending')
      : this.intl.t('components.modal.message.send');
  }),

  shouldSendPushNotification: computed(
    'isCreatingCompanyMessage',
    'isEmailMessage',
    'autoUseAdvancedComposer',
    function () {
      return (
        !this.isCreatingCompanyMessage && !this.isEmailMessage && !this.autoUseAdvancedComposer
      );
    },
  ),

  targetedUsersCount: computed('selectionState.count', 'isCreatingCompanyMessage', function () {
    return new EmberPromise((resolve) => {
      if (this.isCreatingCompanyMessage) {
        this.companyUserCount.then(
          function (response) {
            resolve(response.count);
          },
          function () {
            resolve(null);
          },
        );
      } else {
        resolve(this.get('selectionState.count'));
      }
    });
  }),

  companyUserCount: computed('selectionState.predicates', function () {
    return ajax({
      url: '/ember/users/count.json',
      type: 'POST',
      timeout: 40000,
      data: JSON.stringify({
        app_id: this.get('app.id'),
        predicates: this.get('selectionState.predicates'),
      }),
    });
  }),

  isEmailMessage: equal('type', 'email'),
  hasSubject: notEmpty('subject'),
  firstSelectedUserIsContact: reads('model.selected.firstObject.is_anonymous'),
  isTargetingLeads: reads('firstSelectedUserIsContact'),
  manualMessagingType: ternary('isTargetingLeads', 'contact_messaging', 'manual_messaging'),
  type: 'chat',
  deliveryOption: ternary('isEmailMessage', null, 'summary'),
  defaultEmailTemplateId: reads('app.default_email_template_id'),
  emailTemplateId: reads('defaultEmailTemplateId'),
  emailTemplate: computed('emailTemplateId', async function () {
    let emailTemplateTitles = this.store.peekAll('email-template-titles');
    if (emailTemplateTitles.length === 0) {
      emailTemplateTitles = await this.store.findAll('email-template-titles');
    }
    return emailTemplateTitles.findBy('id', this.emailTemplateId);
  }),

  reset() {
    this.setProperties({
      body: `${this.get('app.commentPlaceholder')}\n`,
      blocks: [{ type: 'paragraph', text: this.get('app.commentPlaceholder') }],
      subject: '',
      isSaving: false,
      hasErrored: false,
      type: 'chat',
      hasInsertedSavedContentWithActions: false,
    });
  },

  hasBody: computed('body', function () {
    if (typeof this.body === 'undefined') {
      return false;
    }
    let body = $.trim(this.body);
    let placeholder = this.get('app.commentPlaceholder');
    return body !== placeholder;
  }),

  hasBodyOrSubject: or('hasBody', 'hasSubject'),

  trackMessageEvent() {
    //intended to be overidden where appropriate
  },

  singleTarget: equal('model.selected.length', 1),

  selectionState: computed('model', function () {
    let model = this.model;
    if (this.singleTarget) {
      return LegacySelectionState.createFromId(
        model.get(`selected.firstObject.${this.TARGET_ID_KEY}`),
      );
    } else {
      return model;
    }
  }),

  autoUseAdvancedComposer: gt('selectionState.count', 10),

  countUndefined: computed('selectionState.count', function () {
    return isNaN(this.get('selectionState.count'));
  }),

  totalCountWithoutUnselected: subtractProperties(
    'selectionState.totalCount',
    'selectionState.unselected.length',
  ),
  selectedTotalCount: ternaryToProperty(
    'selectionState.isSelectAllMode',
    'totalCountWithoutUnselected',
    'model.selected.length',
  ),

  selectedUserIds: mapBy('model.selected', 'id'),

  activeSelectedUsersCount: computed('model.selected', function () {
    return this.get('model.selected').filterBy('isActive', true).length;
  }),
  inactiveSelectedUsersCount: computed('model.selected', function () {
    return this.get('model.selected').filterBy('isActive', false).length;
  }),

  selectedUsersFromCache: computed('selectedUserIds', function () {
    return this.selectedUserIds
      .map((userId) => this.store.peekRecord('user', userId))
      .filter((user) => isPresent(user));
  }),
  remainingUserCount: subtractFrom('model.selected.length', 1),

  isBulkMessage: computed('selectionState.isSelectAllMode', function () {
    return this.get('selectionState.isSelectAllMode') ? 1 : 0;
  }),

  selectedLabel: ternary('model.isSelectAllMode', 'unselected', 'selected'),
  selectedTargetsPath: computed('selectedLabel', function () {
    return `selectionState.${this.selectedLabel}`;
  }),

  transformedSelectedTargets: computed('selectedTargetsPath', function () {
    let selectedTargets = this.get(this.selectedTargetsPath).mapBy(this.TARGET_ID_KEY);
    return selectedTargets.map(function (targetId) {
      let targetObject = {};
      targetObject[targetId] = 1;
      return targetObject;
    });
  }),

  createMessage() {
    let bulk = this.isBulkMessage;

    let targetedObjects = this.transformedSelectedTargets;
    let messageData = {
      message: {
        message_type: this.type.toLowerCase(),
        email_style: this.emailTemplateId,
        body: this.body,
        owner_id: this.get('admin.id'),
        subject: this.subject,
        show_unsubscribe_link: 1,
      },
      search: this.get('selectionState.search'),
      redirect_to: this.router.currentURL,
    };
    messageData[`targeted_${this.messageTargetPlural}`] = targetedObjects;
    if (bulk) {
      messageData.bulk = bulk;
    }
    return JSON.stringify(messageData);
  },

  messageData: computed('type', 'subject', 'body', 'selectionState', {
    get() {
      return this.createMessage();
    },
    set(key, value) {
      return value;
    },
  }),

  selectionStateToApi: computed('selectionState', 'isTargetingLeads', function () {
    let hash = this.selectionState.toApi();
    let rolePredicate = this.isTargetingLeads ? LEAD_ROLE_PREDICATE : USER_ROLE_PREDICATE;
    if (!hash.predicates.includes(rolePredicate)) {
      hash.predicates = [rolePredicate].concat(hash.predicates);
    }
    return hash;
  }),

  submitMessage() {
    this.set('isSaving', true);
    this.set('hasErrored', false);
    this.postMessage().then(
      (message) => {
        this.trackMessageEvent();
        this.notificationsService.notifyConfirmation(
          this.intl.t('components.modal.message.sending-message'),
        );
        this.reset();
        this.modalService.closeModal({ success: true });
      },
      (response) => {
        if (isPresent(response.jqXHR) && response.jqXHR.status === 422) {
          if (isArray(response.jqXHR.responseJSON.errors)) {
            response.jqXHR.responseJSON.errors.forEach((error) => {
              this.notificationsService.notifyError(
                this.intl.t('components.modal.message.error-message', {
                  errorMessage: error.message,
                }),
              );
            });
          } else {
            this.notificationsService.notifyError(
              this.intl.t('components.modal.message.error-message', {
                errorMessage: response.jqXHR.responseJSON.errors,
              }),
            );
          }
        } else {
          this.notificationsService.notifyResponseError(response);
          this.set('hasErrored', true);
        }
        this.set('isSaving', false);
      },
    );
  },

  postMessage() {
    return ajax({
      url: this.BULK_MESSAGE_URL,
      type: 'POST',
      data: JSON.stringify(this._generateRequestData()),
    }).then(() => {
      let analyticsEvent = {
        object: this.get('options.shortObjectName'),
        app_id: this.get('app.id'),
        action: 'bulk-messaged-stats-list',
      };
      this.intercomEventService.trackAnalyticsEvent(analyticsEvent);
    });
  },

  _generateRequestData() {
    let data = {
      app_id: this.get('options.app_id'),
      admin_id: this.get('options.admin_id'),
      additional_filters: this.get('options.additional_filters'),
      is_select_all_mode: this.get('options.is_select_all_mode'),
      included_ids: this.get('options.included_ids'),
      stat: this.get('options.stat'),
      content_id: this.get('options.content_id'),
      content_type: this.get('options.content_type'),
      count: this.get('options.count'),
      blocks: this.composerComponent.getBlocks(),
      created_via: 'simple_composer',
      created_via_version: Message.CREATED_VIA_SIMPLE_COMPOSER_VERSION,
      subject: this.subject,
      message_type: this.type,
      delivery_option: this.deliveryOption,
      email_style: this.emailTemplateId,
      company_message: this.isCreatingCompanyMessage,
      send_push_notification: this.shouldSendPushNotification,
      exists_fields: this.get('options.exists_fields'),
    };
    return data;
  },

  _trackMessageFiltersOnSend() {
    if (
      this.get('selectionState.included_ids.length') &&
      this.get('selectionState.predicates.length') > 1
    ) {
      let filterDescriptions = this.get('selectionState.predicates').map(function (predicate) {
        return `${predicate.attribute}  ${FiltersToText.getPredicateDescription(predicate)}`;
      });
      this.intercomEventService.trackEvent('filter-message-with-selection', {
        count: this.get('selectionState.included_ids.length'),
        filters: filterDescriptions.join(', '),
      });
    }
  },

  _focusOnSubject() {
    if (!this.isDestroying) {
      $('.simple-composer__input:first').focus();
    }
  },
  closeModal: action(function () {
    this.modalService.closeModal();
  }),
  closeAndReset: action(function () {
    this.set('hasInsertedSavedContentWithActions', false);
    this.modalService.closeModal();
  }),
  toggleComposerVideoPopover: action(function () {
    this.toggleProperty('isComposerVideoPopoverOpen');
  }),
  toggleMessageTypePopover: action(function () {
    this.toggleProperty('isMessageTypePopoverOpen');
  }),
  toggleMessageStylePopover: action(function () {
    this.toggleProperty('isMessageStylePopoverOpen');
  }),
  toggleSavedContentPopover: action(function () {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'opened_popover',
      object: 'saved_content',
    });
    this.toggleProperty('isSavedContentPopoverOpen');
  }),
  uploadImage: action(function (files) {
    this.composerComponent.send('uploadImage', files);
  }),
  insertEmoji: action(function (emojiIdentifier) {
    let unicode = intermoji.unicodeFromIdentifier(emojiIdentifier);
    this.composerComponent.send('paste', unicode, 'plain');
    this.set('isEmojiPopoverOpen', false);
  }),
  setMessageChannel: action(function (channel) {
    this.set('type', channel);
    if (channel === 'email') {
      next(this, this._focusOnSubject);
      this.admin.sendVerificationEmail();
    }
  }),
  setEmailTemplate: action(function (templateId) {
    this.set('emailTemplateId', templateId);
  }),
  insertVideo: action(function (videoUrl) {
    this.composerComponent.send('insertVideo', videoUrl);
  }),
  insertSavedContent: action(function (savedContent) {
    this.composerComponent.send('insertBlocks', savedContent.blocks);
    this.toggleProperty('isSavedContentPopoverOpen');
    if (savedContent.hasActions) {
      this.set('hasInsertedSavedContentWithActions', true);
    }
  }),
  uploadAttachment: action(function (files) {
    this.composerComponent.send('uploadAttachment', files);
  }),
  onSubmit: action(function () {
    if (this.isSendingDisabled || this.cannotUseMessenger) {
      return;
    }
    if (this.singleTarget) {
      return this.submitMessage();
    }
    this.paywallService
      .paywall({ featureName: this.manualMessagingType })
      .then(this.submitMessage.bind(this))
      .catch(this.paywallService.handleError);
  }),

  onComposerError: action(function (error) {
    if (!error.recovered) {
      this.notificationsService.notifyError(
        this.intl.t('components.modal.message.unexpected-error-message'),
        -1,
      );
    }
    logLegacyComposerError('Wizard', error, {
      messageId: this.get('message.id'),
      composerConfig: this.composerConfig,
    });
  }),
});
