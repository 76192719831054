/* import __COLOCATED_TEMPLATE__ from './created-at-cell.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { ternaryToProperty } from '@intercom/pulse/lib/computed-properties';

export default Component.extend({
  appService: service(),

  tagName: 'td',
  classNames: ['table__cell settings__attributes-events__cell'],
  classNameBindings: ['archived:o__archived'],

  app: readOnly('appService.app'),
  event: readOnly('row.event'),
  createdAt: ternaryToProperty('event.created_at', 'event.created_at', 'app.created_at'),
  archived: readOnly('event.archived'),
});
