/* import __COLOCATED_TEMPLATE__ from './failure-list-title.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { stats } from 'embercom/models/data/stats-system/stats-constants';
import Component from '@glimmer/component';
import { failureListCategories } from 'embercom/models/data/stats-system/stats-constants';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import { action } from '@ember/object';

const emailFailureReasons = [
  {
    text: `bounced, unsubscribed, or marked this email as spam.`,
    filterValue: '',
  },
  {
    text: `bounced.`,
    filterValue: failureListCategories.hardBounce,
  },
  {
    text: `unsubscribed.`,
    filterValue: failureListCategories.unsubscribe,
  },
  {
    text: `marked this email as spam.`,
    filterValue: failureListCategories.spamComplaint,
  },
];

const smsFailureReasons = [
  {
    text: `unsubscribed, or could not be sent this sms.`,
    filterValue: '',
  },
  {
    text: `unsubscribed.`,
    filterValue: failureListCategories.unsubscribe,
  },
  {
    text: `could not be sent this sms.`,
    filterValue: failureListCategories.smsFailure,
  },
];

export default class FailureListTitle extends Component {
  get filterItems() {
    let objectType = this.args.statsParameters.contentType;
    let filterItems = [{ text: 'All issues', value: '' }];

    if (objectType === objectTypes.email) {
      filterItems.pushObjects([
        { text: 'Hard bounced', value: failureListCategories.hardBounce },
        { text: 'Unsubscribed', value: failureListCategories.unsubscribe },
        { text: 'Marked as spam', value: failureListCategories.spamComplaint },
      ]);
    } else if (objectType === objectTypes.sms) {
      filterItems.pushObjects([
        { text: 'Unsubscribed', value: failureListCategories.unsubscribe },
        { text: 'Failed', value: failureListCategories.smsFailure },
      ]);
    }

    return filterItems;
  }

  get failureReason() {
    let objectType = this.args.statsParameters.contentType;
    let reasons = [
      {
        text: `could not be sent this ${this.args.statsParameters.uiObjectName}.`,
        filterValue: '',
      },
    ];

    if (objectType === objectTypes.email) {
      reasons = emailFailureReasons;
    } else if (objectType === objectTypes.sms) {
      reasons = smsFailureReasons;
    }

    let reason =
      reasons.find((reason) => reason.filterValue === this.selectedFilterValue) || reasons[0];

    return reason.text;
  }

  get selectedFilterValue() {
    let availableFilters = [
      failureListCategories.hardBounce,
      failureListCategories.unsubscribe,
      failureListCategories.spamComplaint,
      failureListCategories.smsFailure,
      failureListCategories.pushFailure,
    ];
    return availableFilters.includes(this.args.filter) ? this.args.filter : '';
  }

  @action
  changeFilter(filter) {
    this.args.changeView(stats.emailFailure, filter);
  }
}
