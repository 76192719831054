/* import __COLOCATED_TEMPLATE__ from './simple-tag-component.hbs'; */
/* RESPONSIBLE TEAM: unused-components */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-component-inheritance */
import { computed } from '@ember/object';
import { alias } from '@ember/object/computed';
import AttributeComponent from 'embercom/components/attribute-component';
import B64FilterParamEncoder from 'embercom/vendor/intercom/b64-filter-param-encoder';

let SimpleTagComponent = AttributeComponent.extend({
  value: alias('tagModel.name'),
  filterUrl: computed('userOrCompany', 'attribute', 'tagModel.predicate', function () {
    return B64FilterParamEncoder.filterUrlFromPredicate(
      this.userOrCompany,
      this.attribute,
      this.get('tagModel.predicate'),
    );
  }),
});

export default SimpleTagComponent;
