/* import __COLOCATED_TEMPLATE__ from './content-button.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class ContentButton extends Component {
  stepCustomButtonTextLength = 16;
  @tracked inputFocused = '';

  get outlineClass() {
    if (this.args.shouldShowValidations && this.args.step?.invalidButtonText) {
      return 'o__validation-error';
    } else {
      return this.inputFocused;
    }
  }
}
