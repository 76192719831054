/* import __COLOCATED_TEMPLATE__ from './section.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { type UserField, type TicketField } from 'embercom/services/fin-standalone-service';

interface Signature {
  Args: {
    title: string;
    isLoading: boolean;
    fields: Array<UserField | TicketField>;
    selectedField: UserField | TicketField | undefined;
    selectField: (field: UserField | TicketField) => void;
    unsynchronizedFields: Array<UserField | TicketField>;
    synchronizeField: (field: UserField | TicketField) => void;
  };
}

export default class DataFieldSection extends Component<Signature> {
  @tracked filter = '';

  get filteredUnsynchronizedFields() {
    return this.args.unsynchronizedFields.filter((field) =>
      field.name.toLowerCase().includes(this.filter.toLowerCase()),
    );
  }

  @action clearFilter() {
    this.filter = '';
  }

  @action updateFilter(event: KeyboardEvent & { target: HTMLInputElement }) {
    this.filter = event.target?.value;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::DataField::Section': typeof DataFieldSection;
  }
}
