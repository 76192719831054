/* import __COLOCATED_TEMPLATE__ from './ruleset-cell.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class RulesetCell extends Component {
  @service store;

  get icon() {
    if (!this.node) {
      return null;
    }
    return this.node.graphConfiguration.componentConfig.icon;
  }

  get node() {
    let nodes = this.store.peekAll('series/node');
    return nodes.findBy('rulesetId', this.args.rulesetId);
  }

  get title() {
    return this.node?.title || 'Deleted';
  }
}
