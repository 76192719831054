/* import __COLOCATED_TEMPLATE__ from './keyword-reply-list-row.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { keywordTypes } from 'embercom/models/data/sms/constants';

export default class KeywordReplyListRow extends Component {
  get keyword() {
    return keywordTypes[this.args.row.data.keywordType];
  }
}
