/* import __COLOCATED_TEMPLATE__ from './clicked-title.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { stats } from 'embercom/models/data/stats-system/stats-constants';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import { getTooltipFilterLabel } from './title-helpers';

export default class ClickedTitle extends Component {
  @service appService;
  @service contentEditorService;

  get app() {
    return this.appService.app;
  }

  get tooltipGroup() {
    return this.contentEditorService.activeObject;
  }

  get isFilteringByTooltip() {
    return this.args.statsParameters.contentType === objectTypes.tooltip;
  }

  get activeFilterTooltip() {
    return this.args.statsParameters.contentObject;
  }

  get filterItems() {
    return this.tooltipGroup.tooltips
      .filter((tooltip) => tooltip.hasLinks)
      .map((tooltip, index) => ({
        text: getTooltipFilterLabel(tooltip, index),
        value: Number(tooltip.id),
      }));
  }

  get selectedFilterValue() {
    return (
      Number(this.args.filter) ||
      this.tooltipGroup.tooltips
        .filter((tooltip) => tooltip.hasLinks)
        .map((tooltip) => Number(tooltip.id))[0]
    );
  }

  @action
  changeFilter(filter) {
    this.args.changeView(stats.click, filter);
  }
}
