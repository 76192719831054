/* import __COLOCATED_TEMPLATE__ from './impersonation-session-events.hbs'; */
/* RESPONSIBLE TEAM: team-app-security */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import AppAdminEventsQuery from 'embercom/lib/admins/app-admin-events-query';

export default class ImpersonationSessionEvents extends Component {
  @service intl;
  @service store;
  @service appService;

  get modalHeader() {
    return this.intl.t('table-cells.admin-events.impersonation-session-events.actions');
  }

  get columns() {
    return [
      {
        label: this.intl.t(
          'table-cells.admin-events.impersonation-session-events.column-headers.action',
        ),
        valuePath: 'type',
        width: 'flex',
      },
      {
        label: this.intl.t(
          'table-cells.admin-events.impersonation-session-events.column-headers.date',
        ),
        valuePath: 'created_at',
      },
    ];
  }

  get impersonationLogs() {
    let impersonationLogs = AppAdminEventsQuery.create({
      appId: this.appService.app.id,
      content: [],
    });
    impersonationLogs.loadByImpersonationId.perform(this.args.event.impersonation_id);
    return impersonationLogs;
  }
}
