/* import __COLOCATED_TEMPLATE__ from './dropdown.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { isEmpty } from '@ember/utils';

export default class Dropdown extends Component {
  @tracked dropdownOptionsInString;

  constructor() {
    super(...arguments);
    this.dropdownOptionsInString = this.args.question.data.options.join();
  }

  get dropdownOptions() {
    return this.dropdownOptionsInString
      .split(',')
      .map((item) => item.trim())
      .filter((item) => item);
  }

  get showInvalidDropdownOptionsError() {
    let invalidOptions = false;
    if (this.args.question.isDropdown && this.args.shouldShowValidations) {
      invalidOptions = isEmpty(this.args.question.data.options) || this.dropdownOptions.length < 1;
    }

    return invalidOptions;
  }

  @action updateDropdownOptions() {
    this.args.question.data.options = [...new Set(this.dropdownOptions)];
  }

  @action loadOptions() {
    this.dropdownOptionsInString = this.args.question.data.options.join();
  }
}
