/* import __COLOCATED_TEMPLATE__ from './sunshine-testing.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
/* eslint-disable @intercom/intercom/no-bare-strings */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { type PulseAccordion } from 'glint/pulse';
import type ZendeskConfiguration from 'embercom/models/standalone/zendesk-configuration';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import OpenCenteredWindow from 'embercom/lib/open-centered-window';
import { trackedInLocalStorage } from 'ember-tracked-local-storage';
import { action } from '@ember/object';
import ENV from 'embercom/config/environment';
import { tracked } from '@glimmer/tracking';
import { get } from 'embercom/lib/ajax';

interface Args {
  accordion: PulseAccordion;
}

export default class StandaloneZendeskSetupSunshineTesting extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare appService: any;
  @service declare notificationsService: any;

  @tracked manualMode = false;

  @trackedInLocalStorage({ keyName: 'fin-standalone-zd-messaging-key' })
  zendeskMessagingKey = '';

  @trackedInLocalStorage({ keyName: 'fin-standalone-zd-messaging-url' })
  urlWithMessagingKey = '';

  @action async findZendeskMessagingKey() {
    let response = await get(
      `/ember/standalone/zendesk_configurations/${this.appService.app.id}/find_zendesk_messenger_key`,
      {
        app_id: this.appService.app.id,
        url: this.urlWithMessagingKey,
      },
    );

    if (response.key) {
      this.zendeskMessagingKey = response.key;
      this.previewZendeskMessaging();
    } else {
      this.notificationsService.notifyError(
        "We couldn't find a Zendesk Messenger on that URL. Try a different URL, or try the manual testing setup",
      );
    }
  }

  get testingURL() {
    let prefix = 'https://standalone.intercom.com';

    if (ENV.environment === 'development') {
      prefix = '';
    }

    return `${prefix}/standalone/zendesk/preview_messenger/${this.zendeskMessagingKey}`;
  }

  @action previewZendeskMessaging() {
    OpenCenteredWindow(this.testingURL, 700, 380, 'Fin on Zendesk Messaging');
  }

  get zendeskConfig(): ZendeskConfiguration {
    return this.finStandaloneService.zendeskConfig;
  }

  @action resetURL() {
    this.urlWithMessagingKey = '';
    this.zendeskMessagingKey = '';
  }

  @action enterManualMode() {
    this.manualMode = true;
    this.urlWithMessagingKey = '';
    this.zendeskMessagingKey = '';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Zendesk::Setup::SunshineTesting': typeof StandaloneZendeskSetupSunshineTesting;
    'standalone/zendesk/setup/sunshine-testing': typeof StandaloneZendeskSetupSunshineTesting;
  }
}
