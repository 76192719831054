/* import __COLOCATED_TEMPLATE__ from './domain.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
import templateOnly from '@ember/component/template-only';
import type { PulseAccordion } from 'glint/pulse';
interface Signature {
  Args: {
    domainSettings: any;
    sectionIndex: number;
    accordion: PulseAccordion;
  };
}

const StandaloneSalesforceSetupEmailDomain = templateOnly<Signature>();
export default StandaloneSalesforceSetupEmailDomain;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Salesforce::Setup::EmailDomainVerification::Domain': typeof StandaloneSalesforceSetupEmailDomain;
    'standalone/salesforce/setup/email-domain-verification/domain': typeof StandaloneSalesforceSetupEmailDomain;
  }
}
