/* import __COLOCATED_TEMPLATE__ from './keyword-reply-title.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { stats } from 'embercom/models/data/stats-system/stats-constants';
import { keywordTypes } from 'embercom/models/data/sms/constants';

export default class KeywordResponseTitle extends Component {
  get filterItems() {
    let noFilter = [{ text: 'Replied', value: '' }];
    let responseFilters = Object.keys(keywordTypes).map((keywordType) => {
      return {
        text: `Replied with ${keywordTypes[keywordType].toLowerCase()}`,
        value: keywordType,
      };
    });
    return [...noFilter, ...responseFilters];
  }

  get selectedFilterValue() {
    return Object.keys(keywordTypes).includes(this.args.filter) ? this.args.filter : '';
  }

  @action
  changeFilter(filter) {
    this.args.changeView(stats.keywordReply, filter);
  }
}
