/* import __COLOCATED_TEMPLATE__ from './dropdown.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class Dropdown extends Component {
  @tracked currentWidth = 0;

  get popoverContentClass() {
    return this.args.question.data.options.length > 10 ? 'h-64 overflow-scroll' : '';
  }

  @action onShow() {
    let selectTrigger = document.getElementsByClassName('js-dropdown-trigger');
    let selectTriggerWidth = selectTrigger[0]
      ? selectTrigger[0].getBoundingClientRect().width.toFixed()
      : 0;

    this.currentWidth = selectTriggerWidth;
  }
}
