/* import __COLOCATED_TEMPLATE__ from './submenu-sections-section.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { readOnly } from '@ember/object/computed';
import Component from '@ember/component';
import defaultTo from '@intercom/pulse/lib/default-to';

export default Component.extend({
  classNames: ['submenu__sections__section', 'min-h-0'],
  classNameBindings: [
    'collapseSectionRightPadding:u__padr__0',
    'isOpen:flex-initial:flex-none',
    'isOpen:flex',
    'isOpen:flex-col',
  ],
  collapseSectionRightPadding: readOnly('isScrollableList'),
  isScrollableList: defaultTo(false),
});
