/* import __COLOCATED_TEMPLATE__ from './answer-stat-graph.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import Range from 'embercom/models/reporting/range';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import { findTickInverval } from 'embercom/lib/reporting/survey-helpers';

const ANSWER_STATS_SIGNAL_CONFIG = {
  name: 'default_query',
  debug_name: 'answer_stat',
  document_type: 'events',
  range_field: 'event.created_at',
  aggregation_type: 'value_count',
  aggregation_field: 'stat_answer.response.raw_response',
};

export default class AnswerStatGraph extends Component {
  @service appService;
  @service reportingService;
  @service contentEditorService;
  @tracked answerStats = {};
  @tracked answerStatsData = [];
  @tracked answerStatsKeys = [];
  @tracked totalAnswersCount;

  constructor() {
    super(...arguments);
    this.updateAnswerStats.perform();
  }

  get app() {
    return this.appService.app;
  }

  get statsParameters() {
    return this.args.statsParameters;
  }

  get defaultFilters() {
    return {
      'event.type': 'stats_answer',
    };
  }

  get savedAttribute() {
    return this.filteredQuestion.attributeIdentifier.split('.').lastObject;
  }

  get chartOptions() {
    return {
      title: {
        text: null,
      },
      legend: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          dataLabels: {
            enabled: true,
          },
          color: '#334BFA',
        },
      },
      chart: {
        type: 'bar',
      },
      xAxis: {
        categories: this.answerStatsKeys,
        title: {
          text: null,
        },
      },
      yAxis: {
        title: {
          text: null,
        },
        tickInterval: this.tickIntervalCount,
      },
      credits: {
        enabled: false,
      },
    };
  }

  get content() {
    return [
      {
        name: 'Number of responses with this value',
        data: this.answerStatsData,
      },
    ];
  }

  get filteredQuestion() {
    return this.args.filteredQuestion;
  }

  get showHorizontalHistogram() {
    return !(this.filteredQuestion?.isFreeTextEntry || this.filteredQuestion?.isFormInput);
  }

  get range() {
    return Range.createFromParams(
      new Date(this.args.statsParameters.startDate),
      new Date(),
      this.app.timezone,
    );
  }

  get contentFilters() {
    return {
      [`${this.statsParameters.shortObjectName}.id`]: this.statsParameters.contentId,
    };
  }

  get averageAnswer() {
    let avg;
    if (this.filteredQuestion.isRatingScale) {
      let answerSumArray = Object.values(this.answerStats).map((value) => value.sum);
      let totalSumOfAnswers = answerSumArray.reduce((a, b) => a + b, 0);
      avg = (totalSumOfAnswers / this.totalAnswersCount).toFixed(2);
    }

    return avg;
  }

  get tickIntervalCount() {
    let biggestAnswerCount = Math.max(
      ...Object.values(this.answerStats).map((value) => value.count),
    );

    return findTickInverval(biggestAnswerCount);
  }

  @task
  *updateAnswerStats() {
    let stats = yield this.fetchReportingServiceStats.perform();
    this.answerStats = stats;
    this.answerStatsKeys = Object.keys(this.answerStats);
    this.answerStatsData = Object.values(this.answerStats).map((value) => value.count);
  }

  @task
  *fetchReportingServiceStats() {
    let aggregations = [{ grouping: 'stat_answer.response.raw_response', interval: 1 }];
    let answerData = yield this.reportingService.fetchNumericSignal(
      ANSWER_STATS_SIGNAL_CONFIG,
      'views',
      this.range,
      aggregations,
      {
        ...this.contentFilters,
        ...this.args.additionalFilters,
        ...this.defaultFilters,
      },
    );

    let stats = {};
    this.totalAnswersCount = answerData.value;
    answerData.context.forEach(({ key, value }) => {
      stats[key] = { count: value, percentage: (100 * value) / this.totalAnswersCount };
      if (this.filteredQuestion.isRatingScale) {
        stats[key].sum = Number(key) * value;
      }
    });

    return stats;
  }
}
