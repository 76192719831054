/* import __COLOCATED_TEMPLATE__ from './last-seen-at-cell.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class LastSeenAtCell extends Component {
  @service intl;

  @tracked lastActive = this.args.lastActive ? this.args.lastActive * 1000 : null;

  get tooltipContent() {
    let lastActiveDate = this.lastActive ? new Date(this.lastActive) : null;
    return lastActiveDate instanceof Date && !isNaN(lastActiveDate)
      ? this.intl.formatDate(this.lastActive, {
          day: 'numeric',
          month: 'short',
          year: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
        })
      : this.intl.t('table-cells.teammate-list.last-seen-at-cell.invalid-date');
  }
}
