/* import __COLOCATED_TEMPLATE__ from './viewed-title.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { stats } from 'embercom/models/data/stats-system/stats-constants';

export default class ViwedTitle extends Component {
  @service appService;
  @service contentEditorService;
  @service intl;

  get app() {
    return this.appService.app;
  }

  get checklist() {
    return this.contentEditorService.activeObject;
  }

  get filterItems() {
    return [
      { text: this.intl.t('components.stats-system.list.checklist.all_steps'), value: 0 },
    ].concat(
      this.checklist.tasks.map((task, index) => ({
        text: this.intl.t('components.stats-system.list.checklist.step_number', {
          index: index + 1,
        }),
        value: Number(task.id),
      })),
    );
  }

  get selectedFilterValue() {
    return Number(this.args.filter) || 0;
  }

  @action
  changeFilter(filter) {
    this.args.changeView(stats.open, filter);
  }
}
