/* import __COLOCATED_TEMPLATE__ from './section-title.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    header: string;
    subheader?: string;
  };
}

const StandaloneSectionTitle = templateOnlyComponent<Signature>();
export default StandaloneSectionTitle;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Setup::SectionTitle': typeof StandaloneSectionTitle;
  }
}
