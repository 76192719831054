/* RESPONSIBLE TEAM: unused-components */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable @intercom/intercom/no-component-inheritance */
/* eslint-disable ember/no-observers */
/* eslint-disable ember/no-on-calls-in-components */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-jquery */
import { observer } from '@ember/object';
import $ from 'jquery';
import { schedule } from '@ember/runloop';
import { on } from '@ember/object/evented';
import { TextArea } from '@ember/legacy-built-in-components';
export default TextArea.extend({
  maxHeight: 99999,
  isDisabled: false,
  textInitiator: on('init', function () {
    schedule('afterRender', this, this.resizeTextArea);
  }),
  resizeTextArea() {
    let borderTop = parseFloat($(this.element).css('borderTopWidth'));
    let borderBottom = parseFloat($(this.element).css('borderBottomWidth'));
    let newHeight = this.element.scrollHeight + borderTop + borderBottom;
    let currentOuterHeight = $(this.element).outerHeight();
    if (currentOuterHeight < this.maxHeight && currentOuterHeight < newHeight) {
      $(this.element).height(newHeight);
    }
  },
  triggerResize: observer({
    dependentKeys: ['forceResizeTextArea'],

    fn() {
      if (this.forceResizeTextArea !== undefined) {
        schedule('afterRender', this, this.resizeTextArea);
      }
    },

    sync: true,
  }),
  handleKeyDown: on('keyDown', function () {
    this.resizeTextArea();
  }),
  actions: {
    forceResizeTextArea() {
      this.resizeTextArea();
    },
  },
});
