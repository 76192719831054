/* import __COLOCATED_TEMPLATE__ from './submenu-header.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { inject as service } from '@ember/service';
import { and, match, none, not, readOnly } from '@ember/object/computed';
import Component from '@ember/component';
import defaultTo from '@intercom/pulse/lib/default-to';
import RouteRegexes from 'embercom/lib/route-regexes';

const SHORTCUT_GROUP = 'submenu';

export default Component.extend({
  classNames: ['submenu__header', 'flex-none', 'flex', 'items-center'],
  searchIsSelected: defaultTo(false),
  searchIsDisabled: defaultTo(false),
  shouldNotShowSearchIcon: none('searchAction'),
  shouldShowSearchIcon: not('shouldNotShowSearchIcon'),
  attributeBindings: ['data-test-submenu-header'],
  'data-test-submenu-header': true,
  shortcuts: service(),
  appService: service(),
  app: readOnly('appService.app'),
  canAccessInbox: and('app.inboxIsActive', 'app.currentAdminHasInboxAccess'),
  router: service(),
  isOnInboxProductRoute: match('router.currentRouteName', RouteRegexes.respond),
  notificationsService: service(),

  didInsertElement() {
    this._super(...arguments);
    if (this.shouldNotShowSearchIcon) {
      return;
    }
    this.shortcuts.register(SHORTCUT_GROUP, this, [
      { key: '/', modalActive: false, method: this.searchShortcutPressed },
    ]);
  },

  willDestroyElement() {
    this._super(...arguments);
    this.shortcuts.unregister(SHORTCUT_GROUP);
  },

  searchShortcutPressed() {
    if (this.shouldNotShowSearchIcon || this.searchIsSelected || this.searchIsDisabled) {
      return;
    }
    this.searchAction();
  },
});
