/* import __COLOCATED_TEMPLATE__ from './cases.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
/* eslint-disable @intercom/intercom/no-bare-strings */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import { inject as service } from '@ember/service';

interface Args {}

export default class StandaloneSalesforceSetupCases extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;

  get salesforceConfig() {
    return this.finStandaloneService.salesforceConfig;
  }

  @tracked openSectionId?: string;

  @action setOpenSectionId(id: string | undefined) {
    this.openSectionId = id;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Salesforce::Setup::Cases': typeof StandaloneSalesforceSetupCases;
    'standalone/salesforce/setup/cases': typeof StandaloneSalesforceSetupCases;
  }
}
