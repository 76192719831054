/* import __COLOCATED_TEMPLATE__ from './receipt-list.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';

export default class ReceiptList extends Component {
  get noun() {
    return this.args.overrideNoun || 'view';
  }

  get verb() {
    return this.args.overrideVerb || 'viewed';
  }

  get emptyStateDescription() {
    return (
      this.args.overrideEmptyStateDescription ||
      `As people ${this.noun} your ${this.args.statsParameters.uiObjectName}, we'll show you how and when they ${this.verb} it.`
    );
  }
}
