/* import __COLOCATED_TEMPLATE__ from './reached-title.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { stats } from 'embercom/models/data/stats-system/stats-constants';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import { getTooltipFilterLabel } from './title-helpers';

export default class ReachedTitle extends Component {
  @service appService;
  @service contentEditorService;

  get app() {
    return this.appService.app;
  }

  get tooltipGroup() {
    return this.contentEditorService.activeObject;
  }

  get isFilteringByTooltip() {
    return this.args.statsParameters.contentType === objectTypes.tooltip;
  }

  get activeFilterTooltip() {
    return this.args.statsParameters.contentObject;
  }

  get filterItems() {
    return [{ text: 'All tooltips in the group', value: 0 }].concat(
      this.tooltipGroup.tooltips.map((tooltip, index) => ({
        text: getTooltipFilterLabel(tooltip, index),
        value: Number(tooltip.id),
      })),
    );
  }

  get selectedFilterValue() {
    return Number(this.args.filter) || 0;
  }

  @action
  changeFilter(filter) {
    this.args.changeView(stats.receipt, filter);
  }
}
