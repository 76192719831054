/* import __COLOCATED_TEMPLATE__ from './workflow.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import templateOnly from '@ember/component/template-only';

export interface WorkflowArgs {
  workflowId: string | number;
  route: string;
  title: string;
}

let Workflow = templateOnly<WorkflowArgs>();

export default Workflow;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Zendesk::Setup::Workflow': typeof Workflow;
  }
}
