/* import __COLOCATED_TEMPLATE__ from './submenu-sections-section-items.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import defaultTo from '@intercom/pulse/lib/default-to';

export default Component.extend({
  classNames: ['submenu__sections__section__items', 'flex-initial', 'overflow-auto'],
  isOpen: defaultTo(false),
  classNameBindings: [
    'isInboxList:js__respond-inbox-list',
    'isUserSegmentList:js__user-segment-list',
    'isCompanySegmentList:js__company-segment-list',
    'collapseSectionLeftPadding:u__padl__10',
    'collapseSectionRightPadding:o__section-padding-right',
  ],
});
