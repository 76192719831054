/* import __COLOCATED_TEMPLATE__ from './add-knowledge.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import {
  TEXT_CONTENT,
  FILE_CONTENT,
  EXTERNAL_CONTENT,
  ZENDESK_ARTICLE_SYNC_CARD,
} from 'embercom/lib/ai-content-library/constants';
import { taskFor } from 'ember-concurrency-ts';
import { dropTask } from 'ember-concurrency-decorators';
import type Store from '@ember-data/store';
import { type TaskGenerator } from 'ember-concurrency';

interface AddKnowledgeArgs {
  onClose: () => void;
  reloadSources: () => void;
}

export default class AddKnowledge extends Component<AddKnowledgeArgs> {
  @service declare appService: $TSFixMe;
  @service declare helpCenterService: $TSFixMe;
  @service declare store: Store;
  @service declare permissionsService: any;

  @tracked showFinContentModal = true;
  @tracked showUrlSourceModal = false;
  @tracked showFileUploadModal = false;
  @tracked showZendeskArticleSyncModal = false;
  @tracked showContentSnippetSideDrawer = false;
  @tracked contentSnippet: any;

  constructor(owner: unknown, args: AddKnowledgeArgs) {
    super(owner, args);
    this.helpCenterService.maybeFetchSite();
  }

  get templates() {
    return [ZENDESK_ARTICLE_SYNC_CARD, EXTERNAL_CONTENT, FILE_CONTENT, TEXT_CONTENT];
  }

  @action
  openUrlSourceModal() {
    this.showFinContentModal = false;
    this.showUrlSourceModal = true;
  }

  @action
  openFileUploadModal() {
    this.showFinContentModal = false;
    this.showFileUploadModal = true;
  }

  @action
  openZendeskArticleSyncModal() {
    this.showFinContentModal = false;
    this.showZendeskArticleSyncModal = true;
  }

  @action
  async openContentSnippetSideDrawer() {
    taskFor(this.createContentSnippet).perform();
    this.showFinContentModal = false;
    this.showContentSnippetSideDrawer = true;
  }

  @dropTask
  *createContentSnippet(): TaskGenerator<void> {
    this.contentSnippet = yield this.store.createRecord('content-service/content-snippet').save();
  }

  @action
  handleModalBackBtnClick() {
    this.showUrlSourceModal = false;
    this.showFileUploadModal = false;
    this.showZendeskArticleSyncModal = false;
    this.showContentSnippetSideDrawer = false;
    this.showFinContentModal = true;
  }

  @action
  closeContentSnippetDrawer() {
    this.showContentSnippetSideDrawer = false;
    this.args.reloadSources();
    this.args.onClose();
  }

  @action
  handleModalClose() {
    this.args.reloadSources();
    this.args.onClose();
  }

  get isLoadingContentSnippet(): boolean {
    return taskFor(this.createContentSnippet).isRunning;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Channels::LibrarySummary::AddKnowledge': typeof AddKnowledge;
    'standalone/channels/library-summary/add-knowledge': typeof AddKnowledge;
  }
}
