/* import __COLOCATED_TEMPLATE__ from './send-recovery-code-cell.hbs'; */
/* RESPONSIBLE TEAM: team-app-security */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class SendRecoveryCodeCell extends Component {
  @service intercomEventService;
  @service notificationsService;
  @service appService;
  @service permissionsService;
  @service intl;

  get canSendRecoveryCode() {
    return (
      this.permissionsService.currentAdminCan('can_manage_teammates') &&
      this.admin !== this.appService.app.currentAdmin &&
      this.admin.has_enabled_2fa
    );
  }

  get admin() {
    return this.args.row.teammate;
  }

  @action
  async sendRecoveryCode() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'send_2_fa_recovery_codes',
      object: 'email_recovery_code',
      context: 'from_teammate_settings',
      models: [this.admin],
    });

    try {
      await this.admin.sendRecoveryCodeEmail();
      this.notificationsService.notifyConfirmation(
        this.intl.t('table-cells.teammate-list.send-recovery-codes-cell.codes-sent', {
          email: this.admin.email,
        }),
      );
    } catch (err) {
      this.notificationsService.notifyError(
        this.intl.t('table-cells.teammate-list.send-recovery-codes-cell.sending-failure'),
      );
    }
  }
}
