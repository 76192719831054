/* import __COLOCATED_TEMPLATE__ from './summary.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { generateShareableUrl } from 'embercom/models/surveys/survey';
import { isPresent } from '@ember/utils';

export default class SurveySharingOptionsSummary extends Component {
  @service store;
  @service attributeService;

  get ruleset() {
    return this.args.ruleset;
  }

  get rulesetId() {
    return this.ruleset.id;
  }

  get hasShareableUrl() {
    return isPresent(this.ruleset?.clientData?.shareableUrl);
  }

  get generatedShareableUrl() {
    let url = this.ruleset.clientData.shareableUrl;
    return generateShareableUrl(url, this.rulesetId);
  }

  get shareableUrlEmptyState() {
    if (this.args.isViewMode) {
      return 'Start editing to generate sharing link';
    }

    return 'Expand to generate sharing link';
  }

  get triggerFromCodeEmptyState() {
    if (this.args.isViewMode) {
      return 'Trigger from code';
    }

    return 'Expand to get code';
  }
}
