/* import __COLOCATED_TEMPLATE__ from './basket-header.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  header: string;
  subHeader: string;
}

interface Signature {
  Element: any;
  Args: Args;
  Blocks: {
    default: any;
  };
}

const BasketHeader = templateOnlyComponent<Signature>();
export default BasketHeader;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Signup::Teams::Pricing5::Components::BasketHeader': typeof BasketHeader;
    'signup/teams/pricing5/components/basket-header': typeof BasketHeader;
  }
}
