/* import __COLOCATED_TEMPLATE__ from './setup.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { isPresent } from '@ember/utils';
import type Session from 'embercom/services/session';
import type Configuration from 'embercom/models/side-by-side/handover/configuration';
import type Store from '@ember-data/store';

interface Signature {
  Args: SetupArgs;
}
interface SetupArgs {
  onRoutingEnabled?: () => void;
  location?: 'wizard' | 'onboarding-home';
}

export default class Setup extends Component<Signature> {
  @service declare session: Session;
  @service declare store: Store;

  @tracked configuration: Configuration | null = null;
  @tracked showSwitchRouteModal = false;
  @tracked supportTools = [{ text: 'Zendesk', value: 'zendesk' }];

  constructor(owner: unknown, args: SetupArgs) {
    super(owner, args);
    taskFor(this.loadConfiguration).perform();
  }

  get isLocationOnboardingHome(): boolean {
    return this.args.location === 'onboarding-home';
  }

  get isLocationWizard(): boolean {
    return this.args.location === 'wizard';
  }

  get activeToolString() {
    if (this.configuration) {
      return this.supportTools.find((tool) => tool.value === this.configuration?.activeTool)?.text;
    }
    return '';
  }

  get isAuthenticated() {
    if (!this.configuration) {
      return false;
    }

    switch (this.configuration.activeTool) {
      case 'zendesk':
        return isPresent(this.configuration.zendeskConfiguration?.subdomain);
      default:
        return false;
    }
  }

  get isConfigured() {
    if (!this.configuration) {
      return false;
    }

    switch (this.configuration.activeTool) {
      case 'zendesk':
        return isPresent(this.configuration.zendeskConfiguration?.zendeskForm);
      default:
        return false;
    }
  }

  @task({ restartable: true })
  *loadConfiguration() {
    this.configuration = yield this.store.findRecord(
      'side-by-side/handover/configuration',
      this.session.workspace.id,
    );
  }

  @action
  selectSupportTool(tool: string) {
    if (this.configuration !== null) {
      this.configuration.activeTool = tool;
    }
  }

  @action
  closeModal() {
    this.showSwitchRouteModal = false;
  }

  @action
  editRouting() {
    this.showSwitchRouteModal = true;
  }

  @action
  switchRouting() {
    if (this.configuration !== null) {
      this.configuration.zendeskConfiguration = null;
    }
  }

  @action
  async turnOnRouting() {
    try {
      if (this.configuration !== null) {
        this.configuration.save();
        if (this.args.onRoutingEnabled) {
          this.args.onRoutingEnabled();
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  @action
  initializeZendeskConfig() {
    if (this.configuration !== null) {
      this.configuration.zendeskConfiguration = this.store.createRecord(
        'side-by-side/handover/zendesk/configuration',
        {},
      );
      this.configuration.activeTool = 'zendesk';
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'SideBySide::Setup': typeof Setup;
    'side-by-side/setup': typeof Setup;
  }
}
