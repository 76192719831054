/* import __COLOCATED_TEMPLATE__ from './goal-success-list.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { stats } from 'embercom/models/data/stats-system/stats-constants';
import { createPercentBucketsForNestedKeys } from 'embercom/lib/reporting/signal-transformations';
import { capitalize } from '@ember/string';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class GoalSuccessList extends Component {
  @service intercomEventService;

  get existsFieldsFilters() {
    if (this.args.filter && this.args.filter !== stats.receipt) {
      return { exists_fields: [`stat_${this.args.filter}.id`] };
    }
  }

  get queryKeys() {
    let filter = this.args.filter || stats.receipt;
    return [`stats_${filter}`, `stats_${stats.goalSuccess}`];
  }

  get transforms() {
    return [createPercentBucketsForNestedKeys(`stats_${stats.goalSuccess}`, this.queryKeys)];
  }

  get goal() {
    return this.args.statsParameters.goal;
  }

  get goalAttributeDescription() {
    let attribute = this.goal.predicateGroup.editablePredicates.firstObject.descriptionPrefix;
    if (this.goal.hasCompanyPredicate) {
      attribute = attribute.split('.').join(' ');
    }
    return attribute;
  }

  get columns() {
    let columns = [];
    if (this.goal.hasCompanyPredicate) {
      columns.push(
        { label: 'Company', valuePath: 'successfulCompany.name' },
        { label: `When it was hit`, valuePath: 'createdAt' },
      );
    } else {
      columns.push(
        { label: 'Name', valuePath: 'user.displayAs', type: 'avatar-with-text' },
        { label: `When it was hit`, valuePath: 'createdAt' },
      );
    }

    // We only want to show the user goal attribute if the goal relates to an attribute, not if it
    // relates to a page view.
    if (!this.goal.hasClientPredicate) {
      columns.push({
        label: capitalize(this.goalAttributeDescription),
        valuePath: 'userGoalAttributes',
      });
    }

    return columns;
  }
}
