/* import __COLOCATED_TEMPLATE__ from './workflow-outlet-wrapper.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';

interface Signature {
  Args: {
    workflowRouteName: string;
    loadingRouteName: string;
  };
  Blocks: {
    default: [];
  };
}

export default class WorkflowOutletWrapper extends Component<Signature> {
  @service declare router: RouterService;

  get shouldShowOutlet(): boolean {
    let currentRouteName = this.router.currentRouteName;
    return (
      currentRouteName === this.args.workflowRouteName ||
      currentRouteName === this.args.loadingRouteName
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Common::WorkflowOutletWrapper': typeof WorkflowOutletWrapper;
  }
}
