/* import __COLOCATED_TEMPLATE__ from './numeric-scale.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import { emojiScaleOptions } from 'embercom/models/data/survey/constants';
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class NumericScale extends Component {
  emojiScaleRanges = [
    { text: '3', value: 3 },
    { text: '5', value: 5 },
  ];

  get question() {
    return this.args.question;
  }

  @action onSelectEmojiScaleRange(scaleEnd) {
    this.question.data.scaleEnd = scaleEnd;
    this.question.data.options = emojiScaleOptions[scaleEnd];
  }
}
