/* import __COLOCATED_TEMPLATE__ from './confirm-button.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import templateOnlyComponent from '@ember/component/template-only';
import { type TaskGenerator } from 'ember-concurrency';

interface Signature {
  Args: {
    buttonLabel: string;
    confirmPayment: () => TaskGenerator<void>;
  };
}

const ConfirmButton = templateOnlyComponent<Signature>();
export default ConfirmButton;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Stripe::ConfirmButton': typeof ConfirmButton;
    'stripe/confirm-button': typeof ConfirmButton;
  }
}
