/* import __COLOCATED_TEMPLATE__ from './description-cell.hbs'; */
/* RESPONSIBLE TEAM: team-app-security */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import AppAdminEventsHelper from 'embercom/lib/admins/app-admin-events-helper';
import { getOwner } from '@ember/application';
import type AppAdminEvent from 'embercom/models/app-admin-event';

interface DescriptionCellArgs {
  column?: {
    widthStyle: string;
  };
  row:
    | {
        event?: AppAdminEvent;
      }
    | AppAdminEvent;
}

export default class DescriptionCell extends Component<DescriptionCellArgs> {
  @service declare appService: any;

  get event(): AppAdminEvent {
    return 'event' in this.args.row ? this.args.row.event! : (this.args.row as AppAdminEvent);
  }

  get app() {
    return this.appService.app;
  }

  get cellText(): string {
    let helper = new AppAdminEventsHelper(getOwner(this));
    return helper.descriptionHTML(this.event);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'TableCells::AdminEvents::DescriptionCell': typeof DescriptionCell;
  }
}
