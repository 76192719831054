/* import __COLOCATED_TEMPLATE__ from './trend-graph.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { computed } from '@ember/object';
import Range from 'embercom/models/reporting/range';
import { readOnly } from '@ember/object/computed';
import { statVisualizationColors } from 'embercom/models/data/stats-system/stats-constants';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';

export default Component.extend({
  appService: service(),
  tagName: '',

  query: computed('aggregationType', 'aggregationField', function () {
    return {
      name: 'default_query',
      document_type: 'events',
      range_field: this.rangeField,
      aggregation_type: this.aggregationType || 'value_count',
      aggregation_field: this.aggregationField || 'event.type',
      nested_keys_are_dates: false,
    };
  }),

  rangeField: computed('statsParameters.rangeField', function () {
    return this.statsParameters.rangeField || 'stat_receipt.created_at';
  }),

  statName: computed('statistic', function () {
    return `stats_${this.statistic}`;
  }),

  keys: computed('statName', function () {
    return [this.statName];
  }),

  tooltips: computed('statName', 'verb', function () {
    return {
      [this.statName]: this.verb,
    };
  }),

  keyColorHash: computed('statName', 'statistic', function () {
    return {
      [this.statName]: statVisualizationColors[this.statistic] || 'vis-blue-40',
    };
  }),

  groupings: [
    {
      grouping: 'event.type',
      interval: 1,
    },
  ],

  contentFilters: computed('statsParameters.{shortObjectName,contentId}', function () {
    return {
      [`${this.statsParameters.shortObjectName}.id`]: this.statsParameters.contentId,
    };
  }),

  eventFilters: computed('queryKeys', 'statName', function () {
    let queryKeys = this.queryKeys || [this.statName];
    return {
      'event.type': queryKeys,
    };
  }),

  // this is a hack: it allows the reaction trend graphs to work for everything except updated reactions
  // more info here: https://github.com/intercom/intercom/issues/179689#issuecomment-676046546
  additionalFiltersWithReaction: computed('additionalFilters', function () {
    let additionalFilters = Object.assign({}, this.additionalFilters);
    let filterReaction = additionalFilters['current.stat_reaction.reaction'];
    if (filterReaction) {
      additionalFilters['reaction.id'] = filterReaction;
      delete additionalFilters['current.stat_reaction.reaction'];
    }
    return additionalFilters;
  }),

  localizedContentIdFilters: computed('statsParameters.localizedContentId', function () {
    if (this.statsParameters.localizedContentId) {
      return {
        'stat_receipt.localized_content_id': this.statsParameters.localizedContentId,
      };
    }
  }),

  helpCenterIdFilters: computed('statName', 'statsParameters.helpCenterId', function () {
    if (this.statName === 'stats_article_content_view' && this.statsParameters.helpCenterId) {
      return {
        'article_stat_metadata.help_center_id': this.statsParameters.helpCenterId,
      };
    }
  }),

  targetChannelFilters: computed('statsParameters.targetChannels', function () {
    if (this.statsParameters.targetChannels?.length > 0) {
      return {
        'custom_bot_conversation_state.channel': this.statsParameters.targetChannels,
      };
    }
  }),

  filters: computed(
    'contentFilters',
    'eventFilters',
    'existsFieldsFilters',
    'additionalFiltersWithReaction',
    'localizedContentIdFilters',
    'helpCenterIdFilters',
    'targetChannelFilters',
    function () {
      return {
        ...this.contentFilters,
        ...this.eventFilters,
        ...this.existsFieldsFilters,
        ...this.additionalFiltersWithReaction,
        ...this.localizedContentIdFilters,
        ...this.helpCenterIdFilters,
        ...this.targetChannelFilters,
      };
    },
  ),

  range: computed('statsParameters', '_timezone', function () {
    if (isPresent(this.statsParameters.range)) {
      return this.statsParameters.range;
    }
    return Range.createFromParams(
      new Date(this.statsParameters.startDate),
      new Date(),
      this._timezone,
    );
  }),

  _timezone: readOnly('appService.app.timezone'),

  transforms: [],
});
