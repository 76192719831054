/* import __COLOCATED_TEMPLATE__ from './permission-change-details.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import Component from '@glimmer/component';

export default class PermissionChangeDetails extends Component {
  @service appService;
  @service intl;

  get modalHeader() {
    return this.appService.app.hasMultipleSeatTypes
      ? this.intl.t('table-cells.admin-events.permission-change-details.seats-and-permissions')
      : this.intl.t('table-cells.admin-events.permission-change-details.permissions');
  }

  get hasSeats() {
    return (
      this.appService.app.hasMultipleSeatTypes &&
      (isPresent(this.args.event.value.seat_types) ||
        isPresent(this.args.event.value.before?.seat_types) ||
        isPresent(this.args.event.value.after?.seat_types))
    );
  }
}
