/* import __COLOCATED_TEMPLATE__ from './add-domain.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isValidEmail } from 'embercom/lib/email';
import type CustomIntlService from 'embercom/services/intl';
import type Store from '@ember-data/store';
import { dropTask } from 'ember-concurrency-decorators';
import type { TaskGenerator } from 'ember-concurrency';
import ajax from 'embercom/lib/ajax';
import type { PulseAccordion } from 'glint/pulse';
import { taskFor } from 'ember-concurrency-ts';

interface Args {
  accordion: PulseAccordion;
}

export default class StandaloneSalesforceSetupAddDomain extends Component<Args> {
  @service declare intl: CustomIntlService;
  @service declare notificationsService: $TSFixMe;
  @service declare store: Store;
  @service declare appService: $TSFixMe;

  @tracked emailAddress = '';
  @tracked displayName = '';

  get isValidEmail() {
    return isValidEmail(this.emailAddress);
  }

  get saveButtonDisabled() {
    return (
      !this.isValidEmail ||
      taskFor(this.createAddressAndDkimSettings).isRunning ||
      !this.displayName
    );
  }

  @action
  updateEmailAddress(event: InputEvent) {
    this.emailAddress = (event.target as HTMLInputElement).value;
  }

  @action
  updateDisplayName(event: InputEvent) {
    this.displayName = (event.target as HTMLInputElement).value;
  }

  @dropTask
  *createAddressAndDkimSettings(): TaskGenerator<void> {
    try {
      let response = yield ajax({
        url: '/ember/sender_emails/create_address_and_dkim_settings',
        type: 'POST',
        data: JSON.stringify({
          email: this.emailAddress,
          app_id: this.appService.app.id,
          admin_id: this.appService.app.currentAdmin.id,
          name: this.displayName,
        }),
      });

      // Push the response data into the store
      this.store.push(this.store.normalize('dkim-settings', response.dkim_settings));
      this.store.pushPayload({
        'custom-bounce-settings': response.custom_bounce_settings,
        'custom-email-address': response.sender_address,
      });

      this.notificationsService.notifyConfirmation(
        this.intl.t(
          'standalone.salesforce.setup.email-domain-verification.domain-added-successfully',
        ),
      );

      this.emailAddress = '';
      this.displayName = '';
    } catch (error) {
      if (error.jqXHR?.responseJSON?.errors) {
        this.notificationsService.notifyError(error.jqXHR.responseJSON.errors[0].message);
      } else {
        let message = this.intl.t(
          'standalone.salesforce.setup.email-domain-verification.failed-to-add-domain',
        );
        this.notificationsService.notifyError(message);
      }
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Salesforce::Setup::EmailDomainVerification::AddDomain': typeof StandaloneSalesforceSetupAddDomain;
  }
}
