/* import __COLOCATED_TEMPLATE__ from './fin-user-selection.hbs'; */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type SalesforceConfiguration from 'embercom/models/standalone/salesforce-configuration';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

interface Args {}

export default class StandaloneSetupSalesforceFinUserSelection extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare notificationsService: any;

  @tracked isSelectingUser = false;
  @tracked isSelectingHandover = false;

  get salesforceConfig(): SalesforceConfiguration {
    return this.finStandaloneService.salesforceConfig;
  }

  get salesforceSetupData() {
    return this.finStandaloneService.salesforceSetupData;
  }

  get isLoading() {
    return this.finStandaloneService.isLoadingSalesforceSetupData && !this.adminOptions.length;
  }

  get adminOptions() {
    let admins = this.salesforceSetupData.admins?.map((admin) => ({
      text: admin.name,
      value: admin.id,
    }));
    return admins || [];
  }

  get handoverOptions() {
    let admins = this.salesforceSetupData.admins?.map((admin) => ({
      text: admin.name,
      value: admin.id,
    }));
    let queues = this.salesforceSetupData.queues?.map((queue) => ({
      text: queue.name,
      value: queue.id,
    }));

    let options = [];

    if (queues) {
      options.push({
        heading: 'Queues',
        items: queues,
      });
    }

    if (admins) {
      options.push({
        heading: 'Admins',
        items: admins,
      });
    }

    return options;
  }

  // Pulse treats null as a real value and errors if there isn't an associated selection
  // This returns undefined in the case where there is no selection
  get selectedAgentId(): string | undefined {
    if (this.salesforceConfig.finSalesforceUserId) {
      return this.salesforceConfig.finSalesforceUserId;
    }
    return undefined;
  }

  get selectedHandoverId(): string | undefined {
    if (this.salesforceConfig.finSalesforceHandoverId) {
      return this.salesforceConfig.finSalesforceHandoverId;
    }
    return undefined;
  }

  @action async updateAgentId(agentId: string) {
    try {
      this.isSelectingUser = true;
      await this.salesforceConfig.setFinUser(agentId);
    } catch (e) {
      this.notificationsService.notifyResponseError(e, {
        default: `Something went wrong and we couldn't set your Fin user. Please try again`,
      });
      console.error(e);
    } finally {
      this.isSelectingUser = false;
    }
  }

  @action async updateHandoverId(handoverId: string) {
    try {
      this.isSelectingHandover = true;
      await this.salesforceConfig.setHandoverId(handoverId);
    } catch (e) {
      this.notificationsService.notifyResponseError(e, {
        default: `Something went wrong and we couldn't set your Fin handover user. Please try again`,
      });
      console.error(e);
    } finally {
      this.isSelectingHandover = false;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Setup::Salesforce::FinUserSelection': typeof StandaloneSetupSalesforceFinUserSelection;
    'standalone/setup/salesforce/fin-user-selection': typeof StandaloneSetupSalesforceFinUserSelection;
  }
}
