/* import __COLOCATED_TEMPLATE__ from './name-cell.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-component-inheritance */
import { readOnly } from '@ember/object/computed';
import InviteBaseCell from 'embercom/components/table-cells/invite-list/base-cell';
export default InviteBaseCell.extend({
  attributeBindings: ['data-test-invite-email'],
  'data-test-invite-email': readOnly('inviteeEmail'),
});
