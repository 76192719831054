/* import __COLOCATED_TEMPLATE__ from './plan-usage-metrics.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import {
  PRICING_5_1_EARLY_STAGE_FREE_PRICING_MODEL,
  PRICING_5_1_EARLY_STAGE_PRICING_MODEL,
} from 'embercom/lib/billing';

interface Args {
  usageMetrics: string[];
  shouldShowCart: boolean;
  earlyStagePartner: boolean;
}

export default class PlanUsageMetrics extends Component<Args> {
  @service declare appService: any;

  get pricingModelIdentifier() {
    let pricingModelIdentifier = this.args.earlyStagePartner
      ? PRICING_5_1_EARLY_STAGE_FREE_PRICING_MODEL
      : PRICING_5_1_EARLY_STAGE_PRICING_MODEL;
    return pricingModelIdentifier;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Signup::Teams::Pricing5::EarlyStage::PlanUsageMetrics': typeof PlanUsageMetrics;
    'signup/teams/pricing5/early-stage/plan-usage-metrics': typeof PlanUsageMetrics;
  }
}
