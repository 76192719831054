/* import __COLOCATED_TEMPLATE__ from './checkout-sidebar-component.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class CheckoutSidebarComponent extends Component {
  @service appService;
  @service media;
  @service intl;
  @service router;

  @tracked couponDiscountAmountInCents = 0;

  get app() {
    return this.appService.app;
  }

  get mobileCart() {
    return !this.media.isDesktop;
  }

  get numberOfItems() {
    return 1 + (this.args.addOnPlanIds.length || 0);
  }

  get billingPeriodDurationInMonths() {
    return this.args.billingPeriodDurationInMonths;
  }

  get canAddCoupon() {
    return (
      this.billingPeriodDurationInMonths !== 12 &&
      !this.args.isEarlyStage &&
      this.app?.id &&
      this.args.creditCardStep
    );
  }

  get isOnConfirmPage() {
    return this.router.currentRouteName === 'apps.app.teams-checkout.confirm';
  }

  get applicableCouponAmount() {
    return this.args.price.getMonthlyTotalAfterTrial(this.billingPeriodDurationInMonths);
  }

  @action updateTotalAmount(couponCode, _, couponDiscountAmountInCents) {
    this.couponCode = couponCode;
    this.couponDiscountAmountInCents = couponDiscountAmountInCents;
  }
}
