/* import __COLOCATED_TEMPLATE__ from './settings.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import {
  largeFormatQuestionTypeItems,
  smallFormatQuestionTypeItems,
  responseTypeCompatibleAttributeTypes,
  attributeTypeAsValidation,
  responseOrderTypes,
  responseTypes,
} from 'embercom/models/data/survey/constants';
import { task } from 'ember-concurrency-decorators';

const FIRST_CLASS_ATTRIBUTES = ['name', 'email', 'phone'];

export default class QuestionSettings extends Component {
  @service appService;
  @service attributeService;
  @service contentEditorService;
  @service intercomEventService;
  @service modalService;
  @service permissionsService;
  @service intercomConfirmService;

  constructor() {
    super(...arguments);
  }

  get app() {
    return this.appService.app;
  }

  get attributeGroupList() {
    let compatibleAttributeTypes = responseTypeCompatibleAttributeTypes[this.question.questionType];
    let attributes = [];

    this.attributeService.surveyUpdatableAttributeGroupList.forEach((attributeGroup) => {
      let compatibleAttributes = attributeGroup.attributes.filter((attribute) => {
        if (FIRST_CLASS_ATTRIBUTES.includes(attribute.identifier)) {
          return compatibleAttributeTypes.includes(attribute.identifier);
        }

        return compatibleAttributeTypes.includes(attribute.type);
      });

      attributes.push({ ...attributeGroup, attributes: compatibleAttributes });
    });

    return attributes;
  }

  get canSaveToAttribute() {
    return !this.question.isFreeTextEntry;
  }

  get canHavePlaceholderText() {
    return !this.question.isRatingScale && !this.question.isMultiSelect;
  }

  get questionTypeSelectItems() {
    let items = smallFormatQuestionTypeItems;
    if (this.args.survey.isPostFormat) {
      items = largeFormatQuestionTypeItems;
    }

    return items;
  }

  get canChangeResponseOrdering() {
    return this.question.supportsResponseOrdering && this.app.canUseSurveysResponseOrdering;
  }

  get responseOrderingSelectItems() {
    return [
      { value: responseOrderTypes.default, text: 'Original order', icon: 'list' },
      { value: responseOrderTypes.random, text: 'Random order', icon: 'rules' },
    ];
  }

  get selectedResponseOrder() {
    return this.question.data.responseOrder;
  }

  get question() {
    return this.args.question;
  }

  get selectedValue() {
    let value = this.question.questionType;

    if (value !== responseTypes.ratingScale) {
      return this.question.questionType;
    }

    return `${this.question.questionType}.${this.question.data.type}`;
  }

  get survey() {
    return this.args.survey;
  }

  get hasPermissionToAccessProductSettings() {
    return this.permissionsService.currentAdminCan(`can_access_product_settings`);
  }

  @task *_changeQuestionType(selectedQuestionType) {
    let { questionType, scaleQuestionType } =
      this.survey.selectedQuestionTypeDetails(selectedQuestionType);

    let hasQuestionTypeChanged =
      !this.question.isNew && this.question.questionType !== questionType;

    let hasScaleTypeQuestionChange =
      !this.question.isNew &&
      this.question.questionType === questionType &&
      this.question.data.type !== scaleQuestionType;

    let confirmed = true;

    if (hasQuestionTypeChanged || hasScaleTypeQuestionChange) {
      let action = hasQuestionTypeChanged ? 'question_type_changed' : 'question_scale_type_changed';
      let previous_type = hasQuestionTypeChanged
        ? this.question.questionType
        : this.question.data.type;
      let new_type = hasQuestionTypeChanged ? questionType : scaleQuestionType;

      this.intercomEventService.trackAnalyticsEvent({
        action,
        object: 'survey_question',
        question_id: this.question.id,
        ruleset_id: this.contentEditorService.ruleset.id,
        previous_type,
        new_type,
      });

      confirmed = yield this.intercomConfirmService.confirm({
        title: 'Choose a new question type',
        body: `Choosing a new question type may erase some edits you have already made to this question?`,
        confirmButtonText: `Confirm`,
      });
    }

    if (confirmed) {
      this.question.questionType = questionType;
      this.question.setupQuestionData(scaleQuestionType);
    }
  }

  @action onSelectQuestionType(selectedQuestionType) {
    if (this.args.checkBranchingOnQuestionChange(this.question)) {
      return;
    }

    this._changeQuestionType.perform(selectedQuestionType);
  }

  @action onSelectQuestionResponseOrder(selectedResponseOrder) {
    this.question.data.responseOrder = selectedResponseOrder;
  }

  @action toggleSaveToDataAttribute() {
    this.question.saveResponseToAttribute = !this.question.saveResponseToAttribute;

    if (!this.question.saveResponseToAttribute) {
      this.question.attributeIdentifier = undefined;
    }
  }

  @action toggleRequiredQuestion() {
    this.question.data.required = !this.question.data.required;
  }

  @action onSelectAttribute(attribute) {
    this.question.attributeIdentifier = attribute.identifier;

    if (FIRST_CLASS_ATTRIBUTES.includes(attribute.identifier)) {
      this.question.data._setValidationType(attributeTypeAsValidation[attribute.identifier]);
    } else {
      this.question.data._setValidationType(attributeTypeAsValidation[attribute.type]);
    }
  }

  @action showNewAttributeModal() {
    this.modalService.openModal('settings/modals/attribute-details', {
      allowArchival: false,
      showPeopleAndCompanyToggleTab: false,
      onSave: (attribute) => {
        if (attribute) {
          this.onSelectAttribute(attribute);
        }
      },
    });
  }
}
