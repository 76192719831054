/* import __COLOCATED_TEMPLATE__ from './completion-list.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';

export default class CompletionList extends Component {
  get survey() {
    return this.args.statsParameters.contentObject;
  }

  get questions() {
    let questions = this.survey.steps.toArray().flatMap((step) => step.orderedQuestions.toArray());

    if (this.additionalFilters) {
      questions = questions.filter(
        (question) => question.sanitizedId === String(this.selectedFilterValue),
      );
    }

    return questions;
  }

  get additionalFilters() {
    if (this.selectedFilterValue === 0) {
      return null;
    }
    return { 'question.id': this.selectedFilterValue };
  }

  get selectedFilterValue() {
    return parseInt(this.args.filter, 10) || 0;
  }

  get filteredQuestion() {
    return this.questions.find(
      (question) => question.sanitizedId === String(this.selectedFilterValue),
    );
  }

  get columns() {
    let columns = [
      { label: 'Name', valuePath: 'user.displayAs', type: 'avatar-with-text' },
      { label: 'Company name', valuePath: 'company.name' },
    ];

    if (this.questions.length > 0) {
      columns.push(
        ...this.questions.map((question, index) => ({
          label: `Q${index + 1}: ${question.questionTitle}`,
          tooltip: this.questionColumnTooltip(question),
          valuePath: `answers.[${question.sanitizedId}].response`,
          id: question.sanitizedId,
        })),
      );
    }
    columns.push({ label: 'Date completed', valuePath: 'createdAt' });

    return columns;
  }

  questionColumnTooltip(question) {
    let label;

    if (question.attributeIdentifier) {
      let attributeIdentifier = question.attributeIdentifier.split('.').lastObject;
      label = `Saved to attribute: ${attributeIdentifier}`;
    }

    return label;
  }
}
