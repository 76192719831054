/* import __COLOCATED_TEMPLATE__ from './failed-step.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
  store: service(),
  stepDescription: computed('stat.tourStepId', function () {
    let step = this.store.peekRecord('tour-step', this.get('stat.tourStepId'));
    if (step) {
      return `Step ${step.order + 1}`;
    } else {
      return `A deleted step`;
    }
  }),
});
