/* import __COLOCATED_TEMPLATE__ from './email-password.hbs'; */
/* RESPONSIBLE TEAM: team-app-security */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { isEmpty } from '@ember/utils';

export default class EmailPassword extends Component {
  @tracked email = '';
  @tracked password = '';
  @tracked keepMeSignedIn = null;

  get buttonDisabled() {
    return isEmpty(this.email) || isEmpty(this.password);
  }
}
