/* import __COLOCATED_TEMPLATE__ from './classifier.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency-decorators';
import { post } from 'embercom/lib/ajax';
import { action } from '@ember/object';
import { taskFor } from 'ember-concurrency-ts';
import storage from 'embercom/vendor/intercom/storage';

interface Args {}

interface Attribute {
  id: string;
  name: string;
  description: string;
}

export default class ExperimentalClassifier extends Component<Args> {
  @service declare appService: any;
  @tracked classifierAttributes: Array<Attribute> = [];

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    let localAttributes = storage.get('standalone.classifierAttributes');

    if (localAttributes) {
      this.classifierAttributes = localAttributes;
    }
  }

  @tracked message = '';
  @tracked result?: {
    attribute: Attribute;
    metadata: {
      confidence_score: number;
      thoughts: string;
    };
  };

  @dropTask *classify() {
    this.result = yield post(`/ember/standalone/experiments/${this.appService.app.id}}/classify`, {
      app_id: this.appService.app.id,
      attributes: this.classifierAttributes,
      message: this.message,
    });
  }

  get isClassifying() {
    return taskFor(this.classify).isRunning;
  }

  @action didUpdateAttributes() {
    this.classifierAttributes = [...this.classifierAttributes];

    storage.set('standalone.classifierAttributes', this.classifierAttributes);
  }

  @action deleteAttribute(attribute: Attribute) {
    this.classifierAttributes.removeObject(attribute);
    this.didUpdateAttributes();
  }

  @action addAttribute() {
    this.classifierAttributes = [
      ...this.classifierAttributes,
      {
        id: this.classifierAttributes.length.toString(),
        name: '',
        description: '',
      },
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Experiments::Classifier': typeof ExperimentalClassifier;
    'standalone::experiments/classifier': typeof ExperimentalClassifier;
  }
}
