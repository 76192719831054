/* import __COLOCATED_TEMPLATE__ from './copilot-access-cell-wrapper.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import templateOnlyComponent from '@ember/component/template-only';
import type Admin from 'embercom/models/admin';

interface Signature {
  Args: {
    column: {
      widthStyle: number;
    };
    row: {
      teammate: Admin;
    };
    tableActions: {};
  };
}

const CopilotAccessCellWrapper = templateOnlyComponent<Signature>();
export default CopilotAccessCellWrapper;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'TableCells::TeammateList::CopilotAccessCellWrapper': typeof CopilotAccessCellWrapper;
  }
}
