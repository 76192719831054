/* import __COLOCATED_TEMPLATE__ from './team-membership-modification.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { task } from 'ember-concurrency-decorators';
import { all } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { Resource, useResource } from 'ember-resources';
import { registerDestructor } from '@ember/destroyable';
import { action } from '@ember/object';

class TeammateResource extends Resource {
  @service store;
  @tracked teammates = [];
  @service intl;

  constructor(owner, args, previous) {
    super(owner, args, previous);

    let { removedTeammateIds, addedTeammateIds } = args.named;
    this.fetchTeammates.perform([...removedTeammateIds, ...addedTeammateIds]);

    registerDestructor(this, () => {
      this.fetchTeammates.cancelAll();
    });
  }

  @task
  *fetchTeammates(teammateIds) {
    let fetchTeammateTasks = teammateIds.map((id) => this.fetchTeammate.perform(id));
    this.teammates = yield all(fetchTeammateTasks);
  }

  @task
  *fetchTeammate(teammateId) {
    let admin = yield this.store.peekRecord('admin', teammateId) ||
      this.store.findRecord('admin', teammateId);

    return {
      id: parseInt(admin.id, 10),
      name: admin.name,
      status: this.membershipStatus(teammateId),
    };
  }

  membershipStatus(teammateId) {
    return this.args.named.addedTeammateIds.includes(teammateId)
      ? this.intl.t('table-cells.admin-events.team-membership-modification.added')
      : this.intl.t('table-cells.admin-events.team-membership-modification.removed');
  }
}

export default class TeamMembershipModification extends Component {
  @service appService;
  @service intl;
  @tracked showModal = false;

  teammateResource = useResource(this, TeammateResource, () => ({
    removedTeammateIds: this.args.event.value.teammates_removed,
    addedTeammateIds: this.args.event.value.teammates_added,
  }));

  get modalHeader() {
    return this.intl.t('table-cells.admin-events.team-membership-modification.modal-header', {
      teamName: this.args.event.value.team_name,
    });
  }

  get columns() {
    return [
      {
        label: this.intl.t(
          'table-cells.admin-events.team-membership-modification.column-headers.name',
        ),
        valuePath: 'name',
      },
      {
        label: this.intl.t(
          'table-cells.admin-events.team-membership-modification.column-headers.status',
        ),
        valuePath: 'status',
      },
    ];
  }

  @action
  toggleModal() {
    this.showModal = !this.showModal;
  }
}
