/* import __COLOCATED_TEMPLATE__ from './base-percentage-stat-list.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import { stats } from 'embercom/models/data/stats-system/stats-constants';
import { createPercentBucketsForNestedKeys } from 'embercom/lib/reporting/signal-transformations';
import Component from '@glimmer/component';

export default class BasePercentageStatList extends Component {
  get queryKeys() {
    return [`stats_${stats.receipt}`, `stats_${this.args.statistic}`];
  }

  get transforms() {
    return [createPercentBucketsForNestedKeys(`stats_${this.args.statistic}`, this.queryKeys)];
  }
}
