/* import __COLOCATED_TEMPLATE__ from './splash-screen.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { REQUEST_COUNTRY_SURVEY_ID } from '../../models/data/sms/constants';
import ENV from 'embercom/config/environment';

export default class SplashScreen extends Component {
  @action
  requestCountrySurveyLink(event) {
    event.preventDefault();
    if (ENV.environment !== 'development') {
      Intercom('startSurvey', REQUEST_COUNTRY_SURVEY_ID);
    }
  }
}
