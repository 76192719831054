/* import __COLOCATED_TEMPLATE__ from './reaction-list.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';

export default class ReactionList extends Component {
  get selectedFilterValue() {
    let reactionSet = this.args.statsParameters.contentObject?.reactionSet ?? [];
    let values = reactionSet.map((reaction) => reaction.unicode_emoticon);
    return values.includes(this.args.filter) ? this.args.filter : '';
  }

  get additionalFilters() {
    if (!this.selectedFilterValue) {
      return null;
    }
    return { 'current.stat_reaction.reaction': this.selectedFilterValue };
  }
}
