/* import __COLOCATED_TEMPLATE__ from './click-list-row.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';

export default class ClickListRow extends Component {
  get intercomContentLink() {
    if (this.args.row.data?.contentLinkUrl?.startsWith('intercom://')) {
      return this.args.insertableContent.find(
        (content) => content.trigger_url === this.args.row.data.contentLinkUrl,
      );
    } else {
      return undefined;
    }
  }
}
