/* import __COLOCATED_TEMPLATE__ from './plan-builder.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import { action } from '@ember/object';
import type Router from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { findSolution, PLAN_DATA } from 'embercom/lib/billing';
import { Metric } from 'embercom/models/data/pricing/metric-types';
import { ChargeModel } from 'embercom/lib/purchase/pricing-metric';
import { PRICING_5_X_GROUPS } from 'embercom/lib/purchase/constants';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import { type IllustrativeIconName } from '@intercom/pulse/components/ic-illustrative-icon';

interface Args {
  solutionId: string;
  solutionPrice: any;
  solutionPlusAddOnPrice: any;
  billingPeriodDurationInMonths: number;
  addOnPlanIds: number[];
  prices: any;
  toggleAddon: (addonId: number, source: string) => void;
}

export interface Feature {
  titleTranslationKey: string;
  pulseInterfaceIcon?: InterfaceIconName;
  pulseIllustrativeIcon?: IllustrativeIconName;
  pill_color?: string;
}

export interface PlanMetricDisplay {
  name: string;
  priceToDisplay: string;
  tooltipText?: string;
  subtitle?: string;
}

type BaseMetric = {
  name: Metric;
};

interface PerUnitMetric extends BaseMetric {
  chargeModel: ChargeModel.PerUnit;
  price: number;
}

interface TieredMetric extends BaseMetric {
  chargeModel: ChargeModel.Tiered;
  startingPrice: number;
  endingPrice: number;
  firstTiersEndingUnit: number;
}

export type PlanMetric = PerUnitMetric | TieredMetric;

export default class PlanBuilder extends Component<Args> {
  @service declare router: Router;
  @service declare purchaseAnalyticsService: any;
  @service declare intl: any;
  @service declare appService: any;

  get app() {
    return this.appService.app;
  }

  get solution() {
    return findSolution(this.args.solutionId);
  }

  @action
  openAllPlans() {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      object: 'see_all_plans_link',
      context: 'cardless_trial',
      place: 'checkout_plan_page',
    });
    let queryParams = { action: 'buy_intercom' };

    this.router.transitionTo(this.app.onboardingHomeRoute, { queryParams });
  }

  @action
  showMeteredMessagesArticle() {
    window.Intercom('showArticle', this.app.meteredMessagesArticleId);
  }

  get recommendedAddOns() {
    return this.solution.recommendedAddons.map((addOn: number) => PLAN_DATA[addOn]) || [];
  }

  get solutionStartingPrice() {
    let price = this.args.solutionPrice?.getPlanStartingPrice() / 100;
    return this.intl.formatNumber(price, {
      style: 'currency',
      currency: 'USD',
      maximumFractionDigits: 0,
    });
  }

  get planMetrics(): any {
    let planMetrics = this.args.solutionPrice.getMetricsWithBasePricing();
    return this.filterMetricsToDisplay(planMetrics);
  }

  findAssociatedPricingMetrics(metric: Metric) {
    for (let [_, metrics] of Object.entries(PRICING_5_X_GROUPS)) {
      if (metrics.includes(metric)) {
        return metrics;
      }
    }
    return;
  }

  filterMetricsToDisplay(planMetrics: PlanMetric[]) {
    let acceptedMetrics = [
      Metric.resolutions_with_custom_answers,
      [
        Metric.sms_segments_sent_received_monthly_us,
        Metric.sms_segments_sent_received_in_shifted_billing_cycle_us,
      ],
      // Whilst we migrate to the shifted one, we'll want to only show one
      Metric.emails_sent,
      Metric.whatsapp_outbound,
      Metric.calling,
    ];

    let metrics = acceptedMetrics.map((metric) => {
      return planMetrics.find((planMetric) => {
        if (Array.isArray(metric)) {
          return metric.find((m) => m === planMetric.name);
        }

        return planMetric.name === metric;
      });
    }) as PlanMetric[];

    return metrics.map((metric) => {
      let tooltipText =
        this.intl.exists(`signup.build-your-trial.plan_metrics.tooltip.${metric.name}`, 'en') &&
        this.intl.t(`signup.build-your-trial.plan_metrics.tooltip.${metric.name}`);

      if (metric.chargeModel === ChargeModel.PerUnit) {
        return {
          name: metric.name,
          priceToDisplay:
            (metric.name === Metric.calling &&
              this.intl.t('signup.build-your-trial.plan_metrics.phone_pricing_static')) ||
            this.intl.t('pricing-and-packaging.price.breakdown.display.per_unit_price', {
              price: this.args.solutionPrice.formatPrice({ price: metric.price, minPrecision: 2 }),
              unitName: this.intl.t(
                `pricing-and-packaging.price.breakdown.display.${metric.name}.unit_name`,
              ),
            }),
          tooltipText,
        };
      } else if (metric.chargeModel === ChargeModel.Tiered) {
        let tieredPricingMetrics = planMetrics.filter(
          (metric) => metric.chargeModel === ChargeModel.Tiered,
        ) as TieredMetric[];
        let { lowest, highest } = this.tieredPriceRange(tieredPricingMetrics, metric);
        return {
          name: metric.name,
          priceToDisplay: this.intl.t(
            'pricing-and-packaging.price.breakdown.display.tiered_price_per_unit',
            {
              startingPrice: this.args.solutionPrice.formatPrice({
                price: lowest,
                priceInCents: false,
                maxPrecision: 5,
                minPrecision: 2,
              }),
              endingPrice: this.args.solutionPrice.formatPrice({
                price: highest,
                priceInCents: false,
                maxPrecision: 5,
                minPrecision: 2,
              }),
              unitName: this.intl.t(
                `pricing-and-packaging.price.breakdown.display.${metric.name}.unit_name`,
              ),
            },
          ),
          tooltipText,
        };
      }

      return;
    });
  }

  get solutionFeatures() {
    return this.solution.features;
  }

  tieredPriceRange(planMetrics: TieredMetric[], selectedMetric: PlanMetric) {
    let associatedMetrics = this.findAssociatedPricingMetrics(selectedMetric.name);

    let metrics = planMetrics.filter((metric) => associatedMetrics?.includes(metric.name));

    return {
      lowest: Math.min(...metrics.map((metric) => metric.startingPrice)),
      highest: Math.max(...metrics.map((metric) => metric.endingPrice)),
    };
  }

  @action transitionToNextStep() {
    let { solutionId, addOnPlanIds, solutionPlusAddOnPrice, billingPeriodDurationInMonths } =
      this.args;

    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      object: 'continue_button',
      planIds: addOnPlanIds,
      solutionId,
      context: 'usecase_signup_flow',
      place: 'add_ons',
      cartAmount: solutionPlusAddOnPrice.getMonthlyTotalAfterTrial(billingPeriodDurationInMonths),
      billing_period_duration_in_months: billingPeriodDurationInMonths,
    });

    this.router.transitionTo('apps.app.teams-checkout.confirm', {
      queryParams: { solution_id: this.args.solutionId },
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Signup::Teams::Pricing5::PlanBuilder': typeof PlanBuilder;
    'signup/teams/pricing5/plan-builder': typeof PlanBuilder;
  }
}
