/* import __COLOCATED_TEMPLATE__ from './tickets.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-empty-glimmer-component-classes */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';

interface Args {}

export default class StandaloneZendeskSetupTickets extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;

  get zendeskConfig() {
    return this.finStandaloneService.zendeskConfig;
  }

  @tracked openSectionId?: string;

  @action setOpenSectionId(id: string | undefined) {
    this.openSectionId = id;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Zendesk::Setup::Tickets': typeof StandaloneZendeskSetupTickets;
    'standalone/zendesk/setup/tickets': typeof StandaloneZendeskSetupTickets;
  }
}
