/* import __COLOCATED_TEMPLATE__ from './data-fields.hbs'; */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* RESPONSIBLE TEAM: team-standalone */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type FinStandaloneService from 'embercom/services/fin-standalone-service';
import { type UserField, type TicketField } from 'embercom/services/fin-standalone-service';
import { action } from '@ember/object';
import type Store from '@ember-data/store';
import { tracked } from '@glimmer/tracking';
import { taskFor } from 'ember-concurrency-ts';

interface Args {}

export default class StandaloneSalesforceDataFields extends Component<Args> {
  @service declare finStandaloneService: FinStandaloneService;
  @service declare store: Store;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    taskFor(this.finStandaloneService.loadSalesforceDataFields).perform();
  }

  get salesforceConfig() {
    return this.finStandaloneService.salesforceConfig;
  }

  get synchronizedUserFields(): Array<UserField> {
    return this.finStandaloneService.salesforceDataFields.userFields.filter(
      (field) => field.required || field.intercom_cda?.identifier || field.isSynchronizing,
    );
  }

  get unsynchronizedUserFields(): Array<UserField> {
    return this.finStandaloneService.salesforceDataFields.userFields.filter(
      (field) => !field.required && !field.intercom_cda?.identifier && !field.isSynchronizing,
    );
  }

  get synchronizedCaseFields(): Array<TicketField> {
    return this.finStandaloneService.salesforceDataFields.caseFields.filter(
      (field) => field.required || field.intercom_cvda?.id || field.isSynchronizing,
    );
  }

  get unsynchronizedCaseFields(): Array<TicketField> {
    return this.finStandaloneService.salesforceDataFields.caseFields.filter(
      (field) => !field.required && !field.intercom_cvda?.id && !field.isSynchronizing,
    );
  }

  @action async addSalesforceUserField(userField: UserField) {
    await this.finStandaloneService.addSalesforceUserField(userField);
  }

  @action async addSalesforceCaseField(caseField: TicketField) {
    await this.finStandaloneService.addSalesforceCaseField(caseField);
  }

  @tracked selectedField?: UserField | TicketField;

  @action didCloseModal() {
    this.selectedField = undefined;
    this.finStandaloneService.notifyPropertyChange('salesforceDataFields');
  }

  @action selectField(field: UserField | TicketField) {
    this.selectedField = field;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Salesforce::DataFields': typeof StandaloneSalesforceDataFields;
    'standalone/Salesforce/DataFields': typeof StandaloneSalesforceDataFields;
  }
}
