/* import __COLOCATED_TEMPLATE__ from './attribute-helper-text.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import type IntlService from 'ember-intl/services/intl';
import { responseTypes } from 'embercom/models/data/survey/constants';
import { inject as service } from '@ember/service';

interface Args {
  questionType: number;
}

export default class AttributeHelperText extends Component<Args> {
  @service declare intl: IntlService;

  get helperText() {
    switch (this.args.questionType) {
      case responseTypes.input:
        return this.intl.t('outbound.surveys.settings.attribute-help-text.input');
      case responseTypes.ratingScale:
        return this.intl.t('outbound.surveys.settings.attribute-help-text.rating-scale');
      case responseTypes.dropdown:
        return this.intl.t('outbound.surveys.settings.attribute-help-text.dropdown');
      case responseTypes.multiSelect:
        return this.intl.t('outbound.surveys.settings.attribute-help-text.multiselect');
      default:
        return this.intl.t('outbound.surveys.settings.attribute-help-text.unknown');
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Survey::Question::Settings::AttributeHelperText': typeof AttributeHelperText;
    'survey/question/settings/attribute-helper-text': typeof AttributeHelperText;
  }
}
