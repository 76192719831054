/* import __COLOCATED_TEMPLATE__ from './survey-settings.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { senderTypes } from 'embercom/models/data/outbound/constants';
import { surveyFormats } from 'embercom/models/data/survey/constants';
import { DEFAULT_MESSENGER_COLORS } from 'embercom/lib/messenger-settings-defaults';
import { stepTypes } from 'embercom/models/data/survey/constants';
import {
  defaultBannerThankYouBlocks,
  defaultPostThankYouBlocks,
} from 'embercom/models/data/survey/constants';

const UNASSIGNED_ID = '0';
export default class SurveySettings extends Component {
  @service store;
  @service appService;
  @service contentEditorService;
  @tracked selectedSenderType = this.survey.senderType;
  @tracked selectedFormat = this.survey.format;
  @tracked showConfirmModal = false;

  senderTypeOptions = [
    { text: "Don't show sender", value: senderTypes.noSender },
    { text: 'Show sender', value: senderTypes.admin },
  ];

  formatOptions = [
    { text: 'Small', value: surveyFormats.banner },
    { text: 'Large', value: surveyFormats.post },
  ];

  colorOptions = DEFAULT_MESSENGER_COLORS;

  get app() {
    return this.appService.app;
  }

  get isNewSurveyNotFromTemplate() {
    return (
      this.contentEditorService.ruleset.isNewRuleset &&
      !this.survey.orderedSteps.firstObject.hasQuestions
    );
  }

  get senderTypes() {
    return senderTypes;
  }

  get survey() {
    return this.args.survey;
  }

  get defaultThankYouStep() {
    return this.survey.steps.find((step) => step.isThankYouType);
  }

  get introStep() {
    return this.survey.steps.find((step) => step.isIntroType);
  }

  get selectedFormatAsString() {
    return this.selectedFormat.toString();
  }

  get selectedFormatIcon() {
    if (this.survey.format === surveyFormats.banner) {
      return 'banner-filled';
    }

    return 'post-filled';
  }

  get selectedFormatHumanFriendlyName() {
    if (this.survey.format === surveyFormats.banner) {
      return 'Small';
    }

    return 'Large';
  }

  @action addEmptyStep() {
    this.args.addStep();
  }

  @action filterOutOrderedSteps() {
    this.survey.steps = this.survey.steps.filter((step) => !step.isOrdered);
  }

  @action setSelectedSenderOption(senderType) {
    this.selectedSenderType = senderType;
    if (senderType === senderTypes.noSender) {
      this.survey.senderId = UNASSIGNED_ID;
      this.survey.senderType = this.senderTypes.noSender;
    } else {
      this.survey.senderId = this.app.currentAdmin.id;
      this.survey.senderType = senderType;
    }
  }

  @action cancelSurveyFormat() {
    this.showConfirmModal = false;
    this.contentEditorService.ruleset.isNewRuleset = false;
    this.selectedFormat = this.survey.format;
  }

  @action confirmSurveyFormat() {
    this.showConfirmModal = false;
    this.contentEditorService.ruleset.isNewRuleset = false;

    if (this.selectedFormat !== this.survey.format) {
      this.survey.format = this.selectedFormat;
      this.showConfirmModal = false;

      this.removeAllThankYouSteps();
      this.addDefaultThankYouStep();

      this.filterOutOrderedSteps();
      this.survey.paths = [];
      this.addEmptyStep();

      if (this.selectedFormat === surveyFormats.banner && this.survey.hasIntroStep) {
        this.args.removeIntroStep();
      } else if (this.selectedFormat === surveyFormats.post) {
        this.args.addIntroStep();
      }
    }
  }

  @action toggleShowConfirmModal() {
    this.showConfirmModal = !this.showConfirmModal;
  }

  @action updateSelectedFormat(format) {
    this.selectedFormat = Number(format);
  }

  @action removeAllThankYouSteps() {
    this.survey.thankYouSteps.forEach((thankYouStep) => {
      this.survey.steps.removeObject(thankYouStep);
    });
  }

  @action addDefaultThankYouStep() {
    let thankYouStep = this.store.createRecord('surveys/step', {
      order: -2,
      stepType: stepTypes.thankYou,
      blocks: this.survey.isPostFormat ? defaultPostThankYouBlocks : defaultBannerThankYouBlocks,
    });
    this.survey.steps.pushObject(thankYouStep);
  }

  @action toggleDismissible() {
    this.survey.dismissible = !this.survey.dismissible;
  }

  @action toggleShowProgressBar() {
    this.survey.showProgressBar = !this.survey.showProgressBar;
  }
}
