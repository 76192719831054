/* import __COLOCATED_TEMPLATE__ from './billing-addressees-change-details.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'ember-intl/services/intl';

interface BillingAddresseesChangeDetailsArgs {
  event: {
    value: {
      old_emails: string;
      new_emails: string;
    };
  };
}

export default class BillingAddresseesChangeDetails extends Component<BillingAddresseesChangeDetailsArgs> {
  @service declare intl: IntlService;
  @tracked showModal = false;

  get emailsBeforeChangeSet(): Set<string> {
    return new Set(this.args.event.value.old_emails.split(', '));
  }

  get emailsAfterChangeSet(): Set<string> {
    return new Set(this.args.event.value.new_emails.split(', '));
  }

  get addedEmails(): string[] {
    return this.args.event.value.new_emails
      .split(', ')
      .filter((email) => !this.emailsBeforeChangeSet.has(email));
  }

  get removedEmails(): string[] {
    return this.args.event.value.old_emails
      .split(', ')
      .filter((email) => !this.emailsAfterChangeSet.has(email));
  }

  get emailsAfterChange(): string[] {
    return this.args.event.value.new_emails.split(', ');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'TableCells::AdminEvents::BillingAddresseesChangeDetails': typeof BillingAddresseesChangeDetails;
    'table-cells/admin-events/billing-addressees-change-details': typeof BillingAddresseesChangeDetails;
  }
}
