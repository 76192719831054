/* import __COLOCATED_TEMPLATE__ from './stripe-component.hbs'; */
/* RESPONSIBLE TEAM: team-purchase-experience */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask, keepLatestTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import ENV from 'embercom/config/environment';
import { post } from 'embercom/lib/ajax';
import Countries from 'embercom/lib/countries';

import type Router from '@ember/routing/router-service';
import { type TaskGenerator } from 'ember-concurrency';
import { captureException } from 'embercom/lib/sentry';

import type Store from '@ember-data/store';
import type StripeIntent from 'embercom/models/billing/stripe-intent';

export interface AfterPaymentMethodSuccess {
  paymentMethod: string;
  stripeIntent?: StripeIntent;
  address: {
    countryCode: string;
    streetAddress: string;
    stateCode?: string;
    postCode?: string;
    city?: string;
  };
  taxNumber?: string;
}

export enum INTENT_TYPES {
  paymentIntent = 'payment_intent',
  setupIntent = 'setup_intent',
}

interface Args {
  redirectUrl: string;
  amount: number;
  type: INTENT_TYPES;
  immediatelyMountStripeElements: boolean;
  disableVatField?: boolean;
  enableExistingPaymentMethod?: boolean;
  afterSuccessTask: (afterSuccessPayload: AfterPaymentMethodSuccess) => TaskGenerator<void>;
}

interface AddressResultFromStripeElements {
  city?: string;
  country?: string;
  line1?: string;
  postal_code?: string;
  state?: string;
}

interface UpdateShippingAddressResults {
  city?: string;
  country?: string;
  line1?: string;
  postal_code?: string;
  state?: string;
  vat_number?: string;
}

interface StripeElement {
  mount: (selector: string) => void;
}

enum addressValidationCountryCodes {
  unitedStates = 'US',
  canada = 'CA',
}

interface Signature {
  Element: any;
  Args: Args;
  Blocks: {
    default: [
      {
        paymentElement: any;
        confirmButton: any;
      },
    ];
  };
}

export default class StripeComponent extends Component<Signature> {
  @service declare appService: any;
  @service declare customerService: any;
  @service declare intl: any;
  @service declare stripev3: any;
  @service declare notificationsService: any;
  @service declare router: Router;

  @service declare store: Store;
  @service declare purchaseAnalyticsService: any;

  @tracked elements: any;
  @tracked confirmingPaymentMethod = false;
  @tracked useExistingCard = false;
  @tracked paymentElementReference!: StripeElement;
  @tracked addressElementReference!: StripeElement;

  @tracked stripeIntent?: StripeIntent;
  @tracked amount = 100;
  @tracked enableExistingPaymentMethod = false;

  // billing address attributes
  @tracked countryCode?: string;
  @tracked stateCode?: string;
  @tracked postCode?: string;
  @tracked city?: string;
  @tracked streetAddress?: string;
  @tracked vatNumber?: string;

  constructor(owner: unknown, args: any) {
    super(owner, args);
    this.enableExistingPaymentMethod = this.args.enableExistingPaymentMethod ?? false;
    if (!this.args.immediatelyMountStripeElements) {
      // We need to wait for the stripe elements to be configured before we can check if we should use the existing card
      taskFor(this.configureStripeElements)
        .perform()
        // eslint-disable-next-line promise/prefer-await-to-then
        .then(() => {
          this.useExistingCard = this.supportExistingPaymentMethod;
        });
    }
  }

  get supportExistingPaymentMethod() {
    if (this.appService.app.allowExistingCardSubmission === false) {
      return false;
    }

    return (
      (this.hasCard &&
        this.stripeIntent?.hasExistingPaymentMethod &&
        this.enableExistingPaymentMethod) ||
      false
    );
  }

  get isMountingElements() {
    return taskFor(this.mountStripeElements).isRunning;
  }

  get showVatField() {
    if (!this.appService.app.showVatInputInCheckout || this.args.disableVatField) {
      return false;
    }
    let country = Countries.allCountries.find(
      (country) => country.code.toUpperCase() === this.countryCode?.toUpperCase(),
    );
    return !!country?.inEu;
  }

  get hasCard() {
    return this.customerService.customer.hasCard;
  }

  get existingCard() {
    return {
      cardBrand: this.customerService.customer.cardBrand,
      cardLastFour: this.customerService.customer.cardLastFour,
      cardExpiryMonth: this.customerService.customer.cardExpiryMonth,
      cardExpiryYear: this.customerService.customer.cardExpiryYear,
    };
  }

  @action
  setUseExistingCard(value: boolean) {
    this.useExistingCard = value;
  }

  private setLoading(loading: boolean) {
    this.confirmingPaymentMethod = loading;
    let addressElement = document.getElementById('address-element');
    let paymentElement = document.getElementById('payment-element');
    let vatField = document.getElementById('vat-field');
    if (addressElement) {
      addressElement.style.visibility = loading ? 'hidden' : 'visible';
    }
    if (paymentElement) {
      paymentElement.style.visibility = loading ? 'hidden' : 'visible';
    }
    if (vatField) {
      vatField.style.visibility = loading ? 'hidden' : 'visible';
    }
  }

  private addressRequiresValidationForCountry(countryCode: string) {
    if (this.appService.app.disableFrontendAddressValidation) {
      return false;
    }

    let countryCodesRequiringChecks: string[] = Object.values(addressValidationCountryCodes);
    return countryCodesRequiringChecks.includes(countryCode);
  }

  @action
  setAddress(addressResult: AddressResultFromStripeElements) {
    this.countryCode = addressResult.country;
    this.stateCode = addressResult?.state ? addressResult?.state : undefined;
    this.postCode = addressResult?.postal_code ? addressResult?.postal_code : undefined;
    this.city = addressResult?.city ? addressResult.city : undefined;
    this.streetAddress = addressResult.line1;
  }

  @action
  setVatNumber(event: Event & { target: HTMLInputElement }) {
    this.vatNumber = event.target.value;
  }

  @keepLatestTask
  *mountStripeElements(): TaskGenerator<void> {
    if (!(this.paymentElementReference && this.addressElementReference)) {
      yield taskFor(this.configureStripeElements).perform();
    }

    try {
      this.paymentElementReference.mount('#payment-element');
      this.addressElementReference.mount('#address-element');
      this.elements.getElement('address').on('change', (event: any) => {
        let countryCode = event.value.address?.country;
        if (countryCode) {
          this.countryCode = countryCode;
        }
      });
    } catch (err) {
      captureException(err, {
        tags: {
          responsibleTeam: 'team-purchase-experience',
          responsible_team: 'team-purchase-experience',
        },
      });
      this.notificationsService.notifyError(
        this.intl.t('signup.teams.pricing5.annual-plans.stripe.payment-element-error'),
      );
      console.error(err);
    }
  }

  @keepLatestTask
  *configureStripeElements(): TaskGenerator<void> {
    try {
      let stripeIntent = yield taskFor(this.createPaymentIntent).perform(this.amount);
      let key = this.getStripeKey();

      if (this.stripev3.stripeOptions?.stripeAccount !== stripeIntent.stripeAccountId) {
        this.stripev3._stripe = null;
      }
      yield this.stripev3.load(key, { stripeAccount: stripeIntent.stripeAccountId });

      let options = {
        loader: 'auto',
        appearance: {
          theme: 'minimal',
        },
        clientSecret: stripeIntent.clientSecret,
      };

      this.elements = this.stripev3.elements(options);

      let addressOptions = { mode: 'billing', fields: { name: 'never' } };

      let paymentElementOptions = {
        terms: {
          card: 'never',
          googlePay: 'never',
          paypal: 'never',
          applePay: 'never',
        },
        layout: 'tabs',
      };

      this.addressElementReference = this.elements.create('address', addressOptions);
      this.paymentElementReference = this.elements.create('payment', paymentElementOptions);
    } catch (err) {
      this.notificationsService.notifyError(
        this.intl.t('signup.teams.pricing5.annual-plans.stripe.payment-element-error'),
      );
      captureException(err, {
        tags: {
          responsibleTeam: 'team-purchase-experience',
          responsible_team: 'team-purchase-experience',
        },
      });
      console.error(err);
    }
  }

  @dropTask
  *confirmPayment(): TaskGenerator<void> {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      object: 'confirm_payment',
      place: 'stripe-payment-method',
      context: {
        payment_intent: this.stripeIntent?.isPaymentIntent,
        app_id: this.appService.app.id,
      },
    });

    this.setLoading(true);
    if (!this.useExistingCard) {
      let addressElement = this.elements.getElement('address');

      let addressResult = yield addressElement.getValue();
      this.setAddress(addressResult.value.address);
      if (this.addressRequiresValidationForCountry(this.countryCode ?? '')) {
        try {
          yield taskFor(this.validateAddress).perform();
        } catch (err) {
          this.setLoading(false);
          this.purchaseAnalyticsService.trackEvent({
            action: 'errored',
            object: 'pay_now_button',
            context: 'address_validation',
            place: 'stripe-payment-method',
          });
          return;
        }
      }
    }

    if (this.vatNumber) {
      try {
        yield taskFor(this.validateTaxNumber).perform();
      } catch (err) {
        this.setLoading(false);
        this.purchaseAnalyticsService.trackEvent({
          action: 'errored',
          object: 'pay_now_button',
          context: 'vat_number_validation',
          place: 'stripe-payment-method',
        });
        return;
      }
    }

    if (this.args.amount !== this.stripeIntent?.amount && this.stripeIntent?.isPaymentIntent) {
      try {
        yield taskFor(this.updatePaymentIntent).perform(this.args.amount);
      } catch (err) {
        this.notificationsService.notifyError(
          this.intl.t('signup.teams.pricing5.annual-plans.stripe.payment-element-error'),
        );
        captureException(err, {
          tags: {
            responsibleTeam: 'team-purchase-experience',
            responsible_team: 'team-purchase-experience',
          },
        });
        console.error(err);
        return;
      }
    }

    let response;
    try {
      response = yield taskFor(this.confirmSetup).perform({
        elements: this.elements,
        url: this.args.redirectUrl,
      });
    } catch (err) {
      this.setLoading(false);
      captureException(err, {
        tags: {
          responsibleTeam: 'team-purchase-experience',
          responsible_team: 'team-purchase-experience',
        },
      });
      this.notificationsService.notifyError(
        this.intl.t('signup.teams.pricing5.annual-plans.stripe.generic-error'),
      );
      console.error(err);
      return;
    }
    //This code will never be hit if the payment method requires a redirect
    if (response.error) {
      this.setLoading(false);
      this.handleError(response.error);
      captureException(response.error, {
        tags: {
          responsibleTeam: 'team-purchase-experience',
          responsible_team: 'team-purchase-experience',
        },
      });
      this.purchaseAnalyticsService.trackEvent({
        action: 'errored',
        object: 'confirm_payment',
        context: {
          error_type: response.error?.type,
          decline_code: response.error?.decline_code || response.error?.code,
          error_code: response.error?.code,
        },
        place: 'stripe-payment-method',
      });
      return;
    }
    let paymentMethod;
    if (this.stripeIntent!.isPaymentIntent) {
      paymentMethod = response.paymentIntent.payment_method;
    } else {
      paymentMethod = response.setupIntent.payment_method;
    }
    let afterSuccessPayload: AfterPaymentMethodSuccess = {
      paymentMethod,
      stripeIntent: this.stripeIntent,
      address: {
        countryCode: this.countryCode!,
        streetAddress: this.streetAddress!,
        stateCode: this.stateCode,
        postCode: this.postCode,
        city: this.city,
      },
      taxNumber: this.vatNumber,
    };
    taskFor(this.args.afterSuccessTask).perform(afterSuccessPayload);
  }

  @keepLatestTask
  *confirmSetup({ elements, url }: { elements: any; url: string }): TaskGenerator<any> {
    if (!this.stripeIntent) {
      throw new Error('Error encountered with configuring payment gateway');
    }

    yield this.stripev3.load(this.getStripeKey(), {
      stripeAccount: this.stripeIntent.stripeAccountId,
    });

    let params: any = {
      redirect: 'if_required',
      confirmParams: {
        return_url: url,
      },
    };
    if (this.useExistingCard) {
      params.clientSecret = this.stripeIntent.clientSecret;
    } else {
      params.elements = elements;
    }
    let response;
    if (this.stripeIntent.isPaymentIntent) {
      response = yield this.stripev3.instance.confirmPayment(params);
    } else {
      response = yield this.stripev3.instance.confirmSetup(params);
    }
    return response;
  }

  @keepLatestTask
  *createPaymentIntent(amount_in_cent: number): TaskGenerator<StripeIntent> {
    let forceSetupIntent = this.args.type === INTENT_TYPES.setupIntent;
    let forcePaymentIntent = this.args.type === INTENT_TYPES.paymentIntent;
    let params: { [key: string]: any } = {
      app_id: this.appService.app.id,
      ...(forcePaymentIntent && {
        amount_in_cent,
      }),
      ...(forceSetupIntent && {
        force_setup_intent: true,
      }),
    };

    try {
      let response = yield post('/ember/customers/create_stripe_intent_for_checkout', params);
      this.store.pushPayload('billing/stripe-intent', {
        'billing/stripe-intent': [response],
      });
      this.stripeIntent = this.store.peekRecord('billing/stripe-intent', response.object_id)!;
      return this.stripeIntent;
    } catch (err) {
      captureException(err);
      throw err;
    }
  }

  @keepLatestTask
  *updatePaymentIntent(newAmount: number): TaskGenerator<StripeIntent> {
    if (!this.stripeIntent) {
      throw new Error('Error encountered with configuring payment gateway');
    }

    if (!this.stripeIntent.isPaymentIntent) {
      return this.stripeIntent;
    }

    let params = {
      payment_intent_id: this.stripeIntent.id,
      amount_in_cent: newAmount,
      app_id: this.appService.app.id,
    };
    try {
      let response = yield post('/ember/customers/update_payment_intent', params);
      this.stripeIntent.updateFromPayload(response);
      return this.stripeIntent;
    } catch (err) {
      captureException(err);
      throw err;
    }
  }

  @keepLatestTask
  *validateAddress(): TaskGenerator<AddressResultFromStripeElements> {
    let params = {
      country_code: this.countryCode,
      state_code: this.stateCode,
      postcode: this.postCode,
      city: this.city,
      street_address: this.streetAddress,
      app_id: this.appService.app.id,
    };
    let response;
    try {
      response = yield post('/ember/customers/validate_address', params);
      return response;
    } catch (err) {
      captureException(err);
      this.notificationsService.notifyError(
        this.intl.t('signup.teams.pricing5.annual-plans.address-error'),
      );
      console.error(err?.jqXHR?.responseJSON?.error);
      captureException(err, {
        tags: {
          responsibleTeam: 'team-purchase-experience',
          responsible_team: 'team-purchase-experience',
        },
      });
      throw err;
    }
  }

  @keepLatestTask
  *validateTaxNumber(): TaskGenerator<UpdateShippingAddressResults> {
    let params = {
      country_code: this.countryCode,
      tax_number: this.vatNumber,
      app_id: this.appService.app.id,
    };
    let response;
    try {
      response = yield post(`/ember/customers/validate_tax_number`, params);
      return response;
    } catch (err) {
      captureException(err);
      let errorMessage =
        err?.jqXHR?.status === 404
          ? this.intl.t('signup.teams.pricing5.annual-plans.vat-invalid-error')
          : this.intl.t('signup.teams.pricing5.annual-plans.vat-check-error');
      this.notificationsService.notifyError(errorMessage);
      console.error(err?.jqXHR?.responseJSON?.error);
      captureException(err, {
        tags: {
          responsibleTeam: 'team-purchase-experience',
          responsible_team: 'team-purchase-experience',
        },
      });
      throw err;
    }
  }

  private handleError(error: any) {
    switch (error?.type) {
      case 'card_error':
      case 'validation_error':
        if (error.decline_code === 'lost_card' || error.decline_code === 'stolen_card') {
          this.notificationsService.notifyError(
            this.intl.t('signup.teams.pricing5.annual-plans.stripe.lost-stolen-card'),
          );
        } else {
          this.notificationsService.notifyError(error.message);
        }
        break;
      case 'invalid_request_error':
        if (error?.code === 'setup_intent_authentication_failure') {
          this.notificationsService.notifyError(
            this.intl.t('signup.teams.pricing5.annual-plans.stripe.card-unauthorized'),
          );
        } else {
          this.notificationsService.notifyError(
            this.intl.t('signup.teams.pricing5.annual-plans.stripe.generic-error'),
          );
        }
        break;
      default:
        this.notificationsService.notifyError(
          this.intl.t('signup.teams.pricing5.annual-plans.stripe.generic-error'),
        );
        break;
    }
  }

  private _isStaging() {
    return window.location.hostname.startsWith(ENV.APP.stagingHostname);
  }

  private getStripeKey() {
    if (this._isStaging()) {
      return ENV.APP.stripe.sandboxPublishableKey;
    }
    return this.stripev3.publishableKey;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Stripe::StripeComponent': typeof StripeComponent;
  }
}
