/* import __COLOCATED_TEMPLATE__ from './state-select-component.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { inject as service } from '@ember/service';
import States from 'embercom/lib/states';

export default Component.extend({
  intl: service(),

  classesForSelect: null,
  required: false,
  stateCodeError: null,
  state: null,

  states: computed('countryCode', 'intl.locale', function () {
    States[this.countryCode].forEach((state) => {
      state.label = this.intl.t(state.textTranslationKey);
    });
    return States[this.countryCode];
  }),

  actions: {
    selectState(state) {
      this.set('state', state);
      if (this.onSelect) {
        this.onSelect('company_state');
      }
    },
  },
});
