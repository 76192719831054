/* import __COLOCATED_TEMPLATE__ from './source-placeholder.hbs'; */
/* RESPONSIBLE TEAM: team-standalone */

import templateOnlyComponent from '@ember/component/template-only';

export interface Args {}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

const SourcePlaceholder = templateOnlyComponent<Signature>();
export default SourcePlaceholder;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Standalone::Setup::Content::SourcePlaceholder': typeof SourcePlaceholder;
    'standalone::setup::content::source-placeholder': typeof SourcePlaceholder;
  }
}
