/* import __COLOCATED_TEMPLATE__ from './reply-list-row.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class ReplyListRow extends Component {
  @service contentEditorService;
  @service appService;

  @action updateConversationId(conversationId) {
    this.contentEditorService.updateConversationId(conversationId);
  }
}
