/* import __COLOCATED_TEMPLATE__ from './article-content-views-title.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class ArticleContentViewsTitle extends Component {
  @service intl;

  get totalViewsText() {
    if (this.args.statMetadata.totalCount > 1) {
      return `${this.intl.formatNumber(this.args.statMetadata.totalCount)} times`;
    } else {
      return 'once';
    }
  }
}
