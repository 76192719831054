/* import __COLOCATED_TEMPLATE__ from './user-info.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import { get } from 'embercom/lib/ajax';
import { inject as service } from '@ember/service';
import {
  objectTypes,
  objectIcons,
  objectNames,
  humanReadableObjectNames,
} from 'embercom/models/data/matching-system/matching-constants';
import { OUTBOUND_EDITOR_ROUTES } from 'embercom/models/outbound/content-wrapper';

export default class StatsSystemUserInfoComponent extends Component {
  @tracked page = 1;
  @tracked receipts = [];
  @service appService;

  @task *load() {
    let response = yield get('/ember/users/receipts.json', {
      app_id: this.appService.app.id,
      id: this.args.user.id,
      page_from: this.page,
      content_types: [
        objectTypes.tour,
        objectTypes.email,
        objectTypes.banner,
        objectTypes.push,
        objectTypes.chat,
        objectTypes.carousel,
        objectTypes.post,
        objectTypes.series,
        objectTypes.customBot,
        objectTypes.survey,
        objectTypes.sms,
        objectTypes.checklist,
      ],
    });

    this.receipts.pushObjects(this.transformReceiptResponse(response));
  }

  transformReceiptResponse(receipts) {
    return receipts.map((receipt) => {
      return {
        createdAt: receipt.created_at,
        icon: objectIcons[receipt.content_type],
        title: this.titleForReceipt(receipt),
        route: this.routeForReceipt(receipt),
      };
    });
  }

  titleForReceipt(receipt) {
    if (receipt.content_name) {
      return receipt.content_name;
    } else {
      return `Untitled ${humanReadableObjectNames[receipt.content_type]}`;
    }
  }

  routeForReceipt(receipt) {
    if (receipt.series_id) {
      return {
        id: receipt.series_id,
        name: `apps.app.outbound.series.series`,
        queryParams: { nodeId: receipt.series_node_id },
      };
    }

    if (
      receipt.ruleset_id === -1 &&
      receipt.instance_type === objectTypes.conversation &&
      receipt.instance_id
    ) {
      return {
        id: receipt.instance_id,
        name: this.appService.app.conversationRoute,
      };
    }

    let name = OUTBOUND_EDITOR_ROUTES[objectNames[receipt.content_type]];
    if (name) {
      return {
        id: receipt.ruleset_id,
        name,
      };
    }
  }
}
