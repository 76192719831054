/* import __COLOCATED_TEMPLATE__ from './include-other.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class IncludeOther extends Component {
  get includeOther() {
    return this.question.data.includeOther;
  }

  get question() {
    return this.args.question;
  }

  @action toggleIncludeOther() {
    this.question.data.includeOther = !this.question.data.includeOther;
    this.args.handleMultiSelectMax(this.question);
  }
}
