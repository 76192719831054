/* import __COLOCATED_TEMPLATE__ from './upgrade-install-banner.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class UpgradeInstallBanner extends Component {
  @service onboardingHomeService;
  @service appService;
  @service router;
  @service intl;

  get app() {
    return this.appService.app;
  }

  get product() {
    return this.app.surveysProduct;
  }

  get paywallContent() {
    return this.intl.t('app.components.survey.paywall-banner');
  }

  get messengerIsInstalled() {
    return (
      this.app.hasAnyInstalledAtDate ||
      this.app.first_identified_request_at ||
      this.app.verifiedLoggedInInstallAt ||
      this.app.first_anonymous_request_at ||
      this.app.verifiedLoggedOutInstallAt
    );
  }

  get showInstallBanner() {
    return !this.messengerIsInstalled && this.onboardingHomeBannerNotShowingInstallMessenger;
  }

  get onboardingHomeBannerNotShowingInstallMessenger() {
    return (
      !this.onboardingHomeService.guide?.installMessengerStep?.available &&
      !this.onboardingHomeService.guide?.installLoggedInMessengerStep?.available
    );
  }

  get showLoggedInInstallBanner() {
    return (
      this.messengerIsInstalled &&
      !(this.app.first_identified_request_at || this.app.verifiedLoggedInInstallAt)
    );
  }

  @action
  async trialConfirmationAction() {
    return this.router.transitionTo({
      queryParams: { onboardingVideoDisplayed: true },
    });
  }
}
