/* import __COLOCATED_TEMPLATE__ from './preview-modal.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { modifier } from 'ember-modifier';
import { tracked } from '@glimmer/tracking';
import isValidUrl from 'embercom/lib/url-validator';
import type IntlService from 'embercom/services/intl';

interface Args {
  survey: $TSFixMe;
  closeModal: () => void;
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
}

export default class PreviewModal extends Component<Signature> {
  @service declare intersectionService: any;
  @service declare appService: any;
  @service declare contentEditorService: any;
  @service declare intercomEventService: any;
  @service declare intl: IntlService;

  @tracked isSelectingRemotePreview = false;
  @tracked selectedDomain: string | undefined;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.intersectionService.registerEventHandler('getSurvey', (_data: any) => {
      return {
        id: this.activeSurvey.id,
        title: this.surveyTitle,
      };
    });
  }

  get activeSurvey() {
    if (
      this.contentEditorService.ruleset.hasTests &&
      !this.contentEditorService.ruleset.hasControlGroup
    ) {
      return this.contentEditorService.activeObject;
    }

    return this.contentEditorService.ruleset.rulesetLinks.firstObject.object;
  }

  get surveyTitle() {
    return (
      this.contentEditorService.ruleset.clientData.title ||
      this.intl.t('outbound.surveys.preview.untitled-survey')
    );
  }

  @action
  closeModal() {
    this.args.closeModal();
  }

  bindIFrame = modifier((_el: Element) => {
    this.intersectionService.mountIFrame(
      `${window.location.origin}/hosted_messenger/${this.appService.app.id}?preview_only=true`,
      'survey-local-preview',
      'survey-preview-iframe-mount',
    );
  });

  @action
  openSite() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'remote_survey_preview_button',
    });

    this.intersectionService.openWindow(this.selectedDomain, 'survey-preview');
    this.args.closeModal();
  }

  @action
  enableRemotePreviewSelection() {
    this.isSelectingRemotePreview = true;
  }

  @action
  resetLocalPreview() {
    let { intersectionWindow, targetDomain } = this.intersectionService;
    intersectionWindow.postMessage('restart_preview', targetDomain.origin);
  }

  get isValidSelectedDomain() {
    return isValidUrl(this.selectedDomain);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Survey::Preview::PreviewModal': typeof PreviewModal;
  }
}
