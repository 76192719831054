/* RESPONSIBLE TEAM: team-growth-opportunities */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-jquery */
import Component from '@ember/component';
import EmberObject, { computed } from '@ember/object';
import { alias, and, bool, empty, not, or, readOnly } from '@ember/object/computed';
import { run } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { isValidEmail, ternary } from '@intercom/pulse/lib/computed-properties';
import PALETTE from '@intercom/pulse/lib/palette';
import ENV from 'embercom/config/environment';
import AddressSettings from 'embercom/lib/address-settings';
import $ from 'jquery';

export default Component.extend({
  tagName: '',
  purchaseAnalyticsService: service(),
  router: service(),
  stripe: service('stripev3'),
  intl: service(),

  hasLocale: bool('locale'),
  notViaSingleSignOn: not('viaSingleSignOn'),
  context: 'signup',

  viaSingleSignOn: false,
  buttonCta: null,
  personalEmailDomain: false,

  name: '',
  email: '',
  password: '',
  appName: '',
  companySize: null,
  department: null,

  adminErrors: EmberObject.create({
    name: null,
    email: null,
    password: null,
  }),
  appErrors: EmberObject.create({
    name: null,
  }),
  networkError: null,
  cardError: null,
  appNameError: alias('appErrors.name'),
  emailError: alias('adminErrors.email'),
  nameError: alias('adminErrors.name'),
  passwordError: alias('adminErrors.password'),
  subscriptionError: null,

  resetErrors() {
    this.setProperties({
      adminErrors: {},
      appErrors: {},
      networkError: null,
      billingAddressErrors: {},
      cardError: null,
      subscriptionError: null,
    });
  },

  handleCreationError(response) {
    if (!response) {
      return;
    }

    this.resetErrors();

    response = response.jqXHR ? response.jqXHR : response;

    if (response.status >= 500) {
      return this.set(
        'networkError',
        this.intl.t('signup.teams.start.form.network_errors.something_went_wrong'),
      );
    }

    if (response.status === 429) {
      return this.set(
        'networkError',
        this.intl.t('signup.teams.start.form.network_errors.too_many_signup_attempts'),
      );
    }

    let responseJSON = response.responseJSON;

    if (!responseJSON) {
      return;
    }

    let {
      unsupported_locale,
      adblocker,
      admin,
      app,
      email,
      name,
      password,
      country,
      country_code,
      state_code,
      token,
    } = responseJSON;

    if (adblocker) {
      return this.set(
        'networkError',
        this.intl.t('signup.teams.start.form.network_errors.ad_blocker'),
      );
    }

    if (unsupported_locale) {
      return this.set(
        'networkError',
        this.intl.t('signup.teams.start.form.network_errors.unsupported_locale'),
      );
    }

    if (admin) {
      this.set('adminErrors', admin);
      this.purchaseAnalyticsService.trackEvent({
        action: 'triggered',
        object: 'teammate_signup_error',
        context: this.context,
        place: 'signup',
        owner: 'growth',
        errors: admin,
        solutionId: this.solutionId,
      });
    }

    if (app) {
      this.set('appErrors', app);
      this.purchaseAnalyticsService.trackEvent({
        action: 'triggered',
        object: 'app_signup_error',
        context: this.context,
        place: 'signup',
        owner: 'growth',
        errors: app,
        solutionId: this.solutionId,
      });
    }

    if (email || name || password) {
      this.set('adminErrors', {
        email,
        name,
        password,
      });
    }

    if (country) {
      this.set('cardError', this.intl.t('signup.teams.start.form.errors.invalid_location'));
    }

    if (token) {
      this.set('cardError', token);
    }

    if (country_code) {
      this.set(
        'billingAddressErrors.countryCode',
        this.intl.t('signup.teams.start.form.billing_address_errors.country_cannot_be_blank'),
      );
    }

    if (state_code) {
      this.set(
        'billingAddressErrors.stateCode',
        this.intl.t('signup.teams.start.form.billing_address_errors.state_cannot_be_blank'),
      );
    }
  },

  actions: {
    onCountrySelected(code) {
      this.set('billingAddressErrors.countryCode', '');
      this.set('billingAddressErrors.stateCode', '');

      this.set('billingAddress.countryCode', code);
      this.set('billingAddress.stateCode', '');

      this._trackDropDownEvent('company_country');
    },
    onStateSelected(object) {
      if (this.invalidStateCode) {
        this.set(
          'billingAddressErrors.stateCode',
          this.intl.t('signup.teams.start.form.billing_address_errors.state_cannot_be_blank'),
        );
      } else {
        this.set('billingAddressErrors.stateCode', '');
      }

      this._trackDropDownEvent(object);
    },
    onAddressEntered(key, event) {
      this.set(`billingAddressErrors.${key}`, '');
      this.set(`billingAddress.${key}`, event.target.value);
    },
    trackDropDownEvent(object) {
      this._trackDropDownEvent(object);
    },
    transitionToPayment() {
      this.purchaseAnalyticsService.trackEvent({
        action: 'clicked next', // eslint-disable-line @intercom/intercom/no-bare-strings
        object: this.signupObject,
        context: 'signup',
        place: this.place,
        mobile: this.viewingFromMobileDevice,
        solutionId: this.solutionId,
      });

      if (this.hasInvalidFormData) {
        this._showInvalidFormDataErrors();
      } else {
        this.set('showPayment', true);
      }
    },

    submitForm() {
      this.purchaseAnalyticsService.trackEvent({
        action: 'clicked',
        object: this.signupObject,
        context: 'signup',
        place: this.place,
        mobile: this.viewingFromMobileDevice,
        gclid: this.gclid,
        emailSubmissionId: this.emailSubmissionId,
        solutionId: this.solutionId,
        mrr: this.get('currentPrice.amount'),
        planIds: this.activePlanIds.toArray(),
      });

      this._resetInvalidFormDataErrors();
      this._resetInvalidLocationDataErrors();

      if (this.hasInvalidFormData) {
        this._showInvalidFormDataErrors();
        this.set('showPayment', false);
      }

      if (this.hasInvalidLocationData) {
        this._showInvalidLocationDataErrors();
      }

      let stripeElement = this.get('elements.firstObject');
      this.resetCardErrors();

      if (this.hasActivePlans) {
        let stripeParams = {};
        if (this.billingAddress.stateCode) {
          stripeParams.address_state = this.billingAddress.stateCode;
        }

        this.stripe.createToken(stripeElement, stripeParams).then((response) => {
          run(() => {
            if (response.error && ENV.environment !== 'test') {
              if (
                [
                  'incomplete_number',
                  'invalid_number',
                  'incorrect_number',
                  'card_declined',
                  'processing_error',
                  'invalid_swipe_data',
                ].includes(response.error.code)
              ) {
                this.set('creditCardNumberError', true);
              }

              if (
                [
                  'incomplete_cvc',
                  'incorrect_cvc',
                  'invalid_cvc',
                  'card_declined',
                  'processing_error',
                ].includes(response.error.code)
              ) {
                this.set('cvcError', true);
              }

              if (
                [
                  'incomplete_expiry',
                  'expired_card',
                  'invalid_expiry_year',
                  'invalid_expiry_month',
                  'processing_error',
                ].includes(response.error.code)
              ) {
                this.set('expiryDateError', true);
              }

              return this.set('cardError', response.error.message);
            }

            this.set('token', response.token);
            this._sendFormTask();
          });
        });
      } else if (this.hasInvalidFormData || this.hasInvalidLocationData) {
        return;
      } else {
        this._sendFormTask();
      }
    },
  },
  showMobileView: false,

  place: computed('hasActivePlans', 'teamsExperience', function () {
    if (this.teamsExperience) {
      return 'start_trial';
    }
    return this.hasActivePlans ? 'create_paid_account' : 'create_free_account';
  }),
  signupObject: ternary('hasActivePlans', 'start_trial', 'create_free_account'),
  planIds: null,
  solutionId: null,
  token: null,

  billingAddress: null,
  billingAddressErrors: null,

  creditCardNumberError: null,
  expiryDateError: null,
  cvcError: null,
  showPaymentForm: true,

  teamsExperience: false,
  notTeamsExperience: not('teamsExperience'),

  validEmail: isValidEmail('email'),

  addressConfiguration: computed('billingAddress.countryCode', function () {
    if (this.billingAddress) {
      return AddressSettings[this.billingAddress.countryCode] || AddressSettings.default;
    }

    return AddressSettings.default;
  }),

  buttonCTA: computed('hasActivePlans', 'showPaymentForm', function () {
    if (!this.showPaymentForm) {
      return {
        text: 'sign_up.new.form.next',
      };
    }

    if (this.hasActivePlans) {
      return {
        text: 'sign_up.new.form.start_free_trial',
        submitText: 'sign_up.new.form.starting',
      };
    }

    return {
      text: 'sign_up.new.form.create_account',
      submitText: 'sign_up.new.form.creating',
    };
  }),

  stripeInputStyle: {
    base: {
      border: '1px solid grey', // eslint-disable-line @intercom/intercom/no-bare-strings
      fontSize: '13px',
      fontSmoothing: 'antialiased',
      color: '#32325d',
      '::placeholder': {
        color: PALETTE.gray,
      },
    },
  },

  stripeClasses: {
    base: 'signup__new__form__stripe-input',
    focus: 'signup__new__form__stripe-input-focus',
    invalid: 'signup__new__form__stripe-input-invalid',
  },

  stripeClassesExpiry: {
    base: 'signup__new__form__stripe-input signup__new__form__expiry ',
    focus: 'signup__new__form__stripe-input-focus',
    invalid: 'signup__new__form__stripe-input-invalid',
  },

  stripeClassesCvc: {
    base: 'signup__new__form__stripe-input signup__new__form__cvc',
    focus: 'signup__new__form__stripe-input-focus',
    invalid: 'signup__new__form__stripe-input-invalid',
  },

  didInsertElement() {
    this._super(...arguments);

    // Initializing billingAddress & billingAddressErrors here so that it is set in the component instance context rather than the component class context.
    this.set('billingAddress', {});
    this.set('billingAddressErrors', {});

    if (this.teamsExperience) {
      let stripeCardElement = this.buildStripeCardElement();
      stripeCardElement.mount($('[role="stripe-card"]', this.element)[0]);
      this.set('elements', [stripeCardElement]);
      this.stripe.addStripeElement(stripeCardElement);
      this.setStripeEventListeners();
    }
    if (this.hasActivePlans && this.notTeamsExperience) {
      let stripeElements = this.stripe.elements();
      let stripeInputStyle = this.stripeInputStyle;
      let stripeCardElement = stripeElements.create('cardNumber', {
        style: stripeInputStyle,
        classes: this.stripeClasses,
      });
      stripeCardElement.mount($('[role="stripe-card-number"]', this.element)[0]);
      let stripeExpiryElement = stripeElements.create('cardExpiry', {
        style: stripeInputStyle,
        classes: this.stripeClassesExpiry,
      });
      stripeExpiryElement.mount($('[role="stripe-card-expiry"]', this.element)[0]);
      let stripeCvcElement = stripeElements.create('cardCvc', {
        style: stripeInputStyle,
        classes: this.stripeClassesCvc,
      });
      stripeCvcElement.mount($('[role="stripe-card-cvc"]', this.element)[0]);

      this.set('elements', [stripeCardElement, stripeExpiryElement, stripeCvcElement]);
      this.stripe.addStripeElement(stripeCardElement);
      this.setStripeEventListeners();
    }
  },

  willDestroyElement() {
    this._super(...arguments);
    if (this.hasActivePlans) {
      this.elements.forEach((element) => {
        element.unmount();
      });
    }
  },

  buildStripeCardElement() {
    let stripeElements = this.stripe.elements();
    let stripeInputStyle = this.stripeInputStyle;
    return stripeElements.create('card', {
      style: stripeInputStyle,
      classes: this.stripeClasses,
    });
  },

  stripeElementEventName(componentName) {
    switch (componentName) {
      case 'cardNumber':
        return 'credit_card_number';
      case 'cardExpiry':
        return 'credit_card_date';
      case 'cardCvc':
        return 'credit_card_code';
      default:
        return 'credit_card';
    }
  },

  stripeElementErrorName(componentName) {
    switch (componentName) {
      case 'cardNumber':
        return 'creditCardNumberError';
      case 'cardExpiry':
        return 'expiryDateError';
      case 'cardCvc':
        return 'cvcError';
      default:
        return 'cardError';
    }
  },

  setStripeEventListeners() {
    let stripeElements = this.elements;
    stripeElements.forEach((stripeElement) => {
      stripeElement.on('change', (...args) => {
        let { error, complete, value } = args[0];
        if (complete) {
          this.purchaseAnalyticsService.trackEvent({
            action: 'entered',
            object: this.stripeElementEventName(stripeElement._componentName),
            context: 'signup',
            place: this.place,
            mobile: this.viewingFromMobileDevice,
            solutionId: this.solutionId,
          });
          this.set('stripeComplete', true);
          let oldPostcode = this.billingAddress?.postcode?.toLowerCase() || '';
          let newPostcode = value.postalCode?.toLowerCase() || '';
          if (newPostcode.includes(oldPostcode)) {
            this.set('billingAddress.postcode', value.postalCode || '');
          }
        } else if (error) {
          this.set('stripeError', error);
        } else {
          let error = this.stripeElementErrorName(stripeElement._componentName);
          this.set('cardError', null);
          this.set(error, null);
        }
      });
    });
  },

  resetCardErrors() {
    this.setProperties({
      creditCardNumberError: null,
      expiryDateError: null,
      cvcError: null,
      cardError: null,
    });
  },

  emptyName: empty('name'),
  emptyAppName: empty('appName'),
  emptyEmail: empty('email'),
  emptyPassword: empty('password'),
  emptyCountryCode: empty('billingAddress.countryCode'),
  emptyStateCode: empty('billingAddress.stateCode'),
  emptyBillingStreet: empty('billingAddress.streetAddress'),
  emptyBillingCity: empty('billingAddress.city'),
  emptyBillingPostcode: empty('billingAddress.postcode'),
  invalidBillingStreet: readOnly('emptyBillingStreet'),
  invalidBillingCity: readOnly('emptyBillingCity'),
  invalidBillingPostcode: computed(
    'emptyBillingPostcode',
    'addressConfiguration',
    'billingAddress.postcode',
    function () {
      return (
        this.emptyBillingPostcode ||
        (this.addressConfiguration.postCodeRegex &&
          !this.addressConfiguration.postCodeRegex.exec(this.billingAddress.postcode))
      );
    },
  ),
  invalidName: and('notViaSingleSignOn', 'emptyName'),
  invalidEmail: and('notViaSingleSignOn', 'emptyEmail'),
  invalidPassword: and('notViaSingleSignOn', 'emptyPassword'),
  invalidCountryCode: readOnly('emptyCountryCode'),
  invalidStateCode: and('addressConfiguration.showState', 'emptyStateCode'),
  hasInvalidFormData: or('invalidName', 'emptyAppName', 'invalidEmail', 'invalidPassword'),
  hasInvalidLocationData: or(
    'invalidCountryCode',
    'invalidStateCode',
    'invalidBillingStreet',
    'invalidBillingCity',
    'invalidBillingPostcode',
  ),

  _resetInvalidFormDataErrors() {
    this.set('nameError', '');
    this.set('appNameError', '');
    this.set('emailError', '');
    this.set('passwordError', '');
  },

  _resetInvalidLocationDataErrors() {
    this.set('billingAddressErrors', {});
  },

  _showInvalidFormDataErrors() {
    if (this.invalidName) {
      this.set('nameError', this.intl.t('signup.teams.start.form.errors.name_cannot_be_blank'));
    }

    if (this.emptyAppName) {
      this.set('appNameError', this.intl.t('signup.teams.start.form.errors.app_cannot_be_blank'));
    }

    if (this.invalidEmail) {
      this.set('emailError', this.intl.t('signup.teams.start.form.errors.email_cannot_be_blank'));
    }

    if (this.invalidPassword) {
      this.set(
        'passwordError',
        this.intl.t('signup.teams.start.form.errors.password_cannot_be_blank'),
      );
    }
  },

  _showInvalidLocationDataErrors() {
    if (this.invalidCountryCode) {
      this.set(
        'billingAddressErrors.countryCode',
        this.intl.t('signup.teams.start.form.billing_address_errors.country_cannot_be_blank'),
      );
    }

    if (this.invalidStateCode) {
      this.set(
        'billingAddressErrors.stateCode',
        this.intl.t('signup.teams.start.form.billing_address_errors.state_cannot_be_blank'),
      );
    }

    if (this.invalidBillingStreet) {
      this.set(
        'billingAddressErrors.streetAddress',
        this.intl.t(
          'signup.teams.start.form.billing_address_errors.street_address_cannot_be_blank',
        ),
      );
    }

    if (this.invalidBillingCity) {
      this.set(
        'billingAddressErrors.city',
        this.intl.t('signup.teams.start.form.billing_address_errors.city_cannot_be_blank'),
      );
    }

    if (this.invalidBillingPostcode) {
      if (this.emptyBillingPostcode) {
        this.set(
          'billingAddressErrors.postcode',
          this.intl.t('signup.teams.start.form.billing_address_errors.zip_code_cannot_be_blank'),
        );
      } else {
        this.set(
          'billingAddressErrors.postcode',
          this.intl.t('signup.teams.start.form.billing_address_errors.invalid_zip_code'),
        );
      }
    }
  },

  _sendFormTask() {
    let formData = {
      name: this.name,
      email: this.email,
      password: this.password,
      appName: this.appName,
      companySize: this.companySize,
      department: this.department,
      countryCode: this.billingAddress.countryCode,
      stateCode: this.billingAddress.stateCode,
    };

    if (this.token) {
      formData.creditCardToken = this.get('token.id');
    }
    this.formTask.perform(formData, this.handleCreationError.bind(this));
  },

  _trackDropDownEvent(object) {
    this.purchaseAnalyticsService.trackEvent({
      action: 'selected',
      object,
      context: 'signup',
      place: this.place,
      mobile: this.viewingFromMobileDevice,
      solutionId: this.solutionId,
    });
  },
});
