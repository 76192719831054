/* import __COLOCATED_TEMPLATE__ from './editor.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import {
  largeFormatQuestionTypeItems,
  maximumQuestionsPerStepBannerFormat,
  maximumQuestionsPerStepPostFormat,
  smallFormatQuestionTypeItems,
  branchingQuestionIdentifier,
} from 'embercom/models/data/survey/constants';
import { stepTypes } from 'embercom/models/data/survey/constants';
import { preferredDevices } from 'embercom/models/data/outbound/constants';
import { EntityType } from 'embercom/models/data/entity-types';
import {
  defaultBannerThankYouBlocks,
  defaultPostThankYouBlocks,
  defaultIntroBlocks,
} from 'embercom/models/data/survey/constants';
import Predicate from 'predicates/models/predicate';

export default class SurveyEditorComponent extends Component {
  postFormatAllowedBlocks = ['bold', 'italic', 'anchor', 'heading', 'subheading'];
  @service store;
  @service appService;
  @service intl;
  @tracked currentStep;
  @tracked selectedQuestionType;
  @tracked activeQuestionIndex;
  @tracked currentHoverIndex;
  @tracked isComposerActive;
  @tracked showSurveySettings = true;
  @tracked showThankYouStepSettings = false;
  @tracked currentDevice = this.args.isEditing ? 'web' : this.firstPreferredDevice;
  @tracked isMobileReadOnlyMode = false;
  @tracked showDeleteBranchingLogicModal = false;
  @tracked showBranchingSideDrawer = false;
  @tracked deleteBranchingModalContent;
  @tracked composerApi;
  @tracked hoveredStepIndex = null;
  ellipsisLength = 3;
  truncateLength = 15;

  constructor() {
    super(...arguments);
    this.currentStep = this.survey.hasIntroStep ? this.survey.introStep : this.survey.firstStep;
    this.isComposerActive = !!this.currentStep?.isThankYouType;
  }

  get questionTypeSelectItems() {
    let items = smallFormatQuestionTypeItems;
    if (this.survey.isPostFormat) {
      items = largeFormatQuestionTypeItems;
    }

    return items;
  }

  get app() {
    return this.appService.app;
  }

  get survey() {
    return this.args.survey;
  }

  get question() {
    return this.currentStep?.nonDeletedQuestions.firstObject;
  }

  get introStep() {
    return this.survey.steps.find((step) => step.isIntroType);
  }

  get currentQuestion() {
    return this.currentStep?.nonDeletedQuestions.objectAt(this.activeQuestionIndex);
  }

  get stepAction() {
    if (this.currentStep?.actions.length) {
      return this.currentStep.actions?.firstObject;
    }
  }

  get shouldShowValidations() {
    return this.args.shouldShowValidations;
  }

  get hasNoQuestionsPostFormatError() {
    return this.shouldShowValidations && this.survey.isPostFormat && !this.currentStep.hasQuestions;
  }

  get hasReachedMaximumQuestionsPerStepLimit() {
    if (this.survey.isBannerFormat) {
      return this.currentStepQuestionCount >= maximumQuestionsPerStepBannerFormat;
    } else if (this.survey.isPostFormat) {
      return this.currentStepQuestionCount >= maximumQuestionsPerStepPostFormat;
    }
  }

  get currentStepQuestionCount() {
    return this.currentStep?.nonDeletedQuestions.length;
  }

  get canAddSingleQuestionToStep() {
    return Boolean(this.survey.isBannerFormat && this.isEditMode);
  }

  get canAddMultipleQuestionsToStep() {
    return Boolean(
      this.survey.isPostFormat &&
        this.isEditMode &&
        !this.currentStep?.isThankYouType &&
        !this.currentStep?.isIntroType,
    );
  }

  get canAddQuestionToNextStep() {
    return Boolean(this.question && this.isEditMode);
  }

  get canPassActiveQuestionIndex() {
    return Boolean(this.isEditMode);
  }

  get canEditOrderedStepBlock() {
    return Boolean(this.currentStep?.isOrdered && this.survey.isPostFormat);
  }

  get shouldShowSurveySettings() {
    return (
      this.showSurveySettings ||
      this.currentStep?.nonDeletedQuestions.length === 0 ||
      !this.currentStep?.isOrdered
    );
  }

  get shouldShowThankYouStepSettings() {
    return this.currentStep?.stepType === stepTypes.thankYou && this.showThankYouStepSettings;
  }

  get isThankYouStepComposerActive() {
    return this.currentStep?.isThankYouType && !this.showSurveySettings && this.isEditMode;
  }

  get isEditMode() {
    return this.args.isEditing && !this.isMobileReadOnlyMode;
  }

  get maxTruncateLength() {
    return this.ellipsisLength + this.truncateLength;
  }

  get putCtaBesideText() {
    return this.currentStep?.textLength <= 200;
  }

  get bannerCtaButtonStyle() {
    if (this.isEditMode) {
      if (this.putCtaBesideText) {
        return 'mt-4';
      } else {
        return 'ml-6 mt-3';
      }
    } else if (!this.isEditMode) {
      if (!this.putCtaBesideText) {
        return 'ml-2 mt-2';
      }
    }
  }

  @action onQuestionTypeSelected(selctedQuestionType) {
    let { questionType, scaleQuestionType } = this.survey.selectedQuestionTypeDetails(
      selctedQuestionType.value,
    );

    this.selectedQuestionType = questionType;
    this.addQuestionToCurrentStep({ scaleQuestionType });
    this.currentStep.stepType = stepTypes.question;
  }

  @action addQuestionToCurrentStep(options) {
    let { scaleQuestionType } = options;
    let question = this.store.createRecord('surveys/question', {
      questionType: this.selectedQuestionType,
      data: this.store.createFragment('surveys/question-data'),
      order: this.currentStep.questions.length,
    });

    this.currentStep.questions.pushObject(question);
    let questionIndex = this.currentStep.nonDeletedQuestions.length - 1;

    question.setupQuestionData(scaleQuestionType);
    this.selectedQuestionType = undefined; // reset
    this.setActiveQuestionIndex(questionIndex);
    this.showSurveySettings = false;
    this.setActiveQuestionIndex(questionIndex);
  }

  @action setBranchingSideDrawerStatus(visible) {
    if (this.composerApi) {
      if (visible) {
        this.composerApi.composer.disableInteractivity();
      } else {
        this.composerApi.composer.enableInteractivity();
      }
    }

    this.showBranchingSideDrawer = visible;
  }

  @action confirmChangeBranchingLogic() {
    this.showDeleteBranchingLogicModal = false;
    this.setBranchingSideDrawerStatus(true);
  }

  get introOrThankYouPlaceholder() {
    if (this.currentStep.isIntroType) {
      return 'Welcome to the survey....';
    }

    return 'Thank you...';
  }

  get firstPreferredDevice() {
    let contentPlatform = this.survey.preferredDevices.firstObject;
    switch (contentPlatform) {
      case preferredDevices.ios:
        return 'ios';
      case preferredDevices.android:
        return 'android';
      default:
        return 'web';
    }
  }

  get showPreviewSwitcher() {
    return !this.args.isEditing || this.isMobileReadOnlyMode;
  }

  get previewSwitcherDeviceOptions() {
    let devices = this.survey.preferredDevices;
    if (this.isMobileReadOnlyMode) {
      devices = devices.filter((device) => device !== preferredDevices.web);
    }

    return devices;
  }

  get mobileButtonClassName() {
    let className = `o__${this.currentDevice}`;
    if (this.stepAction && this.stepAction.actionTitle) {
      className += ' secondary';
    }
    return className;
  }

  get mobileButtonText() {
    let text = this.intl.t('outbound.surveys.step-buttons.submit');
    if (this.currentStep?.isThankYouType) {
      text = this.intl.t('outbound.surveys.step-buttons.done');
    } else if (this.currentStep?.isIntroType) {
      text = this.intl.t('outbound.surveys.step-buttons.next');
    }

    return text;
  }

  _updateDefaultPathsForNewStep(newStep, nextStep = this.survey.defaultThankYouStep) {
    let defaultPathOrder = 0;

    let newPath = this.store.createRecord('content-service/path', {
      contentType: EntityType.Survey,
      contentId: this.survey.id,
      stepType: EntityType.SurveyStep,
      stepUuid: newStep.uuid,
      nextStepId: nextStep.id,
      nextStepUuid: nextStep.uuid,
      order: defaultPathOrder,
      predicateGroup: { predicates: [] },
    });

    // Point any existing paths to new step
    nextStep.inwardPaths.forEach((path) => {
      if (path.order === defaultPathOrder) {
        path.nextStepId = undefined;
        path.nextStepUuid = newStep.uuid;
      }
    });

    this.survey.paths.pushObject(newPath);
  }

  _updateDefaultPathsForMovedOrDeletedStep(stepToDelete) {
    let nextOrderedStep = this.survey.orderedSteps.filter(
      (step) => step.order === stepToDelete.order + 1,
    ).firstObject;
    let newSuccessorStep = nextOrderedStep || this.survey.defaultThankYouStep;
    if (this.survey.defaultThankYouStep === stepToDelete) {
      // if deleting default thank you step then new successor should be next thanks step
      newSuccessorStep = this.survey.thankYouSteps[1];
    }

    // Re-point any paths pointing to the deleted step
    stepToDelete.inwardPaths.forEach((path) => {
      path.nextStepId = newSuccessorStep.id || undefined;
      path.nextStepUuid = newSuccessorStep.uuid;
    });

    // Remove any paths associated with the deleted step itself
    stepToDelete.paths.forEach((path) => {
      this.survey.paths.removeObject(path);
      path.deleteRecord();
    });
  }

  _checkStepBranchingLogic(stepToCheck) {
    let { id, uuid } = stepToCheck;
    let hasAttachedLogic = this.survey.stepsIdsWithAttachedBranchingLogic.find(
      (branchingStep) => branchingStep.id === id || branchingStep.uuid === uuid,
    );

    return hasAttachedLogic;
  }

  _checkQuestionBranchingLogic(question) {
    let questionId = branchingQuestionIdentifier + question.uuid;
    let pathsWithQuestionBranchingLogic = this.survey.paths.filter((path) => {
      let flattenPredicates = [...Predicate.walkPredicates(path.predicateGroup.predicates)];
      let predicatesQuestions = flattenPredicates.filter(
        (predicate) => predicate.attribute === questionId,
      ).length;

      return predicatesQuestions;
    });

    return Boolean(pathsWithQuestionBranchingLogic.length);
  }

  @action updateCurrentPreviewDevice(device) {
    this.currentDevice = device;
  }

  @action onQuestionTypeSelectedForNextStep(selctedQuestionType) {
    let { questionType, scaleQuestionType } = this.survey.selectedQuestionTypeDetails(
      selctedQuestionType.value,
    );

    this.selectedQuestionType = questionType;
    this.addStep();
    this.addQuestionToCurrentStep({ scaleQuestionType });
  }

  @action deleteQuestion(question) {
    if (this._checkQuestionBranchingLogic(question)) {
      this._setupDeleteBranchingModal(
        this.intl.t('outbound.surveys.branching.modal.delete-question'),
      );

      return;
    }

    question.deleteRecord();
    this._reorderQuestions(this.currentStep.nonDeletedQuestions);
    this.setCurrentQuestionIndexOnDelete();
  }

  _reorderQuestions(questions) {
    for (let i = 0; i < questions.length; i++) {
      questions[i].order = i;
    }
    this.currentStep.questions = questions;
  }

  @action onQuestionDragged(newQuestionOrder, draggedQuestion) {
    let draggedQuestionIndex = newQuestionOrder.indexOf(draggedQuestion);
    let draggedQuestionDidNotMove = draggedQuestionIndex === draggedQuestion.questionOrder - 1;

    if (draggedQuestionDidNotMove) {
      return; // no reordering to do
    }

    let newCurrentQuestionIndex = newQuestionOrder.indexOf(this.currentQuestion);
    let activeQuestionMoved = newCurrentQuestionIndex !== this.activeQuestionIndex;

    if (activeQuestionMoved) {
      this.activeQuestionIndex = newCurrentQuestionIndex;
    }

    this._reorderQuestions(newQuestionOrder);
    this.currentHoverIndex = draggedQuestionIndex;
  }

  @action setCurrentStep(step, showSurveySettings = true) {
    this.currentStep = step;

    this.setActiveQuestionIndex(undefined);
    this.showSurveySettings = showSurveySettings;
    if (this.currentStep?.isThankYouType) {
      this.showThankYouStepSettings = !showSurveySettings;
    }
  }

  @action addStep() {
    if (this.survey.hasReachedMaximumStepsLimit) {
      return;
    }

    let newStep = this.store.createRecord('surveys/step', {
      order: this.survey.orderedSteps.length,
    });

    this._updateDefaultPathsForNewStep(newStep);
    this.survey.steps.pushObject(newStep);
    this.setCurrentStep(this.survey.orderedSteps.lastObject);
  }

  @action addThankYouStep() {
    if (this.survey.hasReachedMaximumThankYouStepsLimit) {
      return;
    }

    let newStep = this.store.createRecord('surveys/step', {
      order: this.survey.nextThankYouStepOrder,
      stepType: stepTypes.thankYou,
      blocks: this.survey.isPostFormat ? defaultPostThankYouBlocks : defaultBannerThankYouBlocks,
    });

    this.survey.steps.pushObject(newStep);
    this.setCurrentStep(this.survey.thankYouSteps.lastObject, false);
  }

  _setupDeleteBranchingModal(message) {
    this.deleteBranchingModalContent = {
      info: message,
    };
    this.showDeleteBranchingLogicModal = true;
  }

  @action deleteStep(stepToDelete) {
    if (this._checkStepBranchingLogic(stepToDelete) !== undefined) {
      this._setupDeleteBranchingModal(this.intl.t('outbound.surveys.branching.modal.delete-step'));
      return;
    }

    this._updateDefaultPathsForMovedOrDeletedStep(stepToDelete);

    this.survey.steps.removeObject(stepToDelete);

    if (stepToDelete.isThankYouType) {
      this.survey.thankYouSteps
        .filter((step) => step.order < stepToDelete.order)
        .forEach((step) => (step.order += 1));
      this.setCurrentStep(this.survey.thankYouSteps.lastObject, false);
      return;
    }

    this.survey.orderedSteps
      .filter((step) => step.order > stepToDelete.order)
      .forEach((step) => (step.order -= 1));
    this.setCurrentStep(this.survey.orderedSteps.lastObject);
  }

  @action reorderSteps(newStepOrder, draggedStep) {
    let draggedStepIndex = newStepOrder.indexOf(draggedStep);
    let draggedStepDidNotMove = draggedStepIndex === draggedStep.order;

    if (draggedStepDidNotMove) {
      return; // no reordering to do
    }

    let surveyHasBranchingPaths = this.survey.userDefinedBranchingPaths.length > 0;
    if (surveyHasBranchingPaths) {
      this._setupDeleteBranchingModal(this.intl.t('outbound.surveys.branching.modal.reorder-step'));
      return;
    }

    // Approach here is to use the already defined functions
    // 1. Act as if we're deleting the moved step from its original position.
    //    Delete the moved step's paths
    this._updateDefaultPathsForMovedOrDeletedStep(draggedStep);

    // 2. Assign updated ordering to the steps
    for (let i = 0; i < newStepOrder.length; i++) {
      newStepOrder[i].order = i;
    }
    this.survey.steps = this.survey.steps.filter((step) => step.order <= 0);
    newStepOrder.forEach((step) => this.survey.steps.pushObject(step));

    // 3. Update the paths for the moved step's new position.
    //    Act as if we're adding a new step at a given point
    let nextStep = this.survey.steps.find((step) => step.order === draggedStep.order + 1);
    nextStep = nextStep || this.survey.defaultThankYouStep;
    this._updateDefaultPathsForNewStep(draggedStep, nextStep);

    this.hoveredStepIndex = null;
  }

  @action onStepHover(stepIndex) {
    this.hoveredStepIndex = stepIndex;
  }

  @action onQuestionClick(questionIndex) {
    this.setActiveQuestionIndex(questionIndex);
    this.showSurveySettings = false;
  }

  @action onQuestionHover(questionIndex) {
    this.currentHoverIndex = questionIndex;
  }

  @action setActiveQuestionIndex(questionIndex) {
    this.activeQuestionIndex = questionIndex;
    this.isComposerActive = false;
  }

  @action setCurrentQuestionIndexOnDelete() {
    if (this.activeQuestionIndex >= this.currentStepQuestionCount) {
      this.setActiveQuestionIndex(this.activeQuestionIndex - 1);
    }
  }

  @action toggleShowSurveySettings() {
    this.showSurveySettings = !this.showSurveySettings;
    if (this.currentStep?.isThankYouType) {
      this.showThankYouStepSettings = !this.showThankYouStepSettings;
    }
    this.setActiveQuestionIndex(this.showSurveySettings ? undefined : 0);
  }

  @action handleComposerFocus() {
    this.setActiveQuestionIndex(undefined);
    this.showSurveySettings = true;
    this.isComposerActive = true;
  }

  @action handleComposerFocusOnThankYouStep() {
    if (!this.currentStep?.isThankYouType || !this.args.isEditing) {
      return;
    }

    this.setActiveQuestionIndex(undefined);
    this.showSurveySettings = false;
    this.showThankYouStepSettings = true;
  }

  @action updateCurrentStepAfterAutoSave() {
    let questionIndexBeforeUpdate = this.activeQuestionIndex;
    let stepOrderBeforeUpdate = this.currentStep.order;

    if (this.currentStep.id === null) {
      let currentStep =
        stepOrderBeforeUpdate !== null
          ? this.survey.steps.find((step) => step.order === stepOrderBeforeUpdate)
          : this.survey.orderedSteps.lastObject;

      this.setCurrentStep(currentStep, !this.currentStep.isThankYouType);
    }

    this.setActiveQuestionIndex(questionIndexBeforeUpdate);
  }

  @action toggleMobilePreview() {
    this.isMobileReadOnlyMode = !this.isMobileReadOnlyMode;

    if (this.isMobileReadOnlyMode) {
      this.updateCurrentPreviewDevice('ios');
    } else {
      this.updateCurrentPreviewDevice('web');
    }
  }

  @action toggleIntroStep() {
    if (this.survey.hasIntroStep) {
      this.removeIntroStep();
    } else {
      this.addIntroStep();
    }

    this.setCurrentStep(this.survey.steps.lastObject);
  }

  @action addIntroStep() {
    let introStep = this.store.createRecord('surveys/step', {
      order: -1,
      stepType: stepTypes.intro,
      blocks: defaultIntroBlocks,
    });

    let nextStep = this.survey.orderedSteps.firstObject;
    let path = this.store.createRecord('content-service/path', {
      contentType: EntityType.Survey,
      contentId: this.survey.id,
      stepType: EntityType.SurveyStep,
      stepUuid: introStep.uuid,
      nextStepId: nextStep.id,
      nextStepUuid: nextStep.uuid,
      order: 0,
      predicateGroup: { predicates: [] },
    });
    this.survey.paths.pushObject(path);

    this.survey.steps.pushObject(introStep);
    this.setCurrentStep(this.survey.steps.lastObject);
  }

  @action removeIntroStep() {
    this.introStep.paths.forEach((path) => {
      this.survey.paths.removeObject(path);
      path.deleteRecord();
    });
    this.survey.steps.removeObject(this.introStep);
    this.setCurrentStep(this.survey.orderedSteps.firstObject);
  }

  @action checkBranchingOnQuestionChange(question) {
    let hasQuestionBranchingLogic = this._checkQuestionBranchingLogic(question);

    if (hasQuestionBranchingLogic) {
      this.deleteBranchingModalContent = {
        info: this.intl.t('outbound.surveys.branching.modal.change-question'),
      };
      this.showDeleteBranchingLogicModal = true;
    }

    return hasQuestionBranchingLogic;
  }
}
