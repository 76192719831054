/* import __COLOCATED_TEMPLATE__ from './external-plan.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class ExternalPlan extends Component {
  @service store;

  get plan() {
    return this.store.peekRecord('external-plan', this.args.externalPlanId);
  }
}
