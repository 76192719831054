/* import __COLOCATED_TEMPLATE__ from './permission-list.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import ModelWithPermissions from 'embercom/models/model-with-permissions';
import { intersection } from 'underscore';
export default class PermissionList extends Component {
  get permissions() {
    return Object.fromEntries(
      this.allowedPermissionList.map((rawPermissionName) => [
        `can_${rawPermissionName}`,
        this.args.value.permissions[rawPermissionName],
      ]),
    );
  }

  get allowedPermissionList() {
    return intersection(
      Object.keys(this.args.value.permissions),
      ModelWithPermissions.editablePermissionKeys.map((permissionKey) =>
        permissionKey.replace(/^(can_)/, ''),
      ),
    );
  }
}
